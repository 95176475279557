import { Component, OnInit,HostListener, Renderer2 } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { ConsoleToggleService } from './service/console.log.service';
import { environment } from "../environments/environment";
import { getMessaging, getToken, onMessage } from "firebase/messaging";
import { UtilsService } from 'src/app/service/utils.service';
import { initializeApp } from "firebase/app";
import { PrintScreenService } from 'src/app/service/print-screen.service';
initializeApp(environment.firebase);

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'] 
})
export class AppComponent implements OnInit {
	title = 'Redium';
	message:any = null;
	notification: boolean = false;
	all_users: any;
	profileImage:string;
	macAgent: boolean = false;
  
	constructor(private router: Router, 
	private consoleToggleService: ConsoleToggleService,
	private UtilsService: UtilsService,
	private PrintScreenService: PrintScreenService,
	private renderer: Renderer2
	){
      this.consoleToggleService.disableConsoleInProduction();
	    window.addEventListener('keyup', (e) => this.onKeyUp(e));
		window.addEventListener('keydown', (e) => this.onKeyDown(e));
		window.addEventListener('mouseout', (e: MouseEvent) => this.onMouseOut(e));
    }
	ngOnInit() {
	//this.renderer.listen('document', 'keyup', this.onKeyUp.bind(this));
	this.macAgent = this.isMacOS() ? true : false;
	
	//this.requestPermission();
    //this.listen();
	
	let formChanged = true;
		
    window.addEventListener('storage', (event) => {
        if (event.storageArea == localStorage) {
             let token = localStorage.getItem('fullname');
             if(token == undefined) {
               // Perform logout
               //Navigate to login/home
                this.router.navigate(['/login']);
             }
        }
    });
	}
	
	requestPermission() {
    const messaging = getMessaging();
    getToken(messaging, { vapidKey: environment.firebase.vapidKey }).then((currentToken) => {
      if (currentToken) {
        console.log("token.....")
		localStorage.setItem('push-notification-token',currentToken);
      } else {
        console.log('No registration token available. Request permission to generate one.');
      }
	  this.all_users = JSON.parse(localStorage.getItem('all_users'));
    }).catch((err) => {
      console.log('An error occurred while retrieving token. ', err);
    });
	}
  
  listen() {
    const messaging = getMessaging();
    onMessage(messaging, (payload) => {
      console.log('Message received. ', payload);
     this.message= payload?.data?JSON.parse(payload?.data.sendbird):null;
     let filterData = this.all_users.filter(
            (el) => el.sendbird_user_details.user_id === this.message.sender.id
          );
     this.profileImage = (filterData && filterData.length>0)?filterData[0].userpicture:null;
	 this.notification = true;
	 setTimeout(() => {
        this.notification = false;
      }, 8000);      
    });
	
  }
  
  emptyCollab() {
	this.notification = false;
    localStorage.setItem('allCollab', 'true');
    this.router.navigate(['dashboard/members-collab']);
  }
  
  // @HostListener('document:keydown', ['$event'])
  // handleKeyDownEvent(e: KeyboardEvent) {
	// if(this.macAgent)
    // this.handleKeyboardEvent(e);
  // }

  // // Handle keyup event
  // @HostListener('document:keyup', ['$event'])
  // handleKeyUpEvent(e: KeyboardEvent) {
	// if(!this.macAgent)
    // this.handleKeyboardEvent(e);
  // }
  onMouseOut(event: MouseEvent) {
	//console.log('===',event);
	let mouse = event.relatedTarget;
	//console.log('===',mouse);
	
	if (!mouse || mouse == null) {
    // Specific logic when the mouse leaves the element and goes to HTML or nothing
		console.log('Mouse left the window or went to HTML element');
		//this.PrintScreenService.printScreenAllowed();
		setTimeout(function () {
        console.log('dhee:',event.type);
		}, 0);
	}
	  // const relatedElement = event.relatedTarget || event.toElement;
    // console.log('Mouse left the window:', event);
	  // if ( (!relatedElement || relatedElement.nodeName == "HTML") && relatedElement.clientY <= 100 ) {
		// alert("left top bar");
	  // }
    // You can replace this with your specific logic
  }
  
  onKeyUp(e: KeyboardEvent) {
    if(!this.macAgent && e.type == 'keyup')
	this.handleKeyboard(e);
  }
  
  onKeyDown(e: KeyboardEvent) {
    if(this.macAgent && e.type == 'keydown')
    this.handleKeyboard(e);
  }
  
  handleKeyboard(e: KeyboardEvent) {
    const c = e.keyCode || e.charCode;
	console.log('keycode:',c);
	console.log('event:',e);
    const blockScreenPrint = localStorage.getItem('IS-ALLOWED-SCREENSHOT-ORG');
	if (blockScreenPrint == 'N' && c == 44 && !this.macAgent) { 
	  alert('Print screen not permitted');	
	  navigator.clipboard.writeText('not allowed');
      e.preventDefault();
      e.stopPropagation();
    } else if (blockScreenPrint == 'N' && e.shiftKey && e.keyCode == 83 && !this.macAgent)  {
	  this.PrintScreenService.printScreenAllowed();
      e.preventDefault();
      e.stopPropagation();
    } else if(this.macAgent && blockScreenPrint == 'N' && ((e.metaKey && e.keyCode == 16) || (e.metaKey && e.shiftKey))) {
	  this.PrintScreenService.printScreenAllowed();
	  e.preventDefault();
      e.stopPropagation();
	}

  }
  
  isMacOS() {
	return /Macintosh|MacIntel|MacPPC|Mac68K/.test(navigator.platform) || /Mac OS X/.test(navigator.userAgent);
  }


}
