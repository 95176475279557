import { Component, OnInit,Inject } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { StoreService } from 'src/app/service/store.service';
import { EventEmitterService } from 'src/app/service/event-emitter.service';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { LogoutService } from 'src/app/service/logout.service';
import {MAT_DIALOG_DATA} from '@angular/material/dialog';

@Component({
  selector: 'app-org-deactivate-popup',
  templateUrl: './org-deactivate-popup.component.html',
  styleUrls: ['./org-deactivate-popup.component.scss']
})
export class OrgDeactivatePopupComponent implements OnInit {


  constructor(private lgOut:LogoutService,private dialogRef: MatDialog,private router: Router, private store: StoreService,private eventEmitterService: EventEmitterService,private eventEmitter:EventEmitterService, @Inject(MAT_DIALOG_DATA) public data: any) { }

  ngOnInit(): void {
  }

  forceLogout(){
    this.lgOut.logout();
    //window.location.reload()
  }
}
