import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-teamspage',
  templateUrl: './teamspage.component.html',
  styleUrls: ['./teamspage.component.scss']
})
export class TeamspageComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
