<div class="row">
  <div class="mid-sec d-flex justify-content-center align-items-center">
    <div class="text-center">
      <div><img width="70px" [nexaeiImageSrc]="'successicon.png'" /></div>

      <h5 class="s-m pb-2 pt-3">Your Email is verified</h5>

      <p class="fs-12 d-none d-sm-block">
        Please use the OTP to complete your registration. OTP is valid <br />
        for next 30 minutes
      </p>

      <p class="fs-12 d-block d-sm-none">
        Please use the OTP to complete<br />
        your registration. OTP is valid for <br />
        next 30 minutes
      </p>

      <div class="d-flex justify-content-center">
        <div class="gray-div text-white text-center fs-14">{{ otp }}</div>
      </div>

      <p class="text-center text-green fs-14 pt-4">
        <span *ngIf="display"> {{ display }} sec </span>
      </p>

      <div class="d-flex justify-content-center">
        <div
          class="green-div text-white text-center fs-13 d-flex justify-content-center"
          (click)="redirect()"
        >
          <p>Continue with your registration</p>

          <div>
            <img
              class="ps-2"
              width="20px"
              [nexaeiImageSrc]="'rightarrow.png'"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
