import { Component, OnInit } from '@angular/core';
import { Validators, FormGroup, FormControl } from '@angular/forms';
import { ValidateService } from 'src/app/service/validate.service';

@Component({
  selector: 'app-contact-us',
  templateUrl: './contact-us.component.html',
  styleUrls: ['./contact-us.component.scss']
})
export class ContactUsComponent implements OnInit {
  contactForm: FormGroup;
  successMsg:boolean=false;
  constructor(private customValidator: ValidateService) {
    this.contactForm=new FormGroup({
      fullName: new FormControl(null, [
        Validators.required,
        this.customValidator.nameValidator(),
      ]),
      email: new FormControl(null, [
        Validators.required,
        this.customValidator.emailValidation(),
        this.customValidator.domainValidator(),
      ]),
      mobileNo: new FormControl(null, [
        Validators.required,
        this.customValidator.mobileValidator(),
        Validators.maxLength(10),
      ]),
      msg: new FormControl(null, [
        Validators.required,
      ]),
    });
   }

  ngOnInit(): void {
  }
  get contactFormControl() {
    return this.contactForm.controls;
  }

  onSubmit(){
    if (this.contactForm.invalid) {
      this.contactFormControl.fullName.markAsDirty();
      this.contactFormControl.email.markAsDirty();
      this.contactFormControl.mobileNo.markAsDirty();
      this.contactFormControl.msg.markAsDirty();
      this.successMsg=false
      return;
    }else{
      this.successMsg=true
    }
    this.contactForm.reset();
  }
}
