
    <section class="privacy pt-5 pb-5 bg-black">
 
        <div class="container">
                
           <div class="d-flex justify-content-center">
               
    
            <img class="priv-img" [nexaeiImageSrc]="'terms.png'"> 
    
           </div>
    
     
            <h2 class="text-center text-white pt-4 pb-5 fw-normal">Terms & Conditions</h2>
    
           <div class="border-top"></div>
    
           <div class="d-flex justify-content-center">
    
               <div class="col-md-10 pt-5">
                <p> Welcome to <b>NexAEI Colab</b> (An application service product of <b>Nexval Infotech Pvt. Ltd.</b>)! Our mission is to provide service (“Subscription Service”) of an efficient remote attendance and team collaboration application to improve overall efficiency of the human resource communication system of the organization.<br><br>
                    The Terms of Service apply to your access to and use of the<b>NexAEI Colab application</b> (“NexAEI Colab App or Colab App or the said App”) on your mobile phones and/or computer system / other online devices. By downloading or using the NexAEI Colab App, these terms will automatically apply to you as your ‘Consent’ for using this Colab App including and not limited to its features and privacy policy and therefore you should make sure that you read the terms carefully before using the Colab App. Our Privacy Policy explains how we collect and use your information while our terms outline your responsibilities when using our Colab App services. By using our Colab App Services i.e. the Subscription Services, you’re agreeing to be bound by these Terms and our Rules. Please refer to our Privacy Policy for information about how we collect, use, share and otherwise process information about you.<br><br>
                    If you are accepting these Terms and using the Colab App Services as part of an organization, you represent that you are authorized to do so, and in such case the words “you” or “your” in these Terms include that  or organization or entity. <br><br>
                    <u>Your Responsibilities as User</u><br><br>
                    NexAEI Colab App solicits use of its services in accordance with and in compliance of these terms and you must be an authorized team member of the user organization. 
                    We prominent feature of the service offer includes but not limited to capturing attendance information and team collaboration as part of team communication, but you cannot send it on to anyone else and you are not allowed to copy or modify the Colab App or any of its features or part of it or the intellectual property rights in any way. You are not allowed to source code of the said App and you should not try to translate the said App into other languages, or make derivative versions of the same. 
                     You need to register for an account and/or have log in rights by way of the access rights shared by your employer/team organization for using the Colab App Services. Help us to keep your account protected. Safeguard your password to the account, and keep your account information current. We recommend not to share your password with others.<br><br>
                    The Colab App stores and processes the data that you have provided to us so that you can mark your attendance and apply for leave in your service, apart from the other services provided by the said App. It is your responsibility to keep your phone and access to the Colab App secured. We therefore recommend that you do not jailbreak or root your phone by which you remove the software restrictions and limitation imposed by the official operating system of your device. It can make your device vulnerable to malware/viruses/malicious programs, compromise your phones security features and it can render NexAEI Colab App dysfunction resulting in Service disruption.<br><br>
                    At the bottom of these terms and conditions you will be able to find links to the Privacy Policy which coupled with this Terms will be relevant for use of the NexAEI Colab App. Nexval is committed to ensuring that the is useful and efficient to achieve the goals of effective team collaboration.<br><br> 
                    <u>User Content on the Services</u><br><br>
                    We may suspend any Users’ access to any or all subscription services without notice for (i) use of the subscription service in a way that violates applicable local, state or foreign laws or regulations or the terms of this usage agreement (ii) repeated instances of posting or uploading material that infringes or is alleged to infringe on the copyright or trademark rights of any person or entity. We may without notice, review, edit and delete any data or customer material that we determine in good faith violate these terms, provided that we have no duty to pre-screen, control, monitor or edit your data or materials. In the event of the subscription service is being subjected to security vulnerability or is consuming excessive bandwidth or is causing harm to Nexval Infotech or others, then we may with notice to you, suspend all or any access to the subscription service. Nothing in this clause limits our rights to terminate the agreement, if we determine that you have acted in a way that has or may negatively reflect on or affect us our prospects or our customers.<br><br>
                    <u>Rights and Ownership</u><br><br>
                    The NexAEI Colab App with all its features including but not limited to the trade marks, copy right, database rights and other intellectual property rights related to it, belong to and vested in Nexval Infotech Pvt. Ltd. and it has unfettered rights and obligations to protect and defend its own product. In no event or no circumstances the Nexval Infotech proprietary rights including that of NexAEI are deemed to be assigned, granted or otherwise passed to your or any of the users of the services. All products comprised in the NexAEI Colab subscription services including and limited to Full Service NexAEI products, are owned by Nexval Infotech and we retain all rights, titel and interest and all other ownership and Intellectual Property Rights in and to therein, including but not limited to its source code, object code, application programming interfaces documentation , data, information, trademarks, service marks and trade secrets.<br><br> 
                    Subject to payment of Service Charges ( by the User Company/Organization) during the subscription term and fulfilling all other obligations under the commercial contract, Nexval Infotech grants you an mutually agreed, non-exclusive, non-transferable right to use the Colab Services that is valid only for the subscription term. You agree not to copy, rent, lease , sell, reverse engineer, distribute or create derivative works based on the NeAEI Colab contents, the colab subscription services, in whole or in part by any means, except expressly authorized in writing by us.<br><br>
                     As users of the NexAEI Colab App you agree and acknowledge that Nexval Infotech retains the sole and absolute discretion regarding any of the features, functions and other benefits made available to you and the User in respect of the Colab Services. Nothing in this terms requires Nexval to provide or maintain any features, or limits Nexval’s rights to discontinue or alter any such features, functions or other benefits at any time and from time to time. From time to time NexAEI may notify you of updates or modifications to or new versions of the NexAEI Colab Service. Your usage of the updated or new versions of the Service confirms your acceptance of the new or updated Service and is also subject to this term.<br><br>
                    Any content or communication exchanged by way of violation of rights including and not limited to violation of intellectual property rights by way of misrepresentation or otherwise will be treated as punishable offence and in such an event, Nexval Infotech reserves it’s right to take legal action against the violator.<br><br>
                    We may stop providing the Services or any of its features within our sole discretion. We also retain the right to create limits on use and storage and may remove or limit content distribution on the Services.<br><br>
                    As between you as the user and Nexval, you own and retain all rights to the Customer Data. This terms does not grant us any ownership rights to the Customer Data. You grant permission to NexAEI Colab /Nexval Infotech to use the Customer Data only as necessary to provide the subscription service to your subject to the conditions enumerated and disclosed in the Privacy Policy.<br><br>
                    <u>Termination</u><br><br>
                    An user is free to stop using NexAEI Colab Services at any time at his/her discretion and subject to employment condition. We reserve the right to suspend or terminate access to the Services with or without notice subject to the terms herein.<br><br>
                    <u>Disclaimers — Service is “As Is”</u><br><br>
                    NexAEI Colab aims to give you great Services to each and every user but there are some things which we cannot guarantee specially things which are beyond our control. Your use of our Services is at your sole risk.<br> 
                    You should be aware that there are certain things that Nexval will not take responsibility for smooth working of the NexAEI Colab App. Certain functions of the app, such as the ability to download and store your mobile log information stored on cloud, the live attendance tracker and the mobile host function will all require the app to have an active internet connection. The connection can be Wi-Fi or provided by your mobile network provider, but Nexval Infotech cannot take responsibility for the app not working at full functionality if you do not have access to Wi-Fi, and you do not have any of your data allowance left.<br><br>
                    If you are using the app outside of an area with Wi-Fi, you should remember that your terms of agreement with your mobile network provider will still apply. As a result, you may be charged by your mobile provider for the cost of data for the duration of the connection while accessing the app, or other third party charges. In using the Colab App, you are accepting responsibility for any such charges, including roaming data charges if you use the app outside of your home territory (i.e. region or country) without turning off data roaming. If you are not the bill payer for the device on which you are using the app, please be aware that we assume that you have received permission from the bill payer for using the app.<br><br>
                    <u>Limitation of Liability</u><br><br>
                    We do not exclude or limit our liability to you where it would be illegal to do so including and not limited to any liability for the proven gross negligence, fraud or intentional misconduct of NexAEI in providing the Services.<br><br>
                    We make no representations or warranties about the suitability, reliability, availability, timeliness, security or accuracy or the Colab subscription service, data made available from the Colab subscription service. Application programming services may not be available at all times. Your use of the services is at your own risk and Colab subscription services are provided on “As Is” basis without warranty or condition of any kind. We disclaim all warranties and conditions of any kind, whether express, implied, or statutory, with regard to the Colab Subscription Services including all implied warranties or conditions of merchantability or fitness for a particular purpose, title and non-infringement. <br><br>
                    Any material downloaded or otherwise obtained through the use of the Colab Services is downloaded or obtained at users’ discretion and risk and you and users will be solely responsible for any damage to their computer systems or loss of data that results from the download of any such material. <br><br>
                    NexAEI will not be liable for any access delays or access interruptions to the Colab services, content non-delivery, mis-delivery, corruption, destruction or other modifications, unauthorized access to content entered in or breach of any security mechanisms utilized in, the services or Events beyond our reasonable control. <br><br>
                    In no event Nexval Infotech shall be liable for any indirect, incidental, punitive, or consequential damages or loss of profits, revenue, data or business opportunities. <br><br>
                    You agree that in no event shall the aggregate liability exceed the amount that has been paid for the NexAEI subscription services. <br><br>
                    
                    <u>Miscellaneous</u><br><br>
                    <u>Amendment; No Waiver.</u> We may update and change any part or all of this Terms associated with the use of the NexAEI Colab Subscription Service. If we update or change this Agreement, the updated Agreement will be posted at <a target="_blank" href="http://www.colab.nexaei.com/">www.colab.nexaei.com</a> or in-app notification. The updated Agreement will become effective and binding on the next business day after it is posted. When we change this Agreement, the "Last Modified" date above will be updated to reflect the date of the most recent version.<br><br>
                    
                    Your subscription will continue to be governed by these Terms prior to modification for the remainder of your current term. Upon renewal, the Agreement published by us on our website will apply.<br><br>
                    
                    No delay in exercising any right or remedy or failure to object will be a waiver of such right or remedy or any other right or remedy. A waiver on one occasion will not be a waiver of any right or remedy on any future occasion.<br><br>
                    
                    <u>Relationship of the Parties.</u> You and we agree that no joint venture, partnership, employment, or agency relationship exists between us.<br><br>
                    
                    <u>Compliance with Laws.</u> We will comply with all laws (where applicable) in our provision of the NexAEI Subscription Service and our processing of Customer Data. We reserve the right at all times to disclose any information as necessary to satisfy any law, regulation, legal process or governmental request. You will comply with all laws in your use of the Subscription Service and Consulting Services, including any applicable export laws. You will not directly or indirectly export, re-export, or transfer the Subscription Service or Consulting Services to prohibited countries or individuals or permit use of the Subscription Service by prohibited countries or individuals.<br><br>
                    
                    Dispute Resolution and Jurisdiction All disputes are subject to jurisdiction of Courts in Kolkata.<br><br>
                    
                    </p>
              </div>
    
           </div>
            
    
        </div>
    
    </section>
