import { Component, OnInit } from '@angular/core';
import { LocationStrategy } from '@angular/common';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { AuthHttpService } from 'src/app/service/auth-http.service';
import { CryptoService } from 'src/app/service/crypto.service';
import { environment } from 'src/environments/environment';
import { JwtService } from 'src/app/service/jwt.service';
import { StoreService } from 'src/app/service/store.service';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { OrgDeactivatePopupComponent } from '../authorized/org-deactivate-popup/org-deactivate-popup.component';
import * as moment from 'moment';
import {
  PermissionDataService,
  PermissionInterface,
} from 'src/app/service/permission-data.service';
import { EventEmitterService } from 'src/app/service/event-emitter.service';
import { UsersRoleService } from 'src/app/service/users-role.service';
import { UserDataService } from 'src/app/service/user-data.service';
import { allUsers } from 'src/app/interfaces/user.interface';
import { ConfirmUserNewEmail } from 'src/app/interfaces/user.interface';
import { UserIdleService } from 'angular-user-idle';
import { AttendenceService } from 'src/app/service/attendence.service';
import { CheckoutStatusService } from 'src/app/service/checkout-status.service';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],
})
export class LoginComponent implements OnInit {
  name: any;
  loginForm: FormGroup;
  userDetails: string = '';
  shPassword: boolean = false;
  shNum: number = 0;
  hasError: boolean = false;
  token: string = '';
  countrySearch: FormControl;
  isLoginFormLoading: boolean = false;
  data: string | null = null;
  testjson: string = '';
  loggedinCheck: boolean = false;
  drUserId: string = '';
  redrUrl: string = '';
  popupOpened: boolean = true;
  modalDialog: any;
  isUserEmailUpdated: boolean = false;
  linkToken: boolean = false;
  emailUpdatemessage: string;
  browserWidth: number;
  isProcessBlock: boolean = true;
  config: PermissionInterface | undefined;
  teamAdminRoleId: String = 'wwzpNubYPerVdWDfhxKGrQ==';
  emailConfirmation: boolean = false;
  isLeadToken: boolean = false;

  constructor(
    private http: AuthHttpService,
    private router: Router,
    private encryption: CryptoService,
    private jwt: JwtService,
    private rt: ActivatedRoute,
    private store: StoreService,
    private location: LocationStrategy,
    private route: ActivatedRoute,
    private dialog: MatDialog,
    private permissionService: PermissionDataService,
    private eventEmitterService: EventEmitterService,
    private usrRole: UsersRoleService,
    private UserDataService: UserDataService,
    private userIdle: UserIdleService,
    private attendence: AttendenceService,
    private checkoutStatus: CheckoutStatusService
  ) {
    this.eventEmitterService.headerEmitter.emit(false);
    history.pushState(null, null, window.location.href);
    this.location.onPopState(() => {
      history.pushState(null, null, window.location.href);
    });
    this.loginForm = new FormGroup({
      email: new FormControl(null, [Validators.required]),
      password: new FormControl(null, [Validators.required]),
    });

    this.browserWidth =
      window.innerWidth ||
      document.documentElement.clientWidth ||
      document.body.clientWidth;
  }

  get loginFormControls() {
    return this.loginForm.controls;
  }

  ngOnInit(): void {
    this.route.queryParams.subscribe((params) => {
console.log('Inside coming everytime');
      if (this.browserWidth < 500 && !params?.link) {
        this.router.navigate(['login-responsive']);
      }

	  if (params?.link) {
        this.linkToken = true;
        let flag: boolean = false;
        const auth_token = localStorage.getItem('authentication-token');
        const ref_token = localStorage.getItem('refresh_token');
        const link = params?.link.replace(/\s/g, '+');
        const token = params?.token.replace(/\s/g, '+');

        if (params.hasOwnProperty('VerifyEmail')) {
        this.emailConfirmation = true;
		localStorage.setItem('leadToken','true');
		}
		
        if (link.length > 0 && auth_token && !this.emailConfirmation) {
          this.confirmUpdateEmail(link, null);
        } else if (link.length > 0 && token.length > 0 && auth_token && this.emailConfirmation) { 
		  if(ref_token) this.verifyEmail(link, null); else this.verifyEmail(link, token);
        } else if (link.length > 0 && token.length > 0 && !auth_token && this.emailConfirmation) {
          this.verifyEmail(link, token);
        }  else if (link.length > 0 && token.length > 0 && !auth_token && !this.emailConfirmation) { 
          this.EmailUpdateUsingToken(link, token);
        }

	  } else if (
        JSON.stringify(params).length == 2 &&
        localStorage.getItem('authentication-token') != null && localStorage.getItem('leadToken') == null
      ) {
	console.log('inside first block');
	console.log(localStorage.getItem('authentication-token'));
	console.log(this.linkToken);
	console.log(JSON.stringify(params).length);
        this.isProcessBlock = false;
        this.router.navigate(['./dashboard/']);
      } else this.isProcessBlock = false;
    });
  }

  login(): void {
    if (this.loginForm.invalid) {
      this.loginFormControls.email.markAsDirty();
      this.loginFormControls.password.markAsDirty();
      return;
    }

    this.isLoginFormLoading = true;
    this.token = this.store.getInvitationToken();
    this.token == '' ? (this.testjson = '') : (this.testjson = this.token);

    let data: any = {
      email: this.loginForm.value.email.trim(),
      pwd: this.encryption.encrypt(this.loginForm.value.password.trim()),
    };

    this.http.login(data).subscribe(
      async (response) => {
        this.isLoginFormLoading = false;
        if (response.success) {
          this.data = null;
		  localStorage.removeItem('leadToken');
          if (response.data) {
            await this.store.setUserRole(response.data?.role_name);
            localStorage.setItem('roleid', response?.data?.roleid);
            this.permissionService.getPermissionMatrixFromHttp();
            this.config = this.permissionService.config;
            if (
              response.data.role_name == 'SUPERADMIN' &&
              response.data.orgdetails?.id == 'oipSVkZ9kc/MuElPCSGFsg=='
            ) {
              console.log('timer require');
              //Start watching for user inactivity.
              await this.userIdle.startWatching();
              await this.userIdle.onTimerStart().subscribe((count) => {
                this.router.navigate(['logout']);
              });
            } else {
              console.log('no timer require');
              await this.userIdle.stopWatching();
              await this.userIdle.stopTimer();
            }
            if (response.data.sendbird_user_details != null) {
              localStorage.setItem(
                'nickname',
                response.data.sendbird_user_details.nickname
              );
              localStorage.setItem(
                'user_id',
                response.data.sendbird_user_details.user_id
              );
              localStorage.setItem(
                'application_id',
                response.data.sendbird_user_details.application_id
              );
            }

            this.store.setUserEmail(this.loginForm.value.email);

            if (response.data.status_name == 'INVITED') {
              localStorage.setItem(
                'invitation_id',
                response.data.invitation_id
              );
            }
            localStorage.setItem('is_announcer', response.data.is_announcer);

            if (response.data.latest_check_out_statusurl != null) {
              localStorage.setItem(
                'latest_check_out_statusurl',
                response.data.latest_check_out_statusurl
              );
              localStorage.setItem(
                'latest_check_out_statusdesc',
                response.data.latest_check_out_statusdesc
              );
            }

            let fullname = response.data.fname + ' ' + response.data.lname;
            localStorage.setItem('fullname', fullname);

            let fname = response.data.fname;
            let lname = response.data.lname;

            localStorage.setItem('current_fname', fname);
            localStorage.setItem('current_lname', lname);
            localStorage.setItem('id', response.data.id);
            localStorage.setItem('is_watcher', response.data?.is_watcher);
            localStorage.setItem('radiumid', response.data?.id);
            localStorage.setItem('current_empid', response.data.empid);
            localStorage.setItem('roleid', response.data.roleid);
            localStorage.setItem('loginHomePageLoaded', 'false');
            localStorage.setItem(
              'current_designation',
              response.data.designation
            );
            localStorage.setItem('created_on', response.data.createdon);
            localStorage.setItem(
              'current_employmenttype',
              response.data.employmenttype
            );
            localStorage.setItem(
              'dr',
              response.data.dataconfig_details
                ? response.data.dataconfig_details.char_value
                : null
            );
            this.jwt.setToken(response.data.token);
            localStorage.setItem('refresh_token', response.data.refresh_token);
            this.attendence.fetchAllUsersFirstCheckIn();
            localStorage.setItem('token_timestamp', moment().format());
            this.http.scheduleRefresh();
            localStorage.setItem('refresh_token', response.data.refresh_token);
            localStorage.setItem('regions_id', response.data.regions_id);
            if (response.data.role_name == 'SUPERADMIN') {
              localStorage.setItem('new_region_id', response.data.regions_id);
            }
            localStorage.setItem('new_region', response.data.regionname);
            localStorage.setItem('region_name', response.data.regionname);
            localStorage.setItem('new_region', response.data.regionname);
            this.usrRole.getUserRoles();
            this.checkoutStatus.getCheckoutStatus();

            if (response.data.orgdetails != null) {
              localStorage.setItem(
                'org_name',
                response.data.orgdetails.orgname
              );
              localStorage.setItem('org_id', response.data.orgdetails.id);
            }

            let data: allUsers = {
              is_full_tree_user_req: 'true',
            };

            if (
              localStorage.getItem('new_region_id') == null &&
              localStorage.getItem('roleid') == this.teamAdminRoleId
            ) {
              data.regionid = localStorage.getItem('regions_id');
            }

            if (
              localStorage.getItem('org_name') != null &&
              localStorage.getItem('invite-string') == null
            ) {
              this.UserDataService.getAllUsers(data).subscribe((response) => {
                localStorage.setItem(
                  'all_users',
                  JSON.stringify(response.data)
                );
              });
            }

            if (
              response.data.userpicture_original != '' &&
              response.data.userpicture_original != null
            ) {
              localStorage.setItem(
                'user-profile-pic',
                response.data.userpicture_original.replace(
                  'http://',
                  'https://'
                )
              );
              localStorage.setItem(
                'user-profile-pic-thumb',
                response.data.userpicture.replace('http://', 'https://')
              );
            }

            if (response.data.orgdetails == null) {
              if (response.data.status_name == 'PassCodeNotVerified') {
                localStorage.setItem('invcode_valid', 'false');
              }
              this.router.navigate(['./signup/orgsetup-2']);
            } else if (response.data.orgdetails.status_name == 'PREACTIVE') {
              // this.router.navigate(['./dashboard/team-collab']);
              this.router.navigate(['./dashboard/']);
            } else if (
              response.data.orgdetails.status_name == 'DEACTIVATEORG'
            ) {
              if (response.data.role_name == 'SUPERADMIN') {
                this.openOrgDeactivate(true);
              } else {
                this.openOrgDeactivate(false);
              }
            } else {
              if (this.redrUrl == '') {
                setTimeout(() => {
                  this.router.navigate(['./dashboard/']);
                }, 500);
              } else if (this.redrUrl != '') {
                if (localStorage.getItem('user-role') == 'SUPERADMIN') {
                  this.router.navigate([this.redrUrl]);
                } else {
                  //this.router.navigate(['./dashboard/team-collab']);
                  this.router.navigate(['./dashboard/']);
                }
              }
            }
          }
          return;
        } else {
          this.data = response.message;
          this.hasError = true;
        }
      },
      () => {
        this.isLoginFormLoading = false;
        this.data = environment.errorMessage;
        this.hasError = true;
      }
    );
  }

  toDataURL = async (url: string) => {
    var res = await fetch(url);
    var blob = await res.blob();

    const result = await new Promise((resolve, reject) => {
      var reader = new FileReader();
      reader.addEventListener(
        'load',
        function () {
          resolve(reader.result);
        },
        false
      );

      reader.onerror = () => {
        return reject(this);
      };
      reader.readAsDataURL(blob);
    });

    return result;
  };

  showPassword() {
    if (this.shNum == 0) {
      this.shPassword = true;
      this.shNum = 1;
    } else {
      this.shNum = 0;
      this.shPassword = false;
    }
  }

  getInitialURL(url: string) {
    let regex = /[?&]([^=#]+)=([^&#]*)/g,
      params: any = {},
      match: any;
    while ((match = regex.exec(url))) {
      params[match[1]] = match[2];
    }
    return url;
  }

  openOrgDeactivate(flg: boolean) {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = true;
    dialogConfig.width = '50vw';
    dialogConfig.panelClass = 'borderless-dialog';
    dialogConfig.panelClass = 'bck-remove';
    dialogConfig.data = {
      chkFlag: flg,
    };
    this.modalDialog = this.dialog.open(
      OrgDeactivatePopupComponent,
      dialogConfig
    );
  }

  ngAfterViewInit() {
    //localStorage.clear();
  }

  EmailUpdateUsingToken(newEmail: string, token: string) {
    this.jwt.setToken(token);
    this.http.loginDataRefresh().subscribe((response) => {
      if (response.success && response.data.status_name != 'INACTIVE')
		if(!this.emailConfirmation)
        this.confirmUpdateEmail(newEmail, token);
		else
		this.verifyEmail(newEmail, token);
      else {
        this.isUserEmailUpdated = true;
        this.emailUpdatemessage =
          'Invalid request or Requested link has been expired';
        this.messageTimeout();
        this.isProcessBlock = false;
        this.jwt.setToken(null);
        localStorage.clear();
      }
    });
  }

  async confirmUpdateEmail(email: string, token: string = null) {
    let data: ConfirmUserNewEmail = {
      encrypted_email_details: email,
    };

    this.UserDataService.ConfirmUserNewEmail(data).subscribe((response) => {
      this.isUserEmailUpdated = true;
      if (response.success) {
        this.emailUpdatemessage = 'Your email has been successfully updated';
        localStorage.setItem('is_new_email_updated', '1');
      } else {
        this.emailUpdatemessage =
          'Invalid request or Requested link has been expired';
        localStorage.setItem('is_new_email_updated', '0');
      }

      if (this.browserWidth < 500) {
        this.router.navigate(['success-mobile']);
      } else if (this.browserWidth > 500 && token != null) {
        this.messageTimeout();
        this.jwt.setToken(null);
        localStorage.clear();
        this.isProcessBlock = false;
      } else { 
        this.router.navigate(['./dashboard/']);
      }
    });
  }

  async verifyEmail(email: string, token: string = null) {
	if(token != null)
	this.jwt.setToken(token);

    let data: any = {
      encrypted_email_details: email,
	  is_login: token== null ?'true':'false',
    };

    this.http.emailVerify(data).subscribe((response) => {
      this.isUserEmailUpdated = true;
	  this.isLeadToken = true;
      if (response.success && response.code == 200) {
		if(token != null && response.data.loginStatus == 'InActive'){
		this.isLeadToken = false;
        localStorage.setItem('email_verification_otp', response.data.otp);
		let dataStoreSave: any = {
		  firstName: response.data.fname,
		  lastName: response.data.lname,
		  email: response.data.email,
		};
		this.store.setSignupFormOneData(dataStoreSave);
        this.router.navigate(['./signup/verify-email/']);
		console.log('redirect to verify-email');
		} else if(response.data.loginStatus == 'Active') {
         this.emailUpdatemessage = 'Your email has been successfully verified';
         localStorage.setItem('is_email_verified', '1');
		}
      } else if(response.code == 403) {
        this.emailUpdatemessage =
          'Invalid request or Requested link has been expired';
        localStorage.setItem('is_email_verified', '0');
      } else if(response.code == 200 && !response.success) {
        this.emailUpdatemessage =
          'Invalid request or Requested link has been expired';
        localStorage.setItem('is_email_verified', '0');
      } else {
		this.emailUpdatemessage =
          'Invalid request or Requested link has been expired';
        localStorage.setItem('is_email_verified', '0');
	  }

	  if (this.browserWidth > 500 && token != null && this.isLeadToken) { 
		console.log('redirect to homescreen firt if');
        this.messageTimeout();
        this.jwt.setToken(null);
        localStorage.clear();
        this.isProcessBlock = false;
      } else if(token == null){
		console.log('redirect to dashboard');
        this.router.navigate(['./dashboard/']);
      }
    });

  }

  messageTimeout() {
    setTimeout(() => {
      this.isUserEmailUpdated = false;
      this.emailUpdatemessage = null;
    }, 8000);
  }

  stop() {
    this.userIdle.stopTimer();
  }

  stopWatching() {
    this.userIdle.stopWatching();
  }

  startWatching() {
    this.userIdle.startWatching();
  }

  restart() {
    this.userIdle.resetTimer();
  }
}
