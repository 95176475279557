<router-outlet></router-outlet>

<div class="row position-relative" *ngIf="notification">
	
    <div class="push-notification" (click)="emptyCollab()" style="cursor:pointer;">
		<button type="button" class="btn-close btn-close-white" aria-label="Close"></button>
        <div class="d-flex">
       
         <div class="newmssage"><img width="36px" [nexaeiImageSrc]="'s-logo.png'" />  </div>
   
         <p class="ps-2 newmessagefont">You've got a new chat</p>
         
       </div> 
   
   
       <div class="d-flex"> 
   
		<div class="pe-2" *ngIf="profileImage">  <img class="profilepic-new " src="{{profileImage}}">
		</div>
        <div class="pe-2" *ngIf="!profileImage"> 
          {{ this.UtilsService.getShortName(message.sender.name) }}
        </div>
   
         <div>
           <p class="fs-14 pb-1 mb-0">{{message?.sender?.name}}</p>
           <p class="l-h m-0 p-0 fs-14 text-secondary">{{message?.message}}</p>
         
         </div>
     
   
       </div>
   
   
       <!--<form class="d-flex">
         <input class="form-control me-2 n-search" type="search" placeholder="Sure let’s connect today" aria-label="Search">
         <div  class="send "><a  class="disable-input ms-1 mt-1"><img width="40px" [nexaeiImageSrc]="'emp-invite/send.png'"></a></div>
       </form>-->
   
        </div> 
   
   
       <!-- <div class="push-notification-small">
   
         <div class="ps-inner"> 
   
           <div class="d-flex">
          
            <div><img width="36px" [nexaeiImageSrc]="'s-logo.png'" />  </div>
      
            <p class="ps-2 pt-2">You've got a new chat</p>
            
          </div> 
      
      
          <div class="d-flex"> 
      
           <div class="pe-2">  <img class="profilepic-new " src="https://media.nexaei.com/org_335/ProfilePicture/user_2201/Thumb/7ef7a0f9-a055-4067-97cd-241542132b95.jpg">
           </div>
      
            <div>
              <p class="fs-14 pb-1 mb-0">Alex Thomas</p>
              <p class="l-h fs-14 text-secondary pb-0 mb-0">Hi can we have call?</p>
            
            </div>
        
      
          </div>
      
      
         </div>
   
         <div class="ps-inner">  
   
         <div class="d-flex">
        
          <div><img width="36px" [nexaeiImageSrc]="'s-logo.png'" />  </div>
    
          <p class="ps-2 pt-2">You've got a new chat</p>
          
        </div> 
    
    
        <div class="d-flex"> 
    
         <div class="pe-2">  <img class="profilepic-new " src="https://media.nexaei.com/org_335/ProfilePicture/user_2201/Thumb/7ef7a0f9-a055-4067-97cd-241542132b95.jpg">
         </div>
    
          <div>
            <p class="fs-14 pb-1 mb-0">Alex Thomas</p>
            <p class="l-h fs-14 text-secondary pb-0 mb-0">Delves into fundamental principles such as simplicity, consistency...</p>
          
          </div>
      
    
        </div>
    
    
        <form class="d-flex mt-3">
          <input class="form-control me-2 n-search" type="search" placeholder="Sure let’s connect today" aria-label="Search">
          <div  class="send "><a  class="disable-input ms-1 mt-1"><img width="40px" [nexaeiImageSrc]="'emp-invite/send.png'"></a></div>
        </form>
    
       </div>
    
    
    
        </div> -->

</div>