<app-sidebar></app-sidebar>
<app-header></app-header>

<div class="inner-sec_ emv-invittion">

        <div class="d-flex justify-content-center pt-5">
            <div class="col-lg-12 text-center">
            

                <img width="80px" [nexaeiImageSrc]="'mayaai.png'" />

                <p>Maya, <br>
                    your wellness assistant</p>

                    <p class="fs-13">I am here to give you answers about mental health and mindfulness questions.</p>


                    <p class="fs-13 text-secondary pt-4">You can ask me questions like</p>


                    <div class="row"> 

                         <div class="col-md-4 ">

                            <div class="border border-secondary p-2 pb-0 d-flex">

                          <div class="me-2 pt-2"> <img width="26px" [nexaeiImageSrc]="'round-black.png'" /> </div>
                           <p class="fs-12 text-start pb-0 pt-1"> How can I get started with a
                            mindfulness and meditation practice? </p>

                         </div>
                        </div>

                        <div class="col-md-4 ">

                            <div class="border border-secondary p-2 pb-0 d-flex">

                          <div class="me-2 pt-2"> <img width="26px" [nexaeiImageSrc]="'round-black.png'" /> </div>
                           <p class="fs-12 text-start pb-0 pt-1">How can mindfulness and meditation help with stress and anxiety? </p>

                         </div>
                        </div>


                        <div class="col-md-4 ">

                            <div class="border border-secondary p-2 pb-0 d-flex">

                          <div class="me-2 pt-2"> <img width="26px" [nexaeiImageSrc]="'round-black.png'" /> </div>
                           <p class="fs-12 text-start pb-0 pt-1"> How can I better manage my stress at work? (General) </p>

                         </div>
                        </div>

                      

                    </div>


                    <p class="fs-13 pt-5">I will try to answer your questions as per my knowledge and understanding.</p>



                    <p class="fs-13 text-secondary pt-4">However, I have some limitations.</p>


                    <div class="row"> 

                         <div class="col-md-4 ">

                            <div class=" gray_bg-new  limitations">

                           <p class="fs-12 text-start p-0 m-0"> I may take some time to think and process your question and reply back. </p>

                         </div>
                        </div>


                        <div class="col-md-4 ">

                            <div class=" gray_bg-new  limitations">

                           <p class="fs-12 text-start p-0 m-0"> I may occasionally generate incorrect information. </p>

                         </div>
                        </div>


                        <div class="col-md-4 ">

                            <div class=" gray_bg-new  limitations">

                           <p class="fs-12 text-start p-0 m-0">I have limited knowledge and may occasionally produce biased or harmful instructions.</p>

                         </div>
                        </div>

                      

                    </div>
                      

            </div>
        </div>
</div>