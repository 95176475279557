<div  class="col-12 res-stu bg-color-dark" style="height:100%; overflow-y:scroll;">
    <div  class="form-container">
        
        <span  class="logo col-md-12 text-center">
            <img [nexaeiImageSrc]="'mobile_logo.png'" />

            <h6>Update your name and password</h6>

        </span>
        <form class="pt-5" 
>
            <fieldset  class="form_card ">
                
                    <div  class="col-md-12">
                        <div  class="input_field">
                            <span  class="icon">
                            <img [nexaeiImageSrc]="'input_field/user.svg'" />
                                </span>
                                <input type="text" id="" placeholder="First Name">
                                </div>
                    </div>

                    <div  class="col-md-12">
                        <div  class="input_field">
                            <span  class="icon">
                            <img [nexaeiImageSrc]="'input_field/user.svg'" />
                                </span>
                                <input type="text" id="" placeholder="Last Name">
                                </div>
                    </div>


                    <div  class="col-md-12">
                        <div  class="input_field">
                            <span  class="icon">
                                <img [nexaeiImageSrc]="'input_field/lock.svg'" />
                                </span>
                                <input type="password" id="" placeholder="Password"></div>
                    </div>

                    <div  class="col-md-12">
                        <div  class="input_field">
                            <span  class="icon">
                                <img [nexaeiImageSrc]="'input_field/lock.svg'" />
                                </span>
                                <input type="password" id="" placeholder="Confirm New Password"></div>
                    </div>

                    <p class="fs-12 text-muted">Use minimum 8 characters with a mix of letters (uppercase, lowercase) Numbers & Symbols</p>


                    <div  class="col-md-12 pt-4">
                        <button  type="submit" class="btn primary-xl">Continue</button></div>
               
            </fieldset>

            <p class="fs-12 pt-4 w-100 text-center pb-5">By clicking on continue you agree to our 
                <a href="#" class="text-green"> Privacy Policy </a>  and  <a href="#" class="text-green"> Terms and Conditions </a> </p>

        </form>
        

   
       
    </div>
</div>