// This file can be replaced during build by using the `fileReplacements` array.
// `ng build` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  endpoint: 'https://radiumapi.nexkul.com/',
  endpoint2: 'https://attendanceapi.nexkul.com/',
  //endpoint: 'https://radiumapi.nexaei.com/',
  //endpoint2: 'https://attendance.nexaei.com/',
  imageBasePath: './assets/images/',
  supportweb: 'https://supportweb.nexaei.com/',
  fileviewerPath: 'https://viewer.staging.nexkul.com/',
  //fileviewerPath: 'https://viewer.nexaei.com/',
  staging_file_base_path: 'https://media.nexkul.com/',
  //staging_file_base_path: 'https://media.nexaei.com/',
  report_file_path: 'reports/TimeSheet/AttendanceReport_',
  log_report_file_path: 'reports/TimePunchLogReport/TimePunchLogReport_',
  errorMessageSignup:
    'Unable to process your request at this moment. Please try again after some time. If issue still persists, mail us at ',
  errorMessage:
    'Unable to process your request at this moment. Please try again after some time. If issue still persist, please contact your organisation administrator.',
  firebase: {
    apiKey: 'AIzaSyAlTTy7DXoMY-EkADI5WSfk9Xqd3oVMB48',
    authDomain: 'nexaeicolab.firebaseapp.com',
    projectId: 'nexaeicolab',
    appName: 'nexaeicolab',
    storageBucket: 'nexaeicolab.appspot.com',
    messagingSenderId: '85590487095',
    appId: '1:85590487095:web:dbe6d843c83a5a804d4fb2',
    measurementId: 'G-QYBWV3J655',
    vapidKey:
      'BBVthHFyQCvnhSQ4sxsTawQVgsi5_lIq7v_hwjrm6ooWb-MrDT-z44t5ICs7TMShM2_WbjPPSxfxzbQikx9LQsg',
  },
  publicKey:
    'BMvKBQ2JLxtI0HoWkLSREj-G-zf3V2y_EAobDKXRQ12tl-hYqE7ZUhLM9B2B7YYKX1haame09g2k2KSjg8cUDaM',
  privateKey: 'EiMdNVkbt36S2BjZ19IsIcv8mI4xFBMbSoYQJHS0b6g',
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
