import { Component, OnInit } from '@angular/core';
import { CryptoService } from 'src/app/service/crypto.service';

import { timer, Subscription } from 'rxjs';
import { Router } from '@angular/router';

@Component({
  selector: 'app-signup-verify-pass',
  templateUrl: './signup-verify-pass.component.html',
  styleUrls: ['./signup-verify-pass.component.scss'],
})
export class SignupVerifyPassComponent implements OnInit {
  otp: string;
  countDown: Subscription | null = null;
  counter = 1800;
  tick = 1000;
  otpTimeout: boolean = false;
  otpSendSuccess: boolean = false;
  display: any;
  public timerInterval: any;
  constructor(private encryption: CryptoService, private router: Router) {}

  ngOnInit(): void {
    this.otp = this.encryption.decrypt(
      localStorage.getItem('email_verification_otp')
    );
    this.timer(30);
  }
  ngAfterViewInit() {
    this.activateCountDown();
  }
  activateCountDown() {
    this.countDown = timer(0, this.tick).subscribe(() => {
      if (this.counter > 0) {
        --this.counter;
      } else {
        this.otpTimeout = true;
        this.otpSendSuccess = false;
        return false;
      }
    });
  }
  redirect() {
    this.router.navigate(['./signup/otp-verification/']);
  }
  timer(minute) {
    // let minute = 1;
    let seconds: number = minute * 60;
    let textSec: any = '0';
    let statSec: number = 60;

    const prefix = minute < 10 ? '0' : '';

    this.timerInterval = setInterval(() => {
      seconds--;
      if (statSec != 0) statSec--;
      else statSec = 59;

      if (statSec < 10) {
        textSec = '0' + statSec;
      } else textSec = statSec;

      this.display = `${prefix}${Math.floor(seconds / 60)}:${textSec}`;

      if (seconds == 0) {
        clearInterval(this.timerInterval);
      }
    }, 1000);
  }
}
