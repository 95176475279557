import { Injectable } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { StoreService } from 'src/app/service/store.service';
import { EventEmitterService } from 'src/app/service/event-emitter.service';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';

@Injectable({
  providedIn: 'root'
})
export class LogoutService {

  constructor(private dialogRef: MatDialog,private router: Router, private store: StoreService,private eventEmitterService: EventEmitterService,private eventEmitter:EventEmitterService) { }

  logout(){
    this.router.navigate(['/dashboard/logout']);
  }
}
