<div class="deactive-pop-up">
  <div class="modal-dialog modal-dialog-centered modal-lg">
    <div class="modal-content">
      <div class="modal-body text-center pt-4 pb-4 mt-1">
        <img width="90px" [nexaeiImageSrc]="'enterprice.png'" />

        <p
          class="fs-15 pt-3"
          *ngIf="
            data.chkType == 'RoleCghange' &&
            (selectRoleMsg == 1 ||
              selectRoleMsg == 2 ||
              selectRoleMsg == 3 ||
              selectRoleMsg == 7 ||
              selectRoleMsg == 8 ||
              selectRoleMsg == 9 ||
              selectRoleMsg == 10 ||
              selectRoleMsg == 14)
          "
        >
          <!-- Account deactivated, Connect Colab<br />support to renew subscription -->
          Congratulations on your new role as a <br />
          <strong>
            {{ usrRole.roleMappingFinalArray[this.data.chkFlagTo] }}</strong
          >
        </p>
        <p
          class="fs-15 pt-3"
          *ngIf="
            data.chkType == 'RoleCghange' &&
            (selectRoleMsg == 4 ||
              selectRoleMsg == 5 ||
              selectRoleMsg == 6 ||
              selectRoleMsg == 11 ||
              selectRoleMsg == 12 ||
              selectRoleMsg == 13)
          "
        >
          <!-- Account deactivated, Connect Colab<br />support to renew subscription -->
          Your role has been changed to
          <strong>
            {{ usrRole.roleMappingFinalArray[this.data.chkFlagTo] }}
          </strong>
        </p>
        <p
          class="fs-15 pt-2"
          *ngIf="
            data.chkType == 'RoleCghange' &&
            (selectRoleMsg == 1 || selectRoleMsg == 3)
          "
        >
          You can now access all admin permissions and <br />
          privileges present within this role
        </p>

        <p
          class="fs-15 pt-2"
          *ngIf="
            data.chkType == 'RoleCghange' &&
            (selectRoleMsg == 7 ||
              selectRoleMsg == 8 ||
              selectRoleMsg == 9 ||
              selectRoleMsg == 10)
          "
        >
          You can now access all region admin permissions and <br />
          privileges present within this role.
        </p>

        <p
          class="fs-15 pt-2"
          *ngIf="data.chkType == 'RoleCghange' && selectRoleMsg == 2"
        >
          Now you can create your own teams and add members
        </p>

        <p
          class="fs-15 pt-2"
          *ngIf="
            data.chkType == 'RoleCghange' &&
            (selectRoleMsg == 4 ||
              selectRoleMsg == 5 ||
              selectRoleMsg == 6 ||
              selectRoleMsg == 11 ||
              selectRoleMsg == 12 ||
              selectRoleMsg == 13 ||
              selectRoleMsg == 14)
          "
        >
          Please contact your enterprise admin for further assistance
        </p>

        <p class="fs-15 pt-3" *ngIf="data.chkType == 'OrgName'">
          <!-- Account deactivated, Connect Colab<br />support to renew subscription -->
          Your org name has been changed to
          <strong> {{ data.chkFlag }} </strong>
        </p>

        <div
          class="col-md-12 float-start d-flex justify-content-center pt-3 pb-0 mb-1"
        >
          <button
            (click)="reload()"
            type="submit"
            class="btn primary-xl primary-xl-modal w-50"
          >
            Ok
          </button>
        </div>
      </div>
    </div>
  </div>
</div>
