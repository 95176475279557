<div class="deactive-pop-up">
  <div class="modal-dialog modal-dialog-centered modal-lg">
    <div class="modal-content">
      <div class="modal-body text-center pt-4 pb-4 mt-1">
        <img width="90px" [nexaeiImageSrc]="'account-deactiveicon.png'" />

        <p class="fs-14 pt-4" *ngIf="this.data.chkFlag == false">
          Your account has been deactivated.
          <!-- Your account has been deactivated.<br /> Kindly contact your admin. -->
        </p>
        <p class="fs-14 pt-4" *ngIf="this.data.chkFlag == true">
          <!-- Account deactivated, Connect Colab<br />support to renew subscription -->
          Your account has been deactivated.
        </p>

        <div
          class="col-md-12 float-start d-flex justify-content-center pt-3 pb-0 mb-1"
        >
          <button
            (click)="forceLogout()"
            type="submit"
            class="btn primary-xl primary-xl-modal w-50"
          >
            Ok
          </button>
        </div>
      </div>
    </div>
  </div>
</div>
