import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-mediapage',
  templateUrl: './mediapage.component.html',
  styleUrls: ['./mediapage.component.scss']
})
export class MediapageComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
