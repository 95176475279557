import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
	this.redirectToLandingPage();
  }
  
  redirectToLandingPage() {
  location.href = "https://home.colab.nexaei.com/";
  }

}
