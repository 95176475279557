<app-sidebar></app-sidebar>
<app-header></app-header>

<div class="inner-sec_">
    <section>
        <div class="row">
            <div class="col-lg-12">
                <div class="subheader width-full">
                    <div class="col-md-12 pull-left space-heading">
                        <img [nexaeiImageSrc]="'member/bulk.svg'" /><h5 class="ps-3">My Space</h5>
                    </div>
                </div>
            <div class="w-100 pb-5 pt-4">    
                <div class="subheader-content width-full pb-2">
                    <p>10:22 AM</p>
                    <h6>"Neque porro quisquam est qui dolorem ipsum quia dolor sit amet, consectetur, ... Lorem Ipsum is simply dummy text of the printing and typesetting industry.
                    </h6>
                </div>

                <div class="subheader-content width-full pb-4 pt-4">
                    <p>10:22 AM</p>
                    <span>
                        <img [nexaeiImageSrc]="'blank-colab.png'" />
                        <img [nexaeiImageSrc]="'blank-colab.png'" />
                        <img [nexaeiImageSrc]="'blank-colab.png'" />
                        <img [nexaeiImageSrc]="'blank-colab.png'" />
                        <label>+2</label>
                    </span>
                </div>

                <div class="subheader-content width-full pb-2">
                    <p>10:22 AM</p>
                    <h6>"Neque porro quisquam est qui dolorem ipsum quia dolor sit amet, consectetur, ... Lorem Ipsum is simply dummy text of the printing and typesetting industry.
                    </h6>
                </div>
                
                <div class="date-content width-full pb-3 pt-2">
                    <p>10:22 AM</p>
                </div>

                <div class="subheader-content width-full pb-2">
                    <p>10:22 AM</p>
                    <h6>"Neque porro quisquam est qui dolorem ipsum quia dolor sit amet, and typesetting industry.
                    </h6>
                </div>
            </div>

            </div>
        </div>
    </section>
</div>