<section class="privacy pt-5 pb-5 bg-black">
  <div class="container">
    <!-- <a [routerLink]="['/signup/user-signup/']" class="pt-5"> <img width="15px" [nexaeiImageSrc]="'back.png'"> </a> -->

    <div class="d-flex justify-content-center">
      <img class="priv-img" [nexaeiImageSrc]="'privacy.png'" />
    </div>

    <h2 class="text-center text-white pt-5 pb-5 fw-normal">Privacy Policy</h2>

    <div class="border-top"></div>

    <div class="d-flex justify-content-center">
      <div class="col-md-10 pt-5">
        <p>
          Welcome to NexAEI Colab (a product of Nexval Infotech Pvt. Ltd.)
          (hereinafter referred to as &ldquo;Service Provider&rdquo;).
        </p>
        <br /><br />

        <p>
          Our Privacy Policy governs your use of the NexAEI Colab application
          and explains how we collect, safeguard, and disclose information that
          results from your use of our Service.
        </p>
        <br /><br />

        <p>
          NexAEI Colab Services will be provided through the NexAEI Colab
          software based on the user subscription agreement.
        </p>
        <br /><br />

        <p>
          By using Services, you hereby give specific, informed, unconditional,
          and unambiguous consent to the collection and use of information/data
          by the Service Provider in accordance with the applicable law in force
          as enumerated in this policy. Unless otherwise defined in this Privacy
          Policy, the terms used in this Privacy Policy have the same meanings
          as in our Terms and Conditions.
        </p>
        <br /><br />

        <p>
          Our Terms and Conditions (&ldquo;Terms&rdquo;) govern all use of our
          Service and together with the Privacy Policy constitute your agreement
          with us (&ldquo;Agreement&rdquo;).
        </p>
        <br /><br />

        <p>
          NexAEI is strongly committed to protecting your privacy and complying
          with your choices. Both personal and non-personal information
          collected is safeguarded according to the highest privacy and data
          protection standards adopted worldwide &ndash; the General Data
          Protection Regulation (GDPR) and/or in accordance with the provisions
          of The Digital Personal Data Protection Act, 2023(&ldquo;The
          Act&rdquo;). We have maintained a robust and effective data protection
          program in place which complies with existing law and abides by the
          data protection principles and is updated from time to time.
        </p>
        <br /><br />

        <p>Our Commitment -</p>
        <br />

        <ul>
          <li>
            Your information will not be shared, rented, or sold to any
            third-party service provider/s.
          </li>
          <li>
            We use state-of-the-art data security measures to protect your
            information from unauthorized users.
          </li>
        </ul>
        <br /><br />
        <p>
          <span
            >Specifically, this Privacy Policy describes our practices regarding
            -<br /><br />
            1. Data Collection<br />&nbsp;2. Data Usage<br />&nbsp;3. Data
            Sharing<br />&nbsp;4. Data Location and Retention<br />&nbsp;5.
            Cookies and Tracking Technologies<br />&nbsp;6. Communications<br />&nbsp;7.
            Data Security<br />&nbsp;8. Data Subjects Rights<br />&nbsp;9. Data
            Controller/Processor<br />&nbsp;10. Additional Notices</span
          >
        </p>
        <br />

        <p><strong>1. Data Collection </strong></p>
        <br />
        <br />

        <p>
          We use your data to provide and improve Service and only when it is
          necessary to fulfill the objectives of our services to you. By using
          the Service, you agree to the collection and use of information, which
          is done fairly, lawfully, in accordance with the applicable law in
          force, without any coercion or intimidation from the data subject, and
          under the strict supervision of the managerial control through the
          appropriate officer in charge. Unless otherwise defined in this
          Privacy Policy, the terms used in this Privacy Policy have the same
          meanings as in our Terms and Conditions. <br />
          <br />

          While providing NexAEI Colab Services, NexAEI collects the following
          information from the customers depending upon the service level
          subscription agreement –
        </p>
        <br />

        <span
          [nexaeiBackgroundImage]="'left-arrow.png'"
          [nexaeiBackgroundImage]="'left-arrow.png'"
        >
          Name, address, telephone number, email address, and business details
          when you/your employer creates an account.
        </span>

        <span
          [nexaeiBackgroundImage]="'left-arrow.png'"
          [nexaeiBackgroundImage]="'left-arrow.png'"
        >
          Interactions with third-party service providers.
        </span>

        <span
          [nexaeiBackgroundImage]="'left-arrow.png'"
          [nexaeiBackgroundImage]="'left-arrow.png'"
        >
          Location-based data (GPS coordinates) through our geolocation feature
          if the employer has requested users to log in with their GPS location.
        </span>

        <span
          [nexaeiBackgroundImage]="'left-arrow.png'"
          [nexaeiBackgroundImage]="'left-arrow.png'"
        >
          Collection of time entry and employee attendance/availability data
          when you use the App. Location information. Biometric
          information.</span
        >

        <span
          [nexaeiBackgroundImage]="'left-arrow.png'"
          [nexaeiBackgroundImage]="'left-arrow.png'"
        >
          Your/employee's Internet Protocol (IP) address (if using a browser),
          operating system, browser type, the address of a referring site, and
          any device identifier including any unique ID (if using a mobile
          device or tablet) and other devise diagnostic data.
        </span>

        <span
          [nexaeiBackgroundImage]="'left-arrow.png'"
          [nexaeiBackgroundImage]="'left-arrow.png'"
        >
          Employee collaboration data like messages, files, folders, and images.
          The lawful basis for collecting your data is by way of –</span
        >

        <span
          [nexaeiBackgroundImage]="'left-arrow.png'"
          [nexaeiBackgroundImage]="'left-arrow.png'"
        >
          Consent and by accepting our Privacy Policy along with the Terms &
          Conditions by your employer and/or you.</span
        >

        <span
          [nexaeiBackgroundImage]="'left-arrow.png'"
          [nexaeiBackgroundImage]="'left-arrow.png'"
        >
          Processing your data in the way explained above for us to provide
          NexAEI Colab service to your employees and/or you is necessary.
        </span>

        <span
          [nexaeiBackgroundImage]="'left-arrow.png'"
          [nexaeiBackgroundImage]="'left-arrow.png'"
        >
          Data is processed for the purposes of our legitimate interests in
          relation to the service provided and shall be deleted as soon as the
          legitimate purpose or interest in the collection of the data is
          fulfilled, in accordance with the applicable law. NexAEI will correct,
          amend, update, and/or append personal including forwarding requests to
          third parties’ information based on information provided by the
          customers in accordance with the necessity of the service agreements.
          These activities will be done in consonance with the objective of
          NexAEI Colab services.
        </span>

        <p><strong>2. Data Usage</strong></p>
        <br />

        <p>
          This captured data is collected to improve and secure our Services;
          for analytics, marketing, and sales purposes e.g. <br /><br />

          Communication with the employee users for comments, suggestions,
          requests, feedback, ratings, etc. for information about our products
          and services and/or any other correspondence or phone calls.
        </p>

        <span
          [nexaeiBackgroundImage]="'left-arrow.png'"
          [nexaeiBackgroundImage]="'left-arrow.png'"
        >
          marketing partners, and affiliate interactions For marketing purposes,
          we take note of the marketing channels and campaigns that drew you to
          NexAEI Colab.</span
        >

        <span
          [nexaeiBackgroundImage]="'left-arrow.png'"
          [nexaeiBackgroundImage]="'left-arrow.png'"
        >
          For providing employee analytics as may be demanded by the employer
          for internal operational purposes uses.
        </span>

        <span
          [nexaeiBackgroundImage]="'left-arrow.png'"
          [nexaeiBackgroundImage]="'left-arrow.png'"
        >
          For R & D and consequent improvement in our services including and not
          limited to the following –
        </span>

        <ul>
          <li>
            To gain a better understanding of how Users evaluate, use, and
            interact with our Services.
          </li>

          <li>
            To improve user experience and continually improve our products,
            offerings, and the overall performance of our services..
          </li>

          <li>
            Developing a new line of products/services based on user feedback
            and experience.
          </li>
          <li>
            To facilitate, sponsor, and offer certain events, contests, and
            promotions.
          </li>
        </ul>

        <p>
          NexAEI as a Service Provider through the NexAEI Colab application acts
          by default as a data processor and uses employment data within the
          scope of its data collection under the Services hitherto provided, to
          provide analytics and reporting services to the employer as per
          operational business requirements, to support our legitimate interests
          of the employer user/employee end-user, in accordance with the
          provisions of the applicable law or the Act. Personal information is
          the data captured in the process of use of the NexAEI Colab
          application entered by the Customer’s end-users/employees in the
          process of using the service of NexAEI. The customer is responsible
          for determining the purpose and means for the processing of personal
          information for its end-users. Therefore, the customer shall be
          regarded as the controller of personal data. NexAEI shall be regarded
          as the processor as per the request received from the customer.
          <br /><br />

          Cross-referencing your information with what is received from our
          partners in order to provide a more enhanced and personalized App
          experience.<br /><br />

          Using data information by the customer/employer in communication with
          the employee/end-user e.g. responding to your comments or inquiries,
          resolving disputes, contacting you regarding any other operational
          issues with the App or matter involving product or service
          transactions, to address problems on the App or our Service and to
          enforce our agreements with you.<br /><br />

          As a data processor, we can provide a data processing agreement to the
          team owners. If you are a team owner, you must comply with GDPR or the
          applicable law/the Act. The customer employer is responsible for
          collecting any necessary consent, providing any notice wherever
          necessary to their respective end-users, and being compliant with
          applicable data protection laws.<br /><br />

          Acceptance of our Terms and Conditions and this Privacy Policy will be
          deemed as the customer’s consent to the processing of data of the
          end-user/employee on behalf of the customer/organization for all
          purposes detailed in this document. If you wish to revoke such
          consent, please contact us by email at support@nexaei.com.
          <br /><br />

          It is however noted that collection of data will stop with the
          cessation of the contract with you and all collected data will be
          removed and/or deleted following guidelines of the prevailing law or
          the Act or according to the user agreement abided by the subscription
          policy.<br /><br />
        </p>

        <p><strong>3) Data Sharing</strong></p>
        <br />

        <p>
          We share your data (& feedback) with your organization as per the
          Terms agreed upon. <br /><br />

          Legal Compliance: In exceptional circumstances, we may disclose or
          allow government and law enforcement officials access to your Personal
          Data, in response to a subpoena, search warrant, or court order (or
          similar requirement), or in compliance with applicable laws and
          regulations and/or the Act. Such disclosure or access may occur if we
          believe in good faith that: (a) we are legally compelled to do so; (b)
          disclosure is appropriate in connection with efforts to investigate,
          prevent, or take action regarding actual or suspected illegal
          activity, fraud, or other wrongdoing; or (c) such disclosure is
          required to protect the security or integrity of our products and
          services.<br /><br />

          Service Providers: We may engage selected third-party companies and
          individuals to perform services complementary to our own. Such service
          providers include providers of Third Party Services (as defined in the
          Terms), hosting and server co-location services, e-mail distribution
          and monitoring services, data optimization, resellers, and providers
          of professional services related to our Service, and our legal,
          compliance, and financial advisors (collectively, “Service
          Providers“).<br /><br />

          These Service Providers may have access to a limited amount of your
          Personal Data, depending on each of their specific roles and purposes
          in facilitating and enhancing our Service, and may only use it for
          such limited purposes as determined in our agreements with them. When
          our Service Providers contact you in order to offer or promote our
          Service, they may additionally interest you in their services. Should
          you decide to engage in such activities with NexAEI Colab’s Service
          Providers, please note that such engagement is beyond the scope of
          NexAEI Colab’s Terms and Privacy Policy and will therefore be covered
          by our Service Provider’s terms and privacy policy.<br /><br />

          Our Service Providers shall be deemed as ‘Data Processors’ in
          circumstances where NexAEI assumes the role of ‘Data Controller’; and
          where NexAEI acts as the Data Processor for our Customer, the Service
          Provider shall be deemed our ‘Sub-Processor’. We shall protect your
          personal data under our control and/or under the control of the data
          processors, by taking reasonable security safeguards to prevent
          personal data breaches. <br /><br />

          While using our Service, we may ask you to provide us with certain
          personally identifiable information that can be used to contact or
          identify you (“Personal Data”). This information may include, but is
          not limited to:
        </p>
        <br /><br />

        <ul>
          <li>Email address</li>
          <li>First name and Last name</li>
          <li>Phone number</li>
          <li>Address, Country, State, Province, ZIP/Postal code, City</li>
          <li>Cookies and Usage Data</li>
          <li>
            User’s location Global Positioning System (GPS) data (as directed by
            your employer)
          </li>
          <li>
            Employee collaboration data like messages, files, folders, and
            images.
          </li>
          <li>User official attendance/availability information.</li>
          <li>
            Other employee profile data like profile image. (as per the
            discretion of the user organization and provision provided in the
            service).
          </li>
        </ul>

        <p>
          to facilitate the operation of the App and Service and to perform
          related services. For example, and without limitation, maintenance
          services, database management, web analytics, and improvement of the
          Service’s features or to assist us in analysing how our App and
          Service are used. <br /><br />

          We may share aggregated information that does not include personal
          information and we may otherwise disclose such information with third
          parties for industry analysis, demographic and analytical profiling,
          and other purposes. <br /><br />

          Personal information of the customers may be disclosed to third
          parties for providing added services with the explicit consent of the
          customers, and such consent is obtained before disclosure to meet
          NexAEI Colab’s objectives related to privacy. Such disclosure shall be
          made only with the explicit consent of the customers obtained before
          such disclosure or due to the requirement of any law or regulation.
          NexAEI Colab shall create and retain a complete, accurate, and timely
          record of authorized disclosures of personal information to meet its
          objectives related to privacy. Such personal information shall be
          disclosed only to third parties who have agreements with NexAEI to
          protect personal information in a manner consistent with the relevant
          aspects of NexAEI’s Colab privacy policy and to provide additional
          services. NexAEI Colab shall create and maintain records of detected
          or reported unauthorized disclosures of personal information that are
          complete, accurate, and timely. <br /><br />

          NexAEI Colab shall obtain privacy commitment from vendors and/or other
          third parties and NexAEI Colab to have access to personal information
          and shall ensure and control that such access shall not exceed the
          terms of the agreement, instruction, or requirements. NexAEI Colab
          shall take remedial action in response to the misuse of personal
          information by third parties with whom such data has been shared. A
          process exists for obtaining commitments from vendors and/or other
          third parties to report to NexAEI Colab on actual or suspected
          disclosures of personal data. NexAEI Colab has a process for
          determining whether notification of a privacy breach is required
          including the method to be used, the timeline, and the identification
          of recipients of such notifications.
        </p>
        <br />

        <P><strong>4. Data Location & Retention</strong></P
        ><br />

        <P><strong>Data Location</strong></P
        ><br />

        <P
          >The data we collect is hosted on Mumbai Zone at Amazon Web Services.
          This allows us to provide a reliable service and keeps your data
          available whenever you need it. We have also established a disaster
          recovery plan as additional support from our end.</P
        ><br /><br />

        <P><strong>Data Retention</strong></P
        ><br />

        <P
          >We generally retain three types of Information account details,
          personal information, and service transaction data to fulfill the
          purposes for which we collected it, including to satisfy any legal,
          accounting, or reporting requirements, to establish or defend legal
          claims, and/or for fraud prevention purposes.<br /><br />

          We will retain your data for as long as it is reasonably necessary as
          per the service agreement, terms of usage, and subscription contract
          to provide our services and offerings and to comply with our legal and
          contractual obligations and to protect ourselves from any potential
          disputes, all in accordance with our data retention policy and/or the
          applicable law in force.<br /><br />

          If you have any questions or requests for specific discretion about
          our data retention policy, please contact us by e-mail at
          support@nexaei.com to satisfy any legal, accounting, or reporting
          requirements, to establish or defend legal claims, or for fraud
          prevention. We determine that the appropriate retention period for
          transactional data including archived and deleted Information is
          relevant as per the subscription agreement and account information<br /><br />

          as is prescribed under various applicable laws in force. Specific
          discretion could be handled for customized archival of documents and
          data after consideration of factors such as the amount, nature, and
          sensitivity of the information, the potential risk of harm from
          unauthorized use or disclosure of your information, the purposes for
          which we process your Information and the applicable legal
          requirements, or permanent deletion of data. Customers on being
          properly authenticated shall be given access to view the personal
          information retained about them on an individual basis on request from
          customers and screenshots shall be facilitated as proof of such
          retention within a reasonable time of demand to access such data. In
          case of denial of access to personal information to the subscriber, it
          shall be justified with reasons. The entire process of responding to
          the request of the data subjects shall be governed by the appropriate
          officer of the company. <br /><br />

          We shall, unless retention is necessary for compliance with any law
          for the time being in force, erase personal data if you withdraw
          consent as soon as it is reasonable to assume that the specified
          purpose of such data is no longer necessary. However, if necessary, as
          per policy we may retain your information for our compliance with a
          legal, accounting, or reporting obligation or for archiving purposes
          as per the requirement of any applicable law in force and/or the Act.
          <br /><br />

          All data that is no longer necessary to retain in the system, either
          on request of the data subject/principal or in accordance with the
          applicable law in force, shall be deleted /purged from the system
          either manually or automatically. In case of automatic
          deletion/purging of the data, a screenshot for configuration will be
          retained. There is a robust policy to identify all requests for
          deletion of personal data under the strict managerial supervision of
          the officer in charge. Screenshots of all such expunged data shall be
          retained for inspection for a specific period. <br /><br />

          The personal information no longer necessary to retain is anonymized,
          disposed of, or destroyed in a manner that prevents loss, theft,
          misuse, or unauthorized access. </P
        ><br /><br />

        <P><strong>5. Cookies & Tracking Technologies</strong></P
        ><br />

        <p>
          We use cookies and similar tracking technologies to track the activity
          on our Service and we hold certain information.<br /><br />

          Cookies are files with a small amount of data which may include an
          anonymous unique identifier. Cookies are sent to your browser from a
          website and stored on your device.<br /><br />

          You can instruct your browser to refuse all cookies or to indicate
          when a cookie is being sent. However, if you do not accept cookies,
          you may not be able to use some portions of our Service.<br /><br />
        </p>

        <P><strong>Other Data</strong></P
        ><br />

        <p>
          While using our Service, we may also collect the following information
          on behalf of your employer: sex, age, date of birth, place of birth,
          citizenship, registration at the place of residence and actual
          address, telephone number (work, mobile), details of documents on
          education, qualification, professional training, employment
          agreements, NDA agreements, information on bonuses and compensation,
          information on marital status, family members, office location and
          other data.
        </p>
        <br />

        <p>
          <strong>6. Communications </strong> We engage in service
          communications only through e-mail, phone, SMS, and notifications,
          only if opted for by you or as directed by your owner.
        </p>
        <br />

        <P><strong>Service Communications:</strong></P
        ><br />

        <p>
          We may contact you with important information regarding our Service.
          For example, we may send you notifications (through any of the means
          available to us) of changes or updates to our Service, service
          changes, log-in attempts password reset notices, etc. You can control
          your communications and notifications settings from your User Profile
          settings. However, please note that you will not be able to opt out of
          receiving certain service communications that are integral to your use
          (like password resets)<br /><br />

          Promotional Communications: We may also notify you about new features,
          additional offerings, events, special opportunities, or any other
          information we think our Users will find valuable. We may provide such
          notices through any of the contact means available to us (e.g., phone,
          mobile, or e-mail), through the Service, or through our marketing
          campaigns on any other sites or platforms.<br /><br />

          If you do not wish to receive such promotional communications, you may
          notify NexAEI Colab's at any time by sending an email to
          support@nexaei.com, changing your communications preferences in your
          User Profile settings, or by following the “Unsubscribe”, “stop”,
          “opt-out” or “change e-mail preferences” instructions contained in the
          promotional communications you receive.
        </p>
        <br /><br />

        <P><strong>7. Data Security</strong></P
        ><br />

        <p>
          We take precautions to protect the security of your information. We
          secure your personal data using industry-standard physical,
          procedural, and technical measures. In addition, people can commit
          intentional crimes, make mistakes, or fail to follow policies.<br /><br />

          However, please be aware that in spite of us putting in reasonable
          efforts to protect your personal information, and regardless of any
          security measures we use, we cannot and do not guarantee the absolute
          protection and security of any personal data stored with us.<br />
        </p>
        <br /><br />

        <P><strong>8. Data Subject Rights</strong></P
        ><br />

        <p>
          We are committed to helping our customers meet the data subject rights
          requirements of the General Data Protection Regulation (GDPR) and/or
          any applicable law / the Act.<br /><br />

          As mandated by the Act, subject to restrictions mentioned therein, you
          have the right to obtain from us personally or through your consent
          manager, in relation to your consent of collection, use, and retention
          of your data/personal information, to seek a summary of personal data
          which is being processed by us and the activities undertaken in
          relation thereto and/or the identities of all other bodies,
          organizations including and not limited to the data processor with
          whom such personal data has been shared and/or any other information
          related to the personal data and its usages. <br /><br />

          Following such demand as above, you shall have the right to
          correction, completion, updating, and erasure of your personal data
          for the processing of which you had previously given consent, in
          accordance with the requirement of any applicable laws and/or the Act,
          in so far as such request shall not come in the way of or hamper
          providing service by us to you under the contract. <br /><br />

          NexAEI has a process to ensure every complaint, grievance of, and/or
          dispute with customers/data subjects is thoroughly addressed through
          the concerned officer and there is periodic monitoring of such
          complaints, grievances, and their effective resolution, corrections,
          and actions necessary to mitigate the identified deficiencies.

          <br />
        </p>
        <br />

        <p>Article 5 of the GDPR requires that personal data shall be:</p>
        <br />

        <ul>
          <li>
            Processed lawfully, fairly, and in a transparent manner in relation
            to end users.
          </li>

          <li>
            Collected for specified, explicit, and legitimate purposes and not
            further processed in a manner that is incompatible with those
            purposes.
          </li>

          <li>
            Adequate, relevant, and limited to what is necessary for relation to
            the purposes for which they are processed.
          </li>

          <li>
            Accurate and, where necessary, kept up to date; every reasonable
            step must be taken to ensure that personal data that are inaccurate,
            having regard to the purposes for which they are processed, are
            erased or rectified without delay.
          </li>

          <li>
            Kept in a form that permits identification of data subjects for no
            longer than is necessary for the purposes for which the personal
            data are processed, personal data may be stored for longer periods
            insofar as the personal data will be processed solely for archiving
            purposes in the interest of your employer’s organization.
          </li>

          <li>
            Processed in a manner that ensures appropriate security of the
            personal data, including protection against unauthorized or unlawful
            processing and against accidental loss, destruction, or damage,
            using appropriate technical or organizational measures.
          </li>
        </ul>
        <br />

        <p>
          Please note that once you contact us by e-mail, we may instruct you on
          how to fulfil your request independently through your User Profile
          settings; or may require additional information and documents,
          including certain Personal Data, in order to authenticate and validate
          your identity and <br /><br />

          to process your request.
        </p>
        <br /><br />

        <p><strong>9. Data Controller/Processor</strong></p>

        <br />

        <p>
          We are the Controller of our Visitors’ and Customers’ Personal Data,
          and certain types of User Data; we are the Processor of any Personal
          Data contained in Customer Data, and of User Data processed on behalf
          of our Customers. <br /><br />

          NexAEI is the “Data Controller” of its Customers’ Personal Data, as
          well as User Data consisting of profile and contact details, as well
          as usage, preferences, engagement, and analytics data. With respect to
          such data, we assume the responsibilities of the Data Controller
          (solely to the extent applicable under law), as outlined in this
          Privacy Policy.<br /><br />

          If any of our Users upload or submit Customer Data or other content to
          our Service which includes Personal Data (e.g., by submitting their
          own clients’ contact details to one of their Boards), such data will
          only be processed by NexAEI on behalf of your employer, who is the
          owner of the respective Account, along with all other User Data
          processed on your employer’s behalf.<br /><br />

          In such instances, your employer shall be deemed the “Data Controller”
          of such data, and NexAEI will process such data on the Customer’s
          behalf, as its “Data Processor”.
        </p>
        <br /><br />

        <p><strong>10. Additional Notices</strong></p>
        <br />

        <p>
          Updates and Amendments: We may update and amend this Privacy Policy
          from time to time by posting an amended version on our Service. The
          amended version will be effective as of the date it is published. When
          we make material changes to this Privacy Policy, we’ll provide Users
          with notice as appropriate under the circumstances, e.g., by
          displaying a prominent notice within the Service or by sending an
          email. Your continued use of the Service after the changes have been
          implemented will constitute your acceptance of the changes.<br /><br />

          Questions, Concerns, or Complaints: If you have any comments or
          questions regarding our Privacy Policy or any concerns regarding your
          Personal Data held with us, please get in touch with NexAEI Colab
          support at support@nexaei.com. If you are a GDPR-protected individual,
          you also have the right to lodge a complaint with an EU supervisory
          authority.
        </p>
      </div>
    </div>
  </div>
</section>
