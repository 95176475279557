<app-sidebar></app-sidebar>
<app-header></app-header>

<div class="inner-sec_">
    <section>
        <div class="row">
            <div class="col-lg-12">
                <div class="subheader width-full">
                        <h5><a href="#!"><</a>Mon, 1 Mar 21</h5>

                <div class="col-md-5 pull-left button-section">
                    <button>
                        <img [nexaeiImageSrc]="'calendar/download.png'" />
                    </button>
                    <button>
                        <img [nexaeiImageSrc]="'calendar/search.png'" />
                    </button>
                </div>
            </div>   
            
            <!--table section-->
            <div class="tablesection col-lg-12 text-left">
                <table class="table">
                    <tbody>
                            <tr>
                                <td><span class="border"><img [nexaeiImageSrc]="'member/user.png'" /></span><span>Amkit kumar</span></td>
                                <td>Present <label><img [nexaeiImageSrc]="'calendar/tick.png'" /></label></td>
                                <td>
                                   <label>
                                    10:30 AM In
                                   </label>

                                   <label>
                                    10:30 PM Out
                                   </label>

                                   <span><img [nexaeiImageSrc]="'calendar/location.png'" /></span>
                                </td>
                                <td>
                                   Clock Time: 11 hr
                                </td>
                                <td>
                                    <span class="dotted"><img [nexaeiImageSrc]="'calendar/dotted.png'" /></span>
                                </td>
                                <td>
                                    <span><img [nexaeiImageSrc]="'calendar/tv.png'" /></span>
                                </td>
                            </tr>  

                            <tr>
                                <td><span class="border"><img [nexaeiImageSrc]="'member/user.png'" /></span><span>Amkit kumar</span></td>
                                <td>Present <label><img [nexaeiImageSrc]="'calendar/tick.png'" /></label></td>
                                <td>
                                   <label>
                                    10:30 AM In
                                   </label>

                                   <label>
                                    10:30 PM Out
                                   </label>

                                   <span><img [nexaeiImageSrc]="'calendar/location.png'" /></span>
                                </td>
                                <td>
                                   Clock Time: 11 hr
                                </td>
                                <td>
                                    <span class="dotted"><img [nexaeiImageSrc]="'calendar/dotted.png'" /></span>
                                </td>
                                <td>
                                    <span><img [nexaeiImageSrc]="'calendar/tv.png'" /></span>
                                </td>
                            </tr>  

                            <tr>
                                <td><span class="border"><img [nexaeiImageSrc]="'member/user.png'" /></span><span>Amkit kumar</span></td>
                                <td>Present <label><img [nexaeiImageSrc]="'calendar/tick.png'" /></label></td>
                                <td>
                                   <label>
                                    10:30 AM In
                                   </label>

                                   <label>
                                    10:30 PM Out
                                   </label>

                                   <span><img [nexaeiImageSrc]="'calendar/location.png'" /></span>
                                </td>
                                <td>
                                   Clock Time: 11 hr
                                </td>
                                <td>
                                    <span class="dotted"><img [nexaeiImageSrc]="'calendar/dotted.png'" /></span>
                                </td>
                                <td>
                                    <span><img [nexaeiImageSrc]="'calendar/tv.png'" /></span>
                                </td>
                            </tr>  

                            <tr>
                                <td><span class="border"><img [nexaeiImageSrc]="'member/user.png'" /></span><span>Amkit kumar</span></td>
                                <td>Present <label><img [nexaeiImageSrc]="'calendar/tick.png'" /></label></td>
                                <td>
                                   <label>
                                    10:30 AM In
                                   </label>

                                   <label>
                                    10:30 PM Out
                                   </label>

                                   <span><img [nexaeiImageSrc]="'calendar/location.png'" /></span>
                                </td>
                                <td>
                                   Clock Time: 11 hr
                                </td>
                                <td>
                                    <span class="dotted"><img [nexaeiImageSrc]="'calendar/dotted.png'" /></span>
                                </td>
                                <td>
                                    <span><img [nexaeiImageSrc]="'calendar/tv.png'" /></span>
                                </td>
                            </tr>  

                            <tr>
                                <td><span class="border"><img [nexaeiImageSrc]="'member/user.png'" /></span><span>Amkit kumar</span></td>
                                <td>Present <label><img [nexaeiImageSrc]="'calendar/tick.png'" /></label></td>
                                <td>
                                   <label>
                                    10:30 AM In
                                   </label>

                                   <label>
                                    10:30 PM Out
                                   </label>

                                   <span><img [nexaeiImageSrc]="'calendar/location.png'" /></span>
                                </td>
                                <td>
                                   Clock Time: 11 hr
                                </td>
                                <td>
                                    <span class="dotted"><img [nexaeiImageSrc]="'calendar/dotted.png'" /></span>
                                </td>
                                <td>
                                    <span><img [nexaeiImageSrc]="'calendar/tv.png'" /></span>
                                </td>
                            </tr>  

                            <tr>
                                <td><span class="border"><img [nexaeiImageSrc]="'member/user.png'" /></span><span>Amkit kumar</span></td>
                                <td>Present <label><img [nexaeiImageSrc]="'calendar/tick.png'" /></label></td>
                                <td>
                                   <label>
                                    10:30 AM In
                                   </label>

                                   <label>
                                    10:30 PM Out
                                   </label>

                                   <span><img [nexaeiImageSrc]="'calendar/location.png'" /></span>
                                </td>
                                <td>
                                   Clock Time: 11 hr
                                </td>
                                <td>
                                    <span class="dotted"><img [nexaeiImageSrc]="'calendar/dotted.png'" /></span>
                                </td>
                                <td>
                                    <span><img [nexaeiImageSrc]="'calendar/tv.png'" /></span>
                                </td>
                            </tr>  

                            <tr>
                                <td><span class="border"><img [nexaeiImageSrc]="'member/user.png'" /></span><span>Amkit kumar</span></td>
                                <td>Present <label><img [nexaeiImageSrc]="'calendar/tick.png'" /></label></td>
                                <td>
                                   <label>
                                    10:30 AM In
                                   </label>

                                   <label>
                                    10:30 PM Out
                                   </label>

                                   <span><img [nexaeiImageSrc]="'calendar/location.png'" /></span>
                                </td>
                                <td>
                                   Clock Time: 11 hr
                                </td>
                                <td>
                                    <span class="dotted"><img [nexaeiImageSrc]="'calendar/dotted.png'" /></span>
                                </td>
                                <td>
                                    <span><img [nexaeiImageSrc]="'calendar/tv.png'" /></span>
                                </td>
                            </tr>  

                            <tr>
                                <td><span class="border"><img [nexaeiImageSrc]="'member/user.png'" /></span><span>Amkit kumar</span></td>
                                <td>Present <label><img [nexaeiImageSrc]="'calendar/tick.png'" /></label></td>
                                <td>
                                   <label>
                                    10:30 AM In
                                   </label>

                                   <label>
                                    10:30 PM Out
                                   </label>

                                   <span><img [nexaeiImageSrc]="'calendar/location.png'" /></span>
                                </td>
                                <td>
                                   Clock Time: 11 hr
                                </td>
                                <td>
                                    <span class="dotted"><img [nexaeiImageSrc]="'calendar/dotted.png'" /></span>
                                </td>
                                <td>
                                    <span><img [nexaeiImageSrc]="'calendar/tv.png'" /></span>
                                </td>
                            </tr>  

                            <tr>
                                <td><span class="border"><img [nexaeiImageSrc]="'member/user.png'" /></span><span>Amkit kumar</span></td>
                                <td>Present <label><img [nexaeiImageSrc]="'calendar/tick.png'" /></label></td>
                                <td>
                                   <label>
                                    10:30 AM In
                                   </label>

                                   <label>
                                    10:30 PM Out
                                   </label>

                                   <span><img [nexaeiImageSrc]="'calendar/location.png'" /></span>
                                </td>
                                <td>
                                   Clock Time: 11 hr
                                </td>
                                <td>
                                    <span class="dotted"><img [nexaeiImageSrc]="'calendar/dotted.png'" /></span>
                                </td>
                                <td>
                                    <span><img [nexaeiImageSrc]="'calendar/tv.png'" /></span>
                                </td>
                            </tr>  

                            <tr>
                                <td><span class="border"><img [nexaeiImageSrc]="'member/user.png'" /></span><span>Amkit kumar</span></td>
                                <td>Present <label><img [nexaeiImageSrc]="'calendar/tick.png'" /></label></td>
                                <td>
                                   <label>
                                    10:30 AM In
                                   </label>

                                   <label>
                                    10:30 PM Out
                                   </label>

                                   <span><img [nexaeiImageSrc]="'calendar/location.png'" /></span>
                                </td>
                                <td>
                                   Clock Time: 11 hr
                                </td>
                                <td>
                                    <span class="dotted"><img [nexaeiImageSrc]="'calendar/dotted.png'" /></span>
                                </td>
                                <td>
                                    <span><img [nexaeiImageSrc]="'calendar/tv.png'" /></span>
                                </td>
                            </tr>  
                    </tbody>
                </table>                
            </div>
         </div>
        </div>
    </section>
</div>                