import { Injectable } from '@angular/core';
import { AuthHttpService } from 'src/app/service/auth-http.service';

@Injectable({
  providedIn: 'root'
})
export class CheckoutStatusService {

  public status: JSON;
  public checkoutStatus = [];
  public checkoutStatusList = [];
  public checkoutStatusListDesc = [];
  constructor(private authHttp: AuthHttpService) {}
 
  getCheckoutStatus() {
    let dataRoles = {};
    if (localStorage.getItem('checkout_status_list') == null) {
      this.authHttp.getCheckoutStatusList(dataRoles).subscribe((response) => {
        if (response.success) {
          this.checkoutStatus = response.data;
          localStorage.removeItem('checkout_status_list');
          localStorage.setItem(
            'checkout_status_list',
            JSON.stringify(this.checkoutStatus)
          );
        }
      });
    } else {
      let data = JSON.parse(localStorage.getItem('checkout_status_list'));
      for (let i = 0; i < data.length; i++) {
        this.checkoutStatusList[data[i].id] = data[i].imgurl;
        this.checkoutStatusListDesc[data[i].id] = data[i].desc;
      }
    }
  }
}
