import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-mobilescreenthree',
  templateUrl: './mobilescreenthree.component.html',
  styleUrls: ['./mobilescreenthree.component.scss']
})
export class MobilescreenthreeComponent implements OnInit {
  displayMessage:string = 'Your profile updated successfully';
  is_new_email:string;
  is_invitation_accepted: boolean = true;
  constructor() { }

  ngOnInit(): void {
    localStorage.setItem('invitation_id','accepted');
	if(localStorage.hasOwnProperty('is_new_email_updated'))
	this.confirmEmailUpdated();
  }
  
  confirmEmailUpdated() {
	this.is_invitation_accepted = false;
    this.is_new_email = localStorage.getItem('is_new_email_updated');
	if(this.is_new_email != null) {
		if(this.is_new_email == '1') {
			 this.displayMessage = "Your email address has been successfully updated.";
		} else if(this.is_new_email == '0') {
		 this.displayMessage = "The Requested link has expired.";
		}
		this.clearCacheTimeout();
	}
  }	
  
  clearCacheTimeout() {
	setTimeout(() => {
	 localStorage.clear();
	}, 2000);
  }

}
