import { Component, OnInit, Inject } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { StoreService } from 'src/app/service/store.service';
import { EventEmitterService } from 'src/app/service/event-emitter.service';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';

import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { UsersRoleService } from 'src/app/service/users-role.service';
@Component({
  selector: 'app-popup-notification',
  templateUrl: './popup-notification.component.html',
  styleUrls: ['./popup-notification.component.scss'],
})
export class PopupNotificationComponent implements OnInit {
  selectRoleMsg: number;
  constructor(
    private dialogRef: MatDialog,
    private router: Router,
    private store: StoreService,
    private eventEmitterService: EventEmitterService,
    private eventEmitter: EventEmitterService,
    @Inject(MAT_DIALOG_DATA) public data: any,
    public usrRole: UsersRoleService
  ) {}

  ngOnInit(): void {
    this.usrRole.getUserRoles();
    let roleFrom = this.usrRole.roleMappingFinalArray[this.data.chkFlag];
    let roleTo = this.usrRole.roleMappingFinalArray[this.data.chkFlagTo];

    if (this.data.chkType == 'RoleCghange') {
      if (roleFrom == 'Member' && roleTo == 'Enterprise Admin') {
        this.selectRoleMsg = 1;
      } else if (roleFrom == 'Member' && roleTo == 'Team Admin') {
        this.selectRoleMsg = 2;
      } else if (roleFrom == 'Team Admin' && roleTo == 'Enterprise Admin') {
        this.selectRoleMsg = 3;
      } else if (roleFrom == 'Enterprise Admin' && roleTo == 'Team Admin') {
        this.selectRoleMsg = 4;
      } else if (roleFrom == 'Enterprise Admin' && roleTo == 'Member') {
        this.selectRoleMsg = 5;
      } else if (roleFrom == 'Team Admin' && roleTo == 'Member') {
        this.selectRoleMsg = 6;
      } else if (roleFrom == 'Member' && roleTo == 'Region Admin') {
        this.selectRoleMsg = 7;
      } else if (roleFrom == 'Team Admin' && roleTo == 'Region Admin') {
        this.selectRoleMsg = 8;
      } else if (roleFrom == 'Enterprise Admin' && roleTo == 'Region Admin') {
        this.selectRoleMsg = 9;
      } else if (roleFrom == 'Region Admin' && roleTo == 'Enterprise Admin') {
        this.selectRoleMsg = 10;
      } else if (roleFrom == 'Region Admin' && roleTo == 'Team Admin') {
        this.selectRoleMsg = 11;
      } else if (roleFrom == 'Region Admin' && roleTo == 'Member') {
        this.selectRoleMsg = 12;
      } else if (roleTo == 'Guest') {
        this.selectRoleMsg = 13;
      } else if (roleFrom == 'Guest') {
        this.selectRoleMsg = 14;
      }
    }
  }
  reload() {
    this.router.navigate(['/dashboard/']).then(() => {
      window.location.reload();
    });
  }
}
