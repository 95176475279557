<app-sidebar></app-sidebar>
<app-header></app-header>

<div class="inner-sec_">
    <section class="p-3 bulk-upload">
        <div class="d-flex justify-content-between ">
           
           <div class="col-6"><h4>Bulk Upload</h4>

            <p>To  bulk upload please download the template and populate it with your information and upload here.</p>
        
          </div>

         <div><button type="button" class="btn downloadupload">
             <img class="me-1" width="14px" [nexaeiImageSrc]="'down.png'" /> Download Template</button></div> 

        </div>

       <!-- <div class="d-flex justify-content-center pt-5"> 

        <div class="upload-btn-wrapper">
            <button class="btn-upload"><div><img class="pb-2" width="25px" [nexaeiImageSrc]="'upload.png'" /></div> Choose File to Upload</button>
            <input type="file" name="myfile" />
          </div>

       </div> -->


  <div class="d-flex justify-content-center pt-5"> 

        <div class="upload-btn-wrapper">
            <button class="btn-upload"><div><img class="pb-2" width="25px" [nexaeiImageSrc]="'upload.png'" /></div> Choose File to Upload</button>
            <input type="file" name="myfile" />
          </div>

       </div>



        <div class="d-flex justify-content-between pt-4">
           
            <div class="col-6">
             <p class="text-muted pt-3 fw-normal">Total - 
                <span class="text-white">525</span> |  Uploaded -  <span class="text-success">521</span></p>
           </div>
 
          <div><button type="button" class="btn finish-upload"> Finish Upload </button></div> 
 
         </div>


        <div class="tablesection table-empty col-lg-12 text-center pt-4">
            <table class="table">
                <thead>
                    <tr>
                        <th class="text-start ps-3">Email ID</th>
                        <th class="text-start ps-3">Status</th>
                       
                    </tr>
                </thead>

                <tbody>
                    <tr>
                        <th class="text-start ps-3">amit.kumar@website.com</th>
                        <th class="text-start ps-3">Ok</th>
                       
                    </tr>
                    <tr>
                        <th class="text-start ps-3">psingh@123.com</th>
                        <th class="text-start ps-3 text-danger">Invalid Email</th>
                       
                    </tr>
                    <tr>
                        <th class="text-start ps-3">amit.kumar@website.com</th>
                        <th class="text-start ps-3">Status</th>
                       
                    </tr>

                    <tr>
                        <th class="text-start ps-3">psingh@website.com</th>
                        <th class="text-start ps-3 text-danger">Duplicate</th>
                       
                    </tr>
                    <tr>
                        <th class="text-start ps-3">psingh@website.com</th>
                        <th class="text-start ps-3 text-danger">Duplicate</th>
                       
                    </tr>
               
               
                    
                </tbody>
            </table>
            <!--empty data-->
        
        </div>
        
    </section>
</div>