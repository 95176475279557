import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Validators, FormGroup, FormControl } from '@angular/forms';
import { AuthHttpService } from 'src/app/service/auth-http.service';
import { ValidateService } from 'src/app/service/validate.service';
import { environment } from 'src/environments/environment';
import { CryptoService } from 'src/app/service/crypto.service';
import { UserDataService } from 'src/app/service/user-data.service';
import { resetPass } from 'src/app/interfaces/user.interface';
import { JwtService } from 'src/app/service/jwt.service';

@Component({
  selector: 'app-changepass',
  templateUrl: './changepass.component.html',
  styleUrls: ['./changepass.component.scss'],
})
export class ChangepassComponent implements OnInit {
  changePass: FormGroup;
  serverErrorMessage: string = '';
  hasError: boolean;
  returnUrl: string = 'enterotp';

  constructor(
    private http: AuthHttpService,
    private customValidator: ValidateService,
    private router: Router,
    private encryption: CryptoService,
    private UserDataService: UserDataService,
    private jwt: JwtService
  ) {}

  ngOnInit(): void {
    if (localStorage.getItem('forgot-pass-email') == null) {
      this.router.navigate(['login']);
    }
    this.changePass = new FormGroup(
      {
        password: new FormControl(null, [
          Validators.required,
          Validators.maxLength(28),
          this.customValidator.passwordValidator(),
        ]),
        confirmPassword: new FormControl(null, [Validators.required]),
      },
      this.customValidator.mustMatch('password', 'confirmPassword')
    );
  }

  get changePassControl() {
    return this.changePass.controls;
  }

  onSubmit = async () => {
    if (this.changePass.invalid) {
      this.changePassControl.password.markAsDirty();
      this.changePassControl.confirmPassword.markAsDirty();
      return;
    }
    let mail = localStorage.getItem('forgot-pass-email');

    let data: resetPass = {
      email: mail,
      pwd: this.encryption.encrypt(this.changePass.value.password),
    };

    this.UserDataService.resetPass(data).subscribe(
      async (response) => {
        if (response.success) {
          //  store data to local storage to process request in form two with same data
          for await (let token of response.data) {
            await this.tokenExpiry(token.token);
          }
          this.router.navigate(['logout']);
        } else {
          this.serverErrorMessage = response.data;
          this.hasError = true;
        }
      },
      (_) => {
        this.serverErrorMessage = environment.errorMessage;
      }
    );
  };

  async tokenExpiry(token: string) {
    try {
      this.jwt.setToken(token);
      this.http.logoutTokenExpiry(null).subscribe({
        next: (response) => {
          if (response.code == 200) {
          }
        },
        error: (err) => {},
      });
    } catch (e) {}
  }
}
