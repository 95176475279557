<main class="main_container bg-color-dark" style="height:100vh; overflow-y:scroll;">
  <header class="head_before_login col-md-12">
    <div class="row">
      <div class="col-md-6 logo_before_login">
        <a href="#"> <img [nexaeiImageSrc]="'logo.png'" /></a>
      </div>

    </div>
  </header>
  <div class="container-fluid">
    <div class="row">
      <div class="org_setup text-center pt-5">
        <img [nexaeiImageSrc]="'org_setup_img.png'" />
        <h6 class="mt-4 mb-3">Welcome</h6>
        <h3 class="mb-3 pb-2">{{ fullName }}</h3>
        <h4 class="pb-3">Do you want to setup your organization now?</h4>
        <button
          type="submit"
          class="btn primary-xl"
          [routerLink]="['/signup/orgsetup-2']"
        >
          Yes, let's do it
        </button>
        <span class="bottom-text pb-4">
          <label class="login_cndtn redirec_sigin mt-4"
            ><h6>
              <a data-bs-toggle="modal" data-bs-target="#skip" href="#"
                >No, I will do this later</a
              >
            </h6></label
          >
        </span>

      

            <!-- Modal -->
            <div class="emv-invittion">
            <div class="modal fade" id="exampleModal" tabindex="-1" aria-labelledby="exampleModalLabel" data-bs-backdrop="static" 
            aria-hidden="true">

         

            <div class="modal-dialog modal-lg modal-dialog-centered">
                <div class="modal-content pt-2 pb-2">
                    <div class="modal-header border-0 d-block">
                       <div><h5 class="modal-title text-center w-100" id="exampleModalLabel">
                        Invitation Code
                      </h5>
                    
                      <div class="d-flex justify-content-center">
                        <small class="text-white text-center">Enter valid invitation code to continue</small> </div>

                    </div> 



                    </div>
                    <div class="modal-body border-0 d-flex justify-content-center">
                        <div class="col-md-7 position-relative">
                            <div class="bg-dark-sec col-md-12 f-control p-3 d-flex error_input">
                                <div>
                                    <img class="me-2 pt-1" width="15px"
                                        [nexaeiImageSrc]="'invite.png'" />
                                </div>
                                <input type="text" id="" aria-describedby=""
                                    placeholder="Enter valid invitation code"
                                    class="form-control bg-dark-sec border-0 text-white " />

                  
                                    
                            </div>

                            <p _ngcontent-puh-c49="" class="error_cntnt-org"> Email required </p>

                           
                        
                          
                            <div class="d-flex justify-content-center mb-4">
                                <button type="submit" class="btn copy_btn mt-4 w-50 me-2">
                                  Cancle
                                </button>

                                <button type="submit" class="btn primary-xl mt-4 w-50">Continue</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div></div>
        
      </div>
    </div>
  </div>

  <!--Created skip model-->

  <!-- Modal -->
  <div
    class="modal fade"
    id="skip"
    data-bs-backdrop="static"
    data-bs-keyboard="false"
    tabindex="-1"
    aria-labelledby="staticBackdropLabel"
    aria-hidden="true"
  >
    <div class="modal-dialog modal-dialog-centered modal-lg">
      <div class="modal-content org_setup_skip_model">
        <div class="modal-header border-0">
        </div>
        <div class="modal-body pt-0">
          <div class="org_setup text-center ">
            <img [nexaeiImageSrc]="'logo.png'" />
            <h3 class="mt-4 pt-3">Thank You !</h3>
            <h6 class="mt-3 mb-4">
              No problem <br />
              Come Back Soon
            </h6>
            <h4 class="pb-4">
              You can login anytime and start completing your organization
              setup.
            </h4>
            <button type="submit" class="btn primary-xl mb-3" [routerLink]="['/dashboard/logout']">Sure</button>
          </div>
        </div>
      </div>
    </div>
  </div>
</main>
<app-invitation-code-popup></app-invitation-code-popup>
