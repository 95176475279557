<div class="modal-dialog modal-dialog-centered modal-lg px-5">
  <div class="modal-content px-5 py-3">
    <button
      type="button"
      data-bs-dismiss="modal"
      aria-label="Close"
      class="btn-close-model"
    >
      ✖
    </button>
    <div class="modal-body text-center px-5 py-5">
      <h6>Get OTP on mobile</h6>
      <p class="fs-12">Please provide your mobile number to get the OTP</p>
      <form [formGroup]="getOtpForm" (ngSubmit)="onSubmit()">
        <div class="d-flex justify-content-center">
          <div
            class="col-10 bg-black mid-otp d-flex justify-content-start px-3 py-3 mt-5"
          >
            <div class="pt-1">
              <img [nexaeiImageSrc]="'mobileicon.png'" />
            </div>

            <input
              class="form-control ms-1 bg-black border-0"
              id="disabledInput"
              type="text"
              placeholder=" Mobile Number"
              [formControlName]="'mobileNo'"
              maxlength="13"
              minlength="10"
              (keyup)="myFunc($event)"
            />
          </div>
        </div>

        <div class="pt-4">
          <!-- <p
          class="error_text  text-center"
          *ngIf="
            otpFormControl.mobileNo?.dirty &&
            otpFormControl.mobileNo?.errors?.required
          "
        >
          Mobile no. required.
        </p> -->
          <p
            class="error_text text-center"
            *ngIf="
              otpFormControl.mobileNo?.dirty &&
              otpFormControl.mobileNo?.errors?.invalidMobileNumber &&
              !otpFormControl.mobileNo?.errors?.required &&
              !otpFormControl.mobileNo?.errors?.maxlength
            "
          >
            Invalid mobile no
          </p>

          <p
            class="error_text text-center"
            *ngIf="
              otpFormControl.mobileNo?.dirty &&
              otpFormControl.mobileNo?.errors?.maxlength &&
              !otpFormControl.mobileNo?.errors?.required &&
              !otpFormControl.mobileNo?.errors?.invalidMobileNumber
            "
          >
            Invalid mobile no
          </p>
          <p class="text-center" *ngIf="serverErrorMessage">
            {{ serverErrorMessage }}
          </p>
        </div>

        <div class="col-md-12 ps-5 pe-5 pt-4">
          <button
            type="submit"
            class="btn primary-xl mb-2"
            [disabled]="!btnActive"
          >
            Send OTP
          </button>
        </div>
      </form>
    </div>
  </div>
</div>
