<app-sidebar></app-sidebar>
<app-header></app-header>

<div class="inner-sec_ teampage">
    <section>
        <div class="d-flex justify-content-between pt-3">
            <h4 class="text-white fw-light">Teams</h4>

            <!-- <div class="d-flex justify-content-end">
                <div class="header_search d-flex bg-color-dark2 pt-2 ps-3">
                    <div class="pt-1"><img class="me-1" [nexaeiImageSrc]="'files/search.png'" /></div>
                    <form>
                        <input type="text" class="form-control" id="" placeholder="search" />
                    </form>
                </div>

                <a class="icon_sec" href="#">
                    <div><img [nexaeiImageSrc]="'files/automate.png'" /></div>
                </a>
                <a class="icon_sec" href="#">
                    <div><img [nexaeiImageSrc]="'files/Integrate.png'" /></div>
                </a>
                <a class="icon_sec" href="#">
                    <div><img [nexaeiImageSrc]="'files/icon1.png'" /></div>
                </a>
            </div> -->
        </div>

        <div class="d-flex justify-content-end pt-4 pe-3">
            <div class="d-flex">
                <div><img class="me-2" [nexaeiImageSrc]="'files/arrow.png'" /></div>
                <p>Export Data</p>
            </div>
        </div>

        <div class="row ">
            <div class="col-md-6 col-lg-4 col-xl-3">
                <div class="bg-gray p-4 team_defolt text-center">
                    <img class="me-2 pb-4" [nexaeiImageSrc]="'files/team.png'" />

                    <div class="clearfix"></div>
                    <a href="" class=" pb-0 mb-0 text-white">+ Add Team</a>
                </div>
            </div>

            <div class="col-md-6 col-lg-4 col-xl-3">
                <div class="bg-green p-2 team_defolt">
                    <div class="d-flex justify-content-end">
                        <a href="#"> <img class="me-3" [nexaeiImageSrc]="'files/icon2.png'" /> </a>
                        <a href="#"> <img width="12px" [nexaeiImageSrc]="'files/dot.png'" /> </a>
                    </div>

                    <h5 class="fw-light">Human Resources Payroll Team</h5>

                    <small class="text-white-50">9 Aug, 2021</small>

                    <div class="pt-5 d-flex">
                        <div>
                            <img class="me-1 c-img2" [nexaeiImageSrc]="'Ellipse16.png'" />
                        </div>
                        <div>
                            <img class="me-1 c-img2" [nexaeiImageSrc]="'Ellipse110.png'" />
                        </div>
                        <div><img class="me-1 c-img2" [nexaeiImageSrc]="'Ellipse16.png'" /></div>

                        <div class="c-team">+7</div>
                    </div>
                </div>
            </div>

            <div class="col-md-6 col-lg-4 col-xl-3">
                <div class="bg-light-red p-2 team_defolt">
                    <div class="d-flex justify-content-end">
                        <a href="#"> <img class="me-3" [nexaeiImageSrc]="'files/icon2.png'" /> </a>
                        <a href="#"> <img width="12px" [nexaeiImageSrc]="'files/dot.png'" /> </a>
                    </div>

                    <h5 class="fw-light">Human Resources Payroll Team</h5>

                    <small class="text-white-50">9 Aug, 2021</small>

                    <div class="pt-5 d-flex">
                        <div>
                            <img class="me-1 c-img2" [nexaeiImageSrc]="'Ellipse16.png'" />
                        </div>
                        <div>
                            <img class="me-1 c-img2" [nexaeiImageSrc]="'Ellipse110.png'" />
                        </div>
                        <div><img class="me-1 c-img2" [nexaeiImageSrc]="'Ellipse16.png'" /></div>

                        <div class="c-team">+7</div>
                    </div>
                </div>
            </div>

            <div class="col-md-6 col-lg-4 col-xl-3">
                <div class="bg-yellow p-2 team_defolt">
                    <div class="d-flex justify-content-end">
                        <a href="#"> <img class="me-3" [nexaeiImageSrc]="'files/icon2.png'" /> </a>
                        <a href="#"> <img width="12px" [nexaeiImageSrc]="'files/dot.png'" /> </a>
                    </div>

                    <h5 class="fw-light">Human Resources Payroll Team</h5>

                    <small class="text-white-50">9 Aug, 2021</small>

                    <div class="pt-5 d-flex">
                        <div>
                            <img class="me-1 c-img2" [nexaeiImageSrc]="'Ellipse16.png'" />
                        </div>
                        <div>
                            <img class="me-1 c-img2" [nexaeiImageSrc]="'Ellipse110.png'" />
                        </div>
                        <div><img class="me-1 c-img2" [nexaeiImageSrc]="'Ellipse16.png'" /></div>

                        <div class="c-team">+7</div>
                    </div>
                </div>
            </div>

            <div class="col-md-6 col-lg-4 col-xl-3">
                <div class="bg-blue p-2 team_defolt">
                    <div class="d-flex justify-content-end">
                        <a href="#"> <img class="me-3" [nexaeiImageSrc]="'files/icon2.png'" /> </a>
                        <a href="#"> <img width="12px" [nexaeiImageSrc]="'files/dot.png'" /> </a>
                    </div>

                    <h5 class="fw-light">Human Resources Payroll Team</h5>

                    <small class="text-white-50">9 Aug, 2021</small>

                    <div class="pt-5 d-flex">
                        <div>
                            <img class="me-1 c-img2" [nexaeiImageSrc]="'Ellipse16.png'" />
                        </div>
                        <div>
                            <img class="me-1 c-img2" [nexaeiImageSrc]="'Ellipse110.png'" />
                        </div>
                        <div><img class="me-1 c-img2" [nexaeiImageSrc]="'Ellipse16.png'" /></div>

                        <div class="c-team">+7</div>
                    </div>
                </div>
            </div>

            <div class="col-md-6 col-lg-4 col-xl-3">
                <div class="bg-orange p-2 team_defolt">
                    <div class="d-flex justify-content-end">
                        <a href="#"> <img class="me-3" [nexaeiImageSrc]="'files/icon2.png'" /> </a>
                        <a href="#"> <img width="12px" [nexaeiImageSrc]="'files/dot.png'" /> </a>
                    </div>

                    <h5 class="fw-light">Human Resources Payroll Team</h5>

                    <small class="text-white-50">9 Aug, 2021</small>

                    <div class="pt-5 d-flex">
                        <div>
                            <img class="me-1 c-img2" [nexaeiImageSrc]="'Ellipse16.png'" />
                        </div>
                        <div>
                            <img class="me-1 c-img2" [nexaeiImageSrc]="'Ellipse110.png'" />
                        </div>
                        <div><img class="me-1 c-img2" [nexaeiImageSrc]="'Ellipse16.png'" /></div>

                        <div class="c-team">+7</div>
                    </div>
                </div>
            </div>

            <div class="col-md-6 col-lg-4 col-xl-3">
                <div class="deep-blue p-2 team_defolt">
                    <div class="d-flex justify-content-end">
                        <a href="#"> <img class="me-3" [nexaeiImageSrc]="'files/icon2.png'" /> </a>
                        <a href="#"> <img width="12px" [nexaeiImageSrc]="'files/dot.png'" /> </a>
                    </div>

                    <h5 class="fw-light">Human Resources Payroll Team</h5>

                    <small class="text-white-50">9 Aug, 2021</small>

                    <div class="pt-5 d-flex">
                        <div>
                            <img class="me-1 c-img2" [nexaeiImageSrc]="'Ellipse16.png'" />
                        </div>
                        <div>
                            <img class="me-1 c-img2" [nexaeiImageSrc]="'Ellipse110.png'" />
                        </div>
                        <div><img class="me-1 c-img2" [nexaeiImageSrc]="'Ellipse16.png'" /></div>

                        <div class="c-team">+7</div>
                    </div>
                </div>
            </div>

            <div class="col-md-6 col-lg-4 col-xl-3">
                <div class="deep-violet p-2 team_defolt">
                    <div class="d-flex justify-content-end">
                        <a href="#"> <img class="me-3" [nexaeiImageSrc]="'files/icon2.png'" /> </a>
                        <a href="#"> <img width="12px" [nexaeiImageSrc]="'files/dot.png'" /> </a>
                    </div>

                    <h5 class="fw-light">Human Resources Payroll Team</h5>

                    <small class="text-white-50">9 Aug, 2021</small>

                    <div class="pt-5 d-flex">
                        <div>
                            <img class="me-1 c-img2" [nexaeiImageSrc]="'Ellipse16.png'" />
                        </div>
                        <div>
                            <img class="me-1 c-img2" [nexaeiImageSrc]="'Ellipse110.png'" />
                        </div>
                        <div><img class="me-1 c-img2" [nexaeiImageSrc]="'Ellipse16.png'" /></div>

                        <div class="c-team">+7</div>
                    </div>
                </div>
            </div>

            <div class="col-md-6 col-lg-4 col-xl-3">
                <div class="color-1 p-2 team_defolt">
                    <div class="d-flex justify-content-end">
                        <a href="#"> <img class="me-3" [nexaeiImageSrc]="'files/icon2.png'" /> </a>
                        <a href="#"> <img width="12px" [nexaeiImageSrc]="'files/dot.png'" /> </a>
                    </div>

                    <h5 class="fw-light">Human Resources Payroll Team</h5>

                    <small class="text-white-50">9 Aug, 2021</small>

                    <div class="pt-5 d-flex">
                        <div>
                            <img class="me-1 c-img2" [nexaeiImageSrc]="'Ellipse16.png'" />
                        </div>
                        <div>
                            <img class="me-1 c-img2" [nexaeiImageSrc]="'Ellipse110.png'" />
                        </div>
                        <div><img class="me-1 c-img2" [nexaeiImageSrc]="'Ellipse16.png'" /></div>

                        <div class="c-team">+7</div>
                    </div>
                </div>
            </div>

            <div class="col-md-6 col-lg-4 col-xl-3">
                <div class="color-2 p-2 team_defolt">
                    <div class="d-flex justify-content-end">
                        <a href="#"> <img class="me-3" [nexaeiImageSrc]="'files/icon2.png'" /> </a>
                        <a href="#"> <img width="12px" [nexaeiImageSrc]="'files/dot.png'" /> </a>
                    </div>

                    <h5 class="fw-light">Human Resources Payroll Team</h5>

                    <small class="text-white-50">9 Aug, 2021</small>

                    <div class="pt-5 d-flex">
                        <div>
                            <img class="me-1 c-img2" [nexaeiImageSrc]="'Ellipse16.png'" />
                        </div>
                        <div>
                            <img class="me-1 c-img2" [nexaeiImageSrc]="'Ellipse110.png'" />
                        </div>
                        <div><img class="me-1 c-img2" [nexaeiImageSrc]="'Ellipse16.png'" /></div>

                        <div class="c-team">+7</div>
                    </div>
                </div>
            </div>
        </div>
    </section>
</div>
