<app-sidebar></app-sidebar>
<app-header></app-header>

<div class="mid-sec emv-invittion" style="height:100vh ; overflow-y: scroll; overflow-x:hidden">
    <section class="mix-sec">
        <div class="row">
            <div id="navbar1">
                <div class="col-md-12 pt-3 ps-4 pe-4">
                    <div class="row justify-content-between pt-1">
                        <div class="col-lg-8 col-6 d-flex ps-lg-4">
                            <div class="me-3">
                                <div class="profilepic">D</div>
                            </div>
                            <h5 class="text-white fw-normal pt-2">Design</h5>
                        </div>

                        <div class="col-md-4 col-6 d-flex justify-content-end pt-1">
                            <div>
                                <div class="header_search d-flex ps-2 pr-2">
                                    <div class="pt-1 mt-1"><img width="17px"
                                            [nexaeiImageSrc]="'emp-invite/search.png'" /></div>
                                    <form>
                                        <input type="text" class="form-control border-0" name="search" id="search"
                                            placeholder="Search tags" />
                                    </form>
                                </div>
                            </div>
                            <div class="dropdown">
                                <a href="#" class="me-3" id="dropdownMenuButton1" data-bs-toggle="dropdown"
                                    aria-expanded="false"><img class="pt-1" width="15px"
                                        [nexaeiImageSrc]="'dot.png'" /></a>
                                <ul class="dropdown-menu" aria-labelledby="dropdownMenuButton1">
                                    <li>
                                        <a class="dropdown-item" href="#" data-bs-toggle="modal"
                                            data-bs-target="#exampleModal"><img class="me-2" width="15px"
                                                [nexaeiImageSrc]="'emp-invite/plus.png'" /> Add members</a>
                                    </li>
                                    <li>
                                        <a class="dropdown-item" href="#"><img class="me-2" width="20px"
                                                [nexaeiImageSrc]="'emp-invite/massage.png'" /> Arrival Message</a>
                                    </li>
                                    <li>
                                        <a class="dropdown-item" href="#"><img class="me-2" width="20px"
                                                [nexaeiImageSrc]="'emp-invite/away.png'" /> Away Message</a>
                                    </li>
                                    <li>
                                        <a class="dropdown-item" href="#"><img class="me-2" width="20px"
                                                [nexaeiImageSrc]="'emp-invite/settings.png'" /> Colab Settings</a>
                                    </li>
                                </ul>
                            </div>
                            <!-- Modal -->
                            <div class="modal fade" id="exampleModal" tabindex="-1" aria-labelledby="exampleModalLabel"
                                aria-hidden="true">
                                <div class="modal-dialog modal-lg modal-dialog-centered">
                                    <div class="modal-content pt-3 pb-3 border border-secondary">
                                        <div class="modal-header border-0 d-flex justify-content-between">


                                            <a class="col-2 me-4 me-lg-5 reset" href=""><img width="20spx"
                                                [nexaeiImageSrc]="'reset.png'" /></a>

                                           <div>  <h5 class="text-center" >Arrival Message
                                            </h5>
                                         <p class="text-center fs-12">Set up a message to auto send it in your team colabs once you check in</p>

                                        </div>
                                            
                                            <button type="button" class="btn-close btn-close-m" data-bs-dismiss="modal"
                                                aria-label="Close"></button>
                                        </div>

                                        
                                        <div class="modal-body border-0 d-flex justify-content-center pt-4">
                                            <div class="col-lg-7 col-sm-9 position-relative">
                                                <div class="bg-dark-sec col-md-12 f-control p-3 mb-2">
                                                   
                                                    <input type="text" id="" aria-describedby=""
                                                        placeholder="Add message"
                                                        class="form-control bg-dark-sec border-0 text-white" />
                                                </div>

                                                <button type="submit" class="btn primary-xl  mt-5">Save</button>
                                           
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="col-md-12 col-xl-6 pt-5 mt-3 mt-lg-1 d-flex flow_sec justify-content-center">


                    <div class="ps-3 pe-3 d-flex sec_first b-r">
                        <p class="pt-2 mt-n1">Availability</p>
                        <h5 class="text-green ps-2 pt-2 mt-n1 h6">10:15 am</h5>
                        <div>

                        </div>
                    </div>

                    <div class=" ps-3 pe-3 d-flex  sec_first">
                        <p class="pt-2 mt-n1">Availablity avg.</p>
                        <h5 class="text-green ps-2 pt-2 mt-n1 h6">8:30 hr</h5>



                    </div>




                </div>

                <hr />
            </div>

            <div class="col-md-12 ps-3">
                <div class="pb-6 col-12">
                    <!-- <div class="col-md-12 ps-4 ps-lg-4 pt-2 ">
                        <div class="d-flex">
                            <div class="position-relative">
                                <div class="online-status online"></div>
                                <img class="me-3 c-img" [nexaeiImageSrc]="'Ellipse16.png'" />
                            </div>
                            <div>
                                <div class="d-flex">
                                    <p class="pb-0 mb-0">Amit Kumar</p>
                                    <div><img class="me-2 ms-2" width="5px" [nexaeiImageSrc]="'collab/dot2.png'" /></div>
                                    <small class="text-white pt-1">10:20 AM</small>
                                </div>
                                <small class="text-white">AVP Product</small>
                            </div>
                        </div>
                        <div class="col-md-10 pt-2">
                            <small class="text-white">Member Check in lication will be viewed along with other information mentioned when we click on member and opens colab with that member</small>
                        </div>
                        <img [nexaeiImageSrc]="'collab/tick.png'" />
                        <div class="d-flex pt-3">
                            <div class="d-flex">
                                <div><img [nexaeiImageSrc]="'collab/hand.png'" /></div>
                                <p class="ps-2 pt-2">3</p>
                            </div>
                            <div class="d-flex ps-4 ps-lg-4">
                                <div><img [nexaeiImageSrc]="'collab/clap.png'" /></div>
                                <p class="ps-2 pt-2">2</p>
                            </div>
                        </div>
                    </div>

                    <div class="col-md-12 ps-4 ps-lg-4 pt-2 pb-4">
                        <div class="d-flex">
                            <div class="position-relative">
                                <div class="online-status online"></div>
                                <img class="me-3 c-img" [nexaeiImageSrc]="'Ellipse16.png'" />
                            </div>
                            <div>
                                <div class="d-flex">
                                    <p class="pb-0 mb-0">Amit Kumar</p>
                                    <div><img class="me-2 ms-2" width="5px" [nexaeiImageSrc]="'collab/dot2.png'" /></div>
                                    <small class="text-white pt-1">10:20 AM</small>
                                </div>
                                <small class="text-white">AVP Product</small>
                            </div>
                        </div>
                        <div class="col-md-12 clearfix">
                            <img class="pt-3 img-class" [nexaeiImageSrc]="'collab/Rectangle319.png'" />
                        </div>
                    </div>
                    <div class="col-md-12 ps-4 ps-lg-4 pt-2 pb-4">
                        <div class="d-flex">
                            <div class="position-relative">
                                <div class="online-status online"></div>
                                <img class="me-3 c-img" [nexaeiImageSrc]="'Ellipse16.png'" />
                            </div>
                            <div>
                                <div class="d-flex">
                                    <p class="pb-0 mb-0">Amit Kumar</p>
                                    <div><img class="me-2 ms-2" width="5px" [nexaeiImageSrc]="'collab/dot2.png'" /></div>
                                    <small class="text-white pt-1">10:20 AM</small>
                                </div>
                                <small class="text-white">AVP Product</small>
                            </div>
                        </div>
                        <div class="col-md-10 pt-2">
                            <small class="text-white">
                                Member Check in lication will be viewed along with
                                <div class="tooltip_hover">
                                    other
                                    <span class="tooltiptext">
                                        <img class="me-2 ms-3" width="20px" [nexaeiImageSrc]="'collab/thumb.png'" />
                                        <img class="me-2 ms-3" width="17px" [nexaeiImageSrc]="'collab/share.png'" />
                                        <img class="me-2 ms-3" width="20px" [nexaeiImageSrc]="'collab/left.png'" />
                                        <img class="me-2 ms-3" width="20px" [nexaeiImageSrc]="'collab/arrow.png'" />
                                        <img class="me-2 ms-3" width="20px" [nexaeiImageSrc]="'collab/arrow2.png'" />
                                    </span>
                                </div>
                                .
                            </small>
                        </div>

                        <img [nexaeiImageSrc]="'collab/tick.png'" />
                    </div>
                    <div class="col-md-12 ps-4 ps-lg-4 pt-2 pb-4">
                        <div class="d-flex">
                            <div class="position-relative">
                                <div class="online-status online"></div>
                                <img class="me-3 c-img" [nexaeiImageSrc]="'Ellipse16.png'" />
                            </div>
                            <div>
                                <div class="d-flex">
                                    <p class="pb-0 mb-0">Amit Kumar</p>
                                    <div><img class="me-2 ms-2" width="5px" [nexaeiImageSrc]="'collab/dot2.png'" /></div>
                                    <small class="text-white pt-1">10:20 AM</small>
                                </div>
                                <small class="text-white">AVP Product</small>
                            </div>
                        </div>

                        <div class="col-md-10 pt-2">
                            <small class="text-white">Member Check in lication will be viewed along with other.</small>
                        </div>
                        <img [nexaeiImageSrc]="'collab/tick.png'" />
                    </div>

                    <div class="col-md-12 ps-4 ps-lg-4 pt-2">
                        <div class="d-flex">
                            <div class="position-relative">
                                <div class="online-status online"></div>
                                <div class="profilepic2 me-3">D</div>
                            </div>
                            <div>
                                <div class="d-flex">
                                    <p class="pb-0 mb-0">You</p>
                                    <div><img class="me-2 ms-2" width="5px" [nexaeiImageSrc]="'collab/dot2.png'" /></div>
                                    <small class="text-white pt-1">10:20 AM</small>
                                </div>
                                <small class="text-white">Sub-Admin</small>
                            </div>
                        </div>
                    </div>

                    <div class="col-md-8 col-lg-5 pt-2 mt-4 ps-4">
                        <div class="bg-light-gray border border-white p-3 text-white d-flex justify-content-between">
                            <div>
                                <small>12 Apr, 1:20 AM</small>
                                <h4 class="pt-2 pb-2 fw-light">Early Morning Shift</h4>

                                <div class="d-flex">
                                    <div><img class="me-2" [nexaeiImageSrc]="'collab/watch_icon.png'" /></div>
                                    <p>Start from: 11:30 AM</p>
                                </div>

                                <div class="d-flex mt-n1">
                                    <div><img class="me-2" [nexaeiImageSrc]="'collab/watch_icon.png'" /></div>
                                    <p>End at: 7:30 PM</p>
                                </div>

                                <div class="d-flex mt-n1">
                                    <div><img class="me-2" width="20px" [nexaeiImageSrc]="'collab/icon1.png'" /></div>
                                    <p>Weekoff: Sat (w1, w4), Sun</p>
                                </div>
                            </div>

                            <a href="#"><img width="7px" [nexaeiImageSrc]="'collab/dot.png'" /></a>
                        </div>
                    </div> -->

                    <div class="col-md-12 ps-4 ps-lg-4 pt-2 pb-1">
                        <div class="image-collab_sec col-4 bg-gray row">
                            <div class=" col-6 p-0"> 
                                <img class="col-6 p-0" src="https://pbs.twimg.com/media/FmSSw-maAAAaZ0g.jpg" /> </div>
                            <div class=" col-6 p-0"> 
                                <img class="col-6 p-0" src="https://pbs.twimg.com/media/FjU2lkcWYAgNG6d.jpg" /> </div>
                            <div class="col-6 p-0">
                                <img class="col-6 p-0" src="https://pbs.twimg.com/media/FmSSw-maAAAaZ0g.jpg" /> </div>
                            <div class="col-6 p-0">
                                <img class="col-6 p-0" src="https://pbs.twimg.com/media/FjU2lkcWYAgNG6d.jpg" />
                            </div>
                        </div>
                    </div>


                    

                    <div class="col-md-12 ps-4 ps-lg-4 pt-2 pb-1">
                        <div class="image-collab_sec col-4 bg-gray row">
                            <div class=" col-6 p-0"> <img class="col-6 p-0" src="https://pbs.twimg.com/media/FjU2lkcWYAgNG6d.jpg" /> </div>
                            <div class=" col-6 p-0">  <img class="col-6 p-0" src="https://pbs.twimg.com/media/FmSSw-maAAAaZ0g.jpg" /> </div>
                            <div class="col-6 p-0"> <img class="col-6 p-0" src="https://pbs.twimg.com/media/FmZf1SWagAAiGNg.jpg"> </div>
                            <div class="last-child col-6 p-0"> <img src="https://pbs.twimg.com/media/Fl0y_iZXEAAlDqO.jpg"> 
                            
                                <p class="fw-light">+ 3</p>
                            
                            </div>
                        </div>
                    </div>


                    <div class="col-md-12 ps-4 ps-lg-4 pt-3 float-start">


                        <div class="p-2 hover-effcct pb-0 pt-0">
                            <div class="tooltip_hover_new">
                              
                              <div class="tooltiptext">
                                <img
                                  class="me-2 ms-3"
                                  width="20px"
                                  [nexaeiImageSrc]="'collab/thumb.png'"
                                />
                                <img
                                  class="me-2 ms-3"
                                  width="17px"
                                  [nexaeiImageSrc]="'collab/share.png'"
                                />
                                <img
                                  class="me-2 ms-3"
                                  width="20px"
                                  [nexaeiImageSrc]="'collab/left.png'"
                                />
                                <img data-bs-toggle="modal" data-bs-target="#exampleModal-new"
                                  class="me-2 ms-3"
                                  width="20px"
                                  [nexaeiImageSrc]="'collab/arrow.png'"
                                />
                                
                                <img
                                  class="me-2 ms-3"
                                  width="15px"
                                  [nexaeiImageSrc]="'collab/edit.png'"
                                />
                
                                <img
                                class="me-2 ms-3"
                                width="15px"
                                [nexaeiImageSrc]="'collab/delet.png'"
                              />
                              </div>
                
                        
                            </div>
                      
                        
                          <div class="col-md-12">
                            <div class="col-11 d-flex justify-content-between">
                              <small class="text-white"
                                >Sure please tag a personal day.</small
                              >
                
                              <small class="text-white pt-2">10:20 AM</small>
                
                            </div>
                            
                          </div>
                          <img class="mt-n1" [nexaeiImageSrc]="'collab/tick.png'" />
                    
                        </div> 


                        
                                <!-- Modal -->
                                <div class="modal fade" id="exampleModal-new" tabindex="-1" aria-labelledby="exampleModalLabel"
                                aria-hidden="true">
                                <div class="modal-dialog modal-lg-new modal-dialog-centered">
                                    <div class="modal-content pt-2   border-light-new">
                                        <div class="modal-header border-0 pb-1">
                                            <button type="button" class="btn-close" data-bs-dismiss="modal"
                                            aria-label="Close"></button>


                                            <h5 class="modal-title text-center w-100 h6" id="exampleModal-new">Forward Message 
                                            </h5>


                                            <button type="button" class="btn-success">Send</button>
                                        
                                        </div>
                                        <div class="modal-body border-0 ps-0 pr-0">

                                            <div class="justify-content-center d-flex w-100">

                                            <div class="fw_search d-flex col-9">
                                                <div class="pt-1">
                                                  <img
                                                    width="17px"
                                                    [nexaeiImageSrc]="'emp-invite/search.png'"
                                                  />
                                                </div>
                                                <form class="w-75">
                                                  <input
                                                    type="text"
                                                    class="form-control border-0"
                                                    name="search"
                                                    id="search"
                                                    placeholder="Search people & colab"
                                                  />
                                                </form>
                                              </div>

                                            </div> 


                                            <p class="suc">Suggested</p>

                                            <div class="mid-schrall">

                                            <div class="justify-content-between d-flex  position-relative name-pic mb-3">

                                            <div class="d-flex ">

                                                <div  class="position-relative"><div  class="online-status-small online"></div><div  class="d-flex"><div  class="me-2"><div  class="profilepic-new-fw"> 
                                                    <img class="border-green"  [nexaeiImageSrc]="'man.png'" /></div></div></div></div>

                                                    <p class="fs-11">Robert Smith</p>


                                            </div>

                                           <div> <button type="button" class="btn-success-new">Selected</button></div>


                                        </div>

                                        <div class="justify-content-between d-flex  position-relative name-pic mb-3">

                                            <div class="d-flex ">

                                                <div  class="position-relative"><div  class="online-status-small online"></div><div  class="d-flex"><div  class="me-2"><div  class="profilepic-new-fw"> 
                                                    <img class="border-green"  [nexaeiImageSrc]="'man.png'" /></div></div></div></div>

                                                    <p class="fs-11">Robert Smith</p>


                                            </div>

                                           <div> <button type="button" class="btn btn-select">Select</button></div>


                                        </div>


                                        <div class="justify-content-between d-flex  position-relative name-pic mb-3">

                                            <div class="d-flex ">

                                                <div  class="position-relative"><div  class="online-status-small online"></div><div  class="d-flex"><div  class="me-2"><div  class="profilepic-new-fw"> 
                                                    <img class="border-green"  [nexaeiImageSrc]="'man.png'" /></div></div></div></div>

                                                    <p class="fs-11">Robert Smith</p>


                                            </div>

                                           <div> <button type="button" class="btn-success-new">Selected</button></div>


                                        </div>

                                        <div class="justify-content-between d-flex  position-relative name-pic mb-3">

                                            <div class="d-flex ">

                                                <div  class="position-relative"><div  class="online-status-small online"></div><div  class="d-flex">

                                                    <div _ngcontent-aln-c68="" class="profilepic-fw-new">D</div>

                                                </div></div>

                                                    <p class="fs-11">Robert Smith</p>


                                            </div>

                                           <div> <button type="button" class="btn-success-new">Selected</button></div>


                                        </div>

                                        <div class="justify-content-between d-flex  position-relative name-pic mb-3">

                                            <div class="d-flex ">

                                                <div  class="position-relative"><div  class="online-status-small online"></div><div  class="d-flex"><div  class="me-2"><div  class="profilepic-new-fw"> 
                                                    <img class="border-green"  [nexaeiImageSrc]="'man.png'" /></div></div></div></div>

                                                    <p class="fs-11">Robert Smith</p>


                                            </div>

                                           <div> <button type="button" class="btn-success-new">Selected</button></div>


                                        </div>


                                        <div class="justify-content-between d-flex  position-relative name-pic mb-3">

                                            <div class="d-flex ">

                                                <div  class="position-relative"><div  class="online-status-small online"></div><div  class="d-flex"><div  class="me-2"><div  class="profilepic-new-fw"> 
                                                    <img class="border-green"  [nexaeiImageSrc]="'man.png'" /></div></div></div></div>

                                                    <p class="fs-11">Robert Smith</p>


                                            </div>

                                           <div> <button type="button" class="btn-success-new">Selected</button></div>


                                        </div>


                                        <div class="justify-content-between d-flex  position-relative name-pic mb-3">

                                            <div class="d-flex ">

                                                <div  class="position-relative"><div  class="online-status-small online"></div><div  class="d-flex"><div  class="me-2"><div  class="profilepic-new-fw"> 
                                                    <img class="border-green"  [nexaeiImageSrc]="'man.png'" /></div></div></div></div>

                                                    <p class="fs-11">Robert Smith</p>


                                            </div>

                                           <div> <button type="button" class="btn-success-new">Selected</button></div>


                                        </div>

                                        <div class="justify-content-between d-flex  position-relative name-pic mb-3">

                                            <div class="d-flex ">

                                                <div  class="position-relative"><div  class="online-status-small online"></div><div  class="d-flex"><div  class="me-2"><div  class="profilepic-new-fw"> 
                                                    <img class="border-green"  [nexaeiImageSrc]="'man.png'" /></div></div></div></div>

                                                    <p class="fs-11">Robert Smith</p>


                                            </div>

                                           <div> <button type="button" class="btn-success-new">Selected</button></div>


                                        </div>

                                        <div class="justify-content-between d-flex  position-relative name-pic mb-3">

                                            <div class="d-flex ">

                                                <div  class="position-relative"><div  class="online-status-small online"></div><div  class="d-flex"><div  class="me-2"><div  class="profilepic-new-fw"> 
                                                    <img class="border-green"  [nexaeiImageSrc]="'man.png'" /></div></div></div></div>

                                                    <p class="fs-11">Robert Smith</p>


                                            </div>

                                           <div> <button type="button" class="btn-success-new">Selected</button></div>


                                        </div>

                                    </div>

                                        

                                          
                                        </div>
                                    </div>
                                </div>
                            </div>
        
                       </div>



                    <div class="col-md-12 ps-4 ps-lg-4 pt-3 pb-1 float-start">

                        <div class="Forwarded  pt-2">

                          <div class="c-for pb-2"> <img width="15px" [nexaeiImageSrc]="'collab/arrow.png'" />

                            
                            <span>Forwarded </span> </div>  

                        <img class="img-class" src="https://pbs.twimg.com/media/Fl0y_iZXEAAlDqO.jpg" />

                        <div class="c-for pt-2">
                            <span>Arya Abhi, 12 July, 21 | 10:20 AM </span> </div> 

                    </div>
                    </div>


                    <div class="col-md-12 ps-4 ps-lg-4 pt-3 pb-4 float-start">

                        <div class="Forwarded ">

                          <div class="c-for pb-1"> <img width="15px" [nexaeiImageSrc]="'collab/arrow.png'" />


                            <span>Forwarded </span> </div>  

                            <small class="text-white">when we click on member and opens colab with that member</small>

                        <div class="c-for">
                            <span>Arya Abhi, 12 July, 21 | 10:20 AM </span> </div> 

                    </div>
                    </div>



              <div class="col-md-12 ps-4 ps-lg-5 pt-2 pb-4 float-start">

              

                        <div class="image-collab_sec col-4 bg-light-gray border-3 row pe-0">


                            <div class="c-for pb-1 d-flex w-75"> 
                                <div><img class="fw-img" width="15px" [nexaeiImageSrc]="'collab/arrow.png'" /></div>


                                <span class="pt-1">Forwarded </span> </div>  

                            <div class="row pe-0">
                            <div class=" col-6 p-0"> <img class="col-6 p-0" src="https://pbs.twimg.com/media/FmSgc2-aAAIXH80.jpg" /> </div>
                            <div class=" col-6 p-0">
                                 <img class="col-6 p-0" src="https://pbs.twimg.com/media/FjU2lkcWYAgNG6d.jpg"/> </div>
                            <div class="col-6 p-0">
                                 <img class="col-6 p-0" src="https://pbs.twimg.com/media/FmSSw-maAAAaZ0g.jpg" /> </div>
                            <div class="col-6 p-0"> 
                                <img src="https://pbs.twimg.com/media/Fl0y_iZXEAAlDqO.jpg" />
                            </div></div>

                            <div class="c-for ps-0">
                                <span>Arya Abhi, 12 July, 21 | 10:20 AM </span> </div> 

                        </div>


                       

                    </div>
                    


                </div>
            </div>
        </div>
    </section>

    <div class="chat_space"></div>

    <div class="row pt-1 ps-2 gray_bg">
        <div class="col-md-12 pt-2 d-flex mt-5">
            <div class="col-xl-9 col-md-8 chat_btm">
                <div class="d-flex pt-2 pb-2 align-items-end">
                    <h3 class="pt-2">T</h3>

                    <div class="bg-dark-sec ms-3 col-md-10 f-control p-3">
                        <!-- <div class="col-md-8 col-lg-8">
                            <div class="bg-light-gray2 border border-white text-white p-3">
                                <div class="d-flex pb-1">
                                    <p>Pooja Rai</p>
                                    <small class="ps-2 mt-1">12 Aug | 8:20 PM </small>
                                </div>

                                <p class="p-0 m-0">Member Check in lication will be viewed along with other information mentioned when we click on member and opens colab with that ...</p>
                            </div>
                        </div> -->

                        <!-- <input type="text" class="form-control bg-dark-sec border-0" id="" aria-describedby="" placeholder="Write here..." /> -->


                    </div>

                    <a href="#" class="ms-3 mt-1 pt-1"><img width="40px" [nexaeiImageSrc]="'emp-invite/send.png'" /></a>
                </div>
            </div>
        </div>
    </div>
</div>