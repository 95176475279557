<app-sidebar></app-sidebar>
<app-header></app-header>
<div class="schrall-sec">
    <section class="col-12 emv-invittion bg-color-dark collab pt-2 pb-5">
        <div class="row justify-content-between pt-1">
            <div class="col-4 d-flex ps-lg-5 ps-4">
              <div class="me-3">
                <div class="profilepic">D</div>
              </div>
              <div>
                <h4 class="text-white fw-normal pt-2">Design</h4>
                <small class="text-white">9 Aug, 2021</small>
              </div>
              <a href="#" class="ms-3 pt-2"><img width="16px" [nexaeiImageSrc]="'collab/icon1.png'" /></a>
            </div>
            <div class="col-md-4 d-flex justify-content-end pt-1">
              <a href="#" class="me-3"><img width="22px" [nexaeiImageSrc]="'emp-invite/search.png'" /></a>
              <a href="#" class="me-3"><img width="22px" [nexaeiImageSrc]="'emp-invite/phone.png'" /></a>
              <a href="#" class="me-3"><img width="22px" [nexaeiImageSrc]="'emp-invite/video.png'" /></a>
              <div class="dropdown">
                <a href="#" class="me-3" id="dropdownMenuButton1" data-bs-toggle="dropdown" aria-expanded="false"><img
                    width="15px" [nexaeiImageSrc]="'emp-invite/dot.png'" /></a>
                <ul class="dropdown-menu" aria-labelledby="dropdownMenuButton1">
                  <li>
                    <a class="dropdown-item" href="#" data-bs-toggle="modal" data-bs-target="#exampleModal"><img class="me-2"
                        width="15px" [nexaeiImageSrc]="'emp-invite/plus.png'" />
                      Add members</a>
                  </li>
                  <li>
                    <a class="dropdown-item" href="#"><img class="me-2" width="20px"
                        [nexaeiImageSrc]="'emp-invite/massage.png'" />
                      Arrival Message</a>
                  </li>
                  <li>
                    <a class="dropdown-item" href="#"><img class="me-2" width="20px"
                        [nexaeiImageSrc]="'emp-invite/away.png'" />
                      Away Message</a>
                  </li>
                  <li>
                    <a class="dropdown-item" href="#"><img class="me-2" width="20px"
                        [nexaeiImageSrc]="'emp-invite/settings.png'" />
                      Colab Settings</a>
                  </li>
                </ul>
              </div>
              <!-- Modal -->
              <div class="modal fade" id="exampleModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
                <div class="modal-dialog modal-lg modal-dialog-centered">
                  <div class="modal-content pt-2 pb-2">
                    <div class="modal-header border-0">
                      <h5 class="modal-title text-center w-100" id="exampleModalLabel">
                        Add Members
                      </h5>
                      <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                    </div>
                    <div class="modal-body border-0 d-flex justify-content-center pt-5">
                      <div class="col-md-7 position-relative">
                        <div class="bg-dark-sec col-md-12 f-control p-3 d-flex">
                          <div>
                            <img class="me-2 pt-1" width="15px" [nexaeiImageSrc]="'emp-invite/frame.png'" />
                          </div>
                          <input type="text" id="" aria-describedby="" placeholder="Search member by name, email"
                            class="form-control bg-dark-sec border-0 text-white" />
                        </div>
                        <div class="profile_dropdown bg-dark-sec pb-0 d-none">
                          <div class="d-flex pb-3">
                            <div>
                              <img class="me-3" [nexaeiImageSrc]="'Ellipse16.png'" />
                            </div>
                            <p class="pt-2 mt-1">Amit Kumar</p>
                          </div>
                          <div class="d-flex pb-3">
                            <div>
                              <img class="me-3" [nexaeiImageSrc]="'Ellipse16.png'" />
                            </div>
      
                            <p class="pt-2 mt-1">Amita kumar</p>
                          </div>
                          <div class="d-flex pb-3">
                            <div>
                              <img class="me-3" [nexaeiImageSrc]="'Ellipse16.png'" />
                            </div>
                            <p class="pt-2 mt-1">Amita kumar</p>
                          </div>
                          <div class="d-flex pb-3">
                            <p class="pt-2 mt-1">+ Invite amit.kumar</p>
                          </div>
                        </div>
                        <button type="submit" class="btn primary-xl mt-4">Add</button>
                        <div class="d-flex justify-content-center">
                          <button type="submit" class="btn copy_btn mt-4 col-7">
                            <img class="me-2" width="15px" [nexaeiImageSrc]="'emp-invite/copy.png'" />
                            Copy Link to Invite
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="d-flex ps-lg-5 ps-4 pe-2 pt-4 justify-content-between">
            <div class="col-md-4 ">
              <small class="text-white">Add Description</small>
              <hr class="pt-0 mt-1" />
            </div>
            <div class="col-md-8 d-flex justify-content-end pe-3">
              <small class="text-white">Parent Team:</small>
              <div class="dropdown collab2">
                <button class="btn btn-outline-light rounded-pill ms-4 mt-n1 dropdown-toggle pt-1 pb-1" type="button"
                  id="dropdownMenuButton1" data-bs-toggle="dropdown" aria-expanded="false">
                  Software
                </button>
              </div>
            </div>
          </div>

        <div class="col-md-12">
            <div class="tabs-wrapper">
                <nav class="tabs">
                    <a href="#" class="tab after-slide" data-target="1">Members</a>
                    <a href="#" class="tab after-slide" data-target="2">Media</a>
                    <a href="#" class="tab after-slide is-active" data-target="3">Files</a>
                    <a href="#" class="tab after-slide" data-target="4">Links</a>
                    <a href="#" class="tab after-slide" data-target="5">Tags</a>
                    <a href="#" class="tab after-slide" data-target="5">Sub Teams (0)</a>
                </nav>
            </div>
        </div>

        <div class="pb-4">
            <div class="row pt-4 mt-1 ps-5 pe-4 m_mid">
                <div class="col-md-6  col-lg-4  col-xl-3 pb-4">
                    <img class="w-100 rounded" [nexaeiImageSrc]="'media/file1.png'" />

                    <div class="d-flex pt-3">
                        <div>
                            <img class="me-2 m-img" [nexaeiImageSrc]="'Ellipse16.png'" />
                        </div>
                        <p class="pb-0 mb-0 pt-2">Ramesh Gupta</p>
                    </div>

                    <h5 class="pt-2 fw-light">Nexaei Des....pdf</h5>
                    <p class="mt-n1 text-secondary">11 Jan. 22 at 2:30 PM</p>
                </div>
                <div class="col-md-6  col-lg-4  col-xl-3 pb-4">
                    <img class="w-100 rounded" [nexaeiImageSrc]="'media/file1.png'" />

                    <div class="d-flex pt-3">
                        <div>
                            <img class="me-2 m-img" [nexaeiImageSrc]="'Ellipse16.png'" />
                        </div>
                        <p class="pb-0 mb-0 pt-2">Ramesh Gupta</p>
                    </div>

                    <h5 class="pt-2 fw-light">Nexaei Des....pdf</h5>
                    <p class="mt-n1 text-secondary">11 Jan. 22 at 2:30 PM</p>
                </div>
                <div class="col-md-6  col-lg-4  col-xl-3 pb-4">
                    <img class="w-100 rounded" [nexaeiImageSrc]="'media/file1.png'" />

                    <div class="d-flex pt-3">
                        <div>
                            <img class="me-2 m-img" [nexaeiImageSrc]="'Ellipse16.png'" />
                        </div>
                        <p class="pb-0 mb-0 pt-2">Ramesh Gupta</p>
                    </div>

                    <h5 class="pt-2 fw-light">Nexaei Des....pdf</h5>
                    <p class="mt-n1 text-secondary">11 Jan. 22 at 2:30 PM</p>
                </div>
                <div class="col-md-6  col-lg-4  col-xl-3 pb-4">
                    <img class="w-100 rounded" [nexaeiImageSrc]="'media/file1.png'" />

                    <div class="d-flex pt-3">
                        <div>
                            <img class="me-2 m-img" [nexaeiImageSrc]="'Ellipse16.png'" />
                        </div>
                        <p class="pb-0 mb-0 pt-2">Ramesh Gupta</p>
                    </div>

                    <h5 class="pt-2 fw-light">Nexaei Des....pdf</h5>
                    <p class="mt-n1 text-secondary">11 Jan. 22 at 2:30 PM</p>
                </div>
            </div>
        </div>
    </section>
</div>
