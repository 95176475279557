<app-sidebar></app-sidebar>
<app-header></app-header>

<div class="inner-sec_ p-5">

    <div class="row">

        <div class="col-md-6">

            <div class="border-line ">

                <div class="member-edit d-flex d-flex justify-content-between p-2">

                    <a class="d-flex align-items-center">
                        <div class="me-3" style="cursor: pointer;">
                            <div class="profilepic-spe border-green">
                                <img class="profilepic-new"
                                    src="https://media.nexkul.com/org_372/ProfilePicture/user_116298/Thumb/ce6a01a1-1f03-4f3a-ae06-1bd770799d5f.png">
                            </div>
                        </div>

                        <h5> Pooja Singh </h5>

                    </a>

                    <div>

                        <p class="fs-12 p-0 m-0">12 Jun, 22</p>
                        <p class="fs-12 p-0 m-0"> 9:30 AM In</p>

                    </div>


                </div>


                <div class="d-flex justify-content-between w-100 p-3">

                    <div>
                        <p class="text-muted m-0 p-0 fs-13">Email</p>
                        <p class="m-0 p-0 pt-1 fs-13">shelly.holt@website.com</p>
                    </div>
                </div>



                <div class="d-flex justify-content-between w-100 p-3 pt-1">
                    <div>
                        <p class="text-muted m-0 p-0 fs-13">Date of Activation</p>
                        <p class="m-0 p-0 pt-1 fs-13">11 May, 2019</p>
                    </div>

                </div>



                <div class="d-flex justify-content-between w-100 p-3 pt-1">
                    <div>
                        <p class="text-muted m-0 p-0 fs-13">Emp ID</p>
                        <p class="m-0 p-0 pt-1 fs-13">2323</p>
                    </div>

                    <a href="#"> <img class="pt-1" width="15px" [nexaeiImageSrc]="'e-edit.png'" /></a>

                </div>




                  <div class="d-flex justify-content-between w-100 p-3 pt-1">
                    <div class="col-10">
                        <p class="text-muted m-0 p-0 fs-13">Emp ID</p>
                        <div class="wrap pt-2">
                            <div class="search">
                               <input type="text" class="searchTerm" placeholder="2323">

                            </div>
                         </div>
                    </div>

                   <div class="pt-4"> <a href="#">
                         <img class="pt-1 me-2" width="20px" [nexaeiImageSrc]="'member-close.png'" />

                        </a>

                        <a href="#">
                            <img class="pt-1" width="20px" [nexaeiImageSrc]="'success.png'" />
   
                           </a>

                        </div>

                </div>




                <div class="d-flex justify-content-between w-100 p-3 pt-1">
                    <div>
                        <p class="text-muted m-0 p-0 fs-13">Designation</p>
                        <p class="m-0 p-0 pt-1 fs-13">Product Designer</p>
                    </div>

                    <a href="#"> <img class="pt-1" width="15px" [nexaeiImageSrc]="'e-edit.png'" /></a>

                </div>


                <div class="d-flex justify-content-between w-100 p-3 pt-1">
                    <div>
                        <p class="text-muted m-0 p-0 fs-13">Employment Type</p>
                        <p class="m-0 p-0 pt-1 fs-13">Permanent</p>
                    </div>

                    <a href="#"> <img class="pt-1" width="15px" [nexaeiImageSrc]="'e-edit.png'" /></a>

                </div>


            </div>


        </div>


        <div class="col-md-6">

            <div class="border-line-2nd p-3">

              
                 <h6>Teams</h6>

                 <div class="wrap pt-2 position-relative">
                    <div class="search">
                       <input type="text" class="searchTerm" placeholder="Search & Select Team">
                       <button type="submit" class="searchButton">
                         <i class="fa fa-search"></i>
                      </button>
                    </div>

                    <div class="search_dropdown">
                    
                        <div class="d-flex p-2 align-items-center bhover">

                            <div class="profilepic1 me-3" 
                             style="background-color:#61C532"> D </div>
        
                           <div> <p class="m-0 p-0 fs-13">Design Team</p>
                        </div>
                            
                        </div>


                        <div class="d-flex p-2 align-items-center bhover">

                            <div class="profilepic1 me-3" 
                             style="background-color:#F25945"> D </div>
        
                           <div> <p class="m-0 p-0 fs-13">Developement Team</p>
                        </div>
                            
                        </div>


                        <div class="d-flex p-2 align-items-center bhover">

                            <div class="profilepic1 me-3" 
                             style="background-color:#61C532"> D </div>
        
                           <div> <p class="m-0 p-0 fs-13">Design Team</p>
                        </div>
                            
                        </div>


                        <div class="d-flex p-2 align-items-center bhover">

                            <div class="profilepic1 me-3" 
                             style="background-color:#F25945"> D </div>
        
                           <div> <p class="m-0 p-0 fs-13">Developement Team</p>
                        </div>
                            
                        </div>

                        <div class="d-flex p-2 align-items-center bhover">

                            <div class="profilepic1 me-3" 
                             style="background-color:#61C532"> D </div>
        
                           <div> <p class="m-0 p-0 fs-13">Design Team</p>
                        </div>
                            
                        </div>
                
                    </div>


                 </div>

              



                       
            <div class="d-flex justify-content-between w-100 pt-2">

                <div class="d-flex pt-3">

                    <div class="profilepic me-3" 
                     style="background-color:#61C532"> H </div>

                   <div> <p class="m-0 p-0 fs-13">Human Resources Payroll Team</p>
                    <p class="text-muted m-0 p-0 fs-13">9 Aug, 2021</p>
                </div>
                    
                </div>


                <a href="#" class="dropdown-toggle" data-bs-toggle="dropdown" aria-expanded="false"> <img class="pt-4" width="15px" [nexaeiImageSrc]="'3dots.png'" /></a>

                <div class="dropdown-menu">

                   
                   <div class="d-flex">
                   <div><img class="pe-2" width="25px" [nexaeiImageSrc]="'remove.png'" /></div> 
                    <p class="p-0 m-0"> Remove</p>

                </div>
                   
                </div>


               <div class="schreenfixed">

                    <div  class="timerloder">
                        <div  class="pt-2">
                            <a  href="#" style="cursor: auto;">10 sec</a>
                        </div>
                        <a  class="text-green pt-2">Undo</a>
                    </div>



                    
                        <div  class="timerloder-new">
                            <div  class="pt-2">
                                Team not found
                            </div>
                            
                        </div>
            
                
                
                    

                    
                    </div> 


            </div>

                             
            <div class="d-flex justify-content-between w-100 pt-2">

                <div class="d-flex pt-3">

                    <div class="profilepic me-3" 
                     style="background-color: #D3830B"> H </div>

                   <div> <p class="m-0 p-0 fs-13">Human Resources Payroll Team</p>
                    <p class="text-muted m-0 p-0 fs-13">9 Aug, 2021</p>
                </div>
                    
                </div>


                <a href="#"> <img class="pt-4" width="15px" [nexaeiImageSrc]="'3dots.png'" /></a>


            </div>

                             
            <div class="d-flex justify-content-between w-100 pt-2">

                <div class="d-flex pt-3">

                    <div class="profilepic me-3" 
                     style="background-color: #EE92D4"> H </div>

                   <div> <p class="m-0 p-0 fs-13">Human Resources Payroll Team</p>
                    <p class="text-muted m-0 p-0 fs-13">9 Aug, 2021</p>
                </div>
                    
                </div>


                <a href="#"> <img class="pt-4" width="15px" [nexaeiImageSrc]="'3dots.png'" /></a>


            </div>

                             
            <div class="d-flex justify-content-between w-100 pt-2">

                <div class="d-flex pt-3">

                    <div class="profilepic me-3" style="background-color: #F25945;
                     "> H </div>

                   <div> <p class="m-0 p-0 fs-13">Human Resources Payroll Team</p>
                    <p class="text-muted m-0 p-0 fs-13">9 Aug, 2021</p>
                </div>
                    
                </div>


                <a href="#"> <img class="pt-4" width="15px" [nexaeiImageSrc]="'3dots.png'" /></a>


            </div>

                             
            <div class="d-flex justify-content-between w-100 pt-2">

                <div class="d-flex pt-3">

                    <div class="profilepic me-3" 
                     style="background-color:#1F45CC;"> H </div>

                   <div> <p class="m-0 p-0 fs-13">Human Resources Payroll Team</p>
                    <p class="text-muted m-0 p-0 fs-13">9 Aug, 2021</p>
                </div>
                    
                </div>


                <a href="#"> <img class="pt-4" width="15px" [nexaeiImageSrc]="'3dots.png'" /></a>


            </div>


                             
            <div class="d-flex justify-content-between w-100 pt-2">

                <div class="d-flex pt-3">

                    <div class="profilepic me-3" 
                     style="background-color:#61C532"> H </div>

                   <div> <p class="m-0 p-0 fs-13">Human Resources Payroll Team</p>
                    <p class="text-muted m-0 p-0 fs-13">9 Aug, 2021</p>
                </div>
                    
                </div>


                <a href="#"> <img class="pt-4" width="15px" [nexaeiImageSrc]="'3dots.png'" /></a>


            </div>
               


            </div>


      


        </div>

    </div>


</div>