<section class="privacy pt-5 pb-5 bg-black">
 
    <div class="container">
        
       <div class="d-flex justify-content-center">
           

        <img class="priv-img" [nexaeiImageSrc]="'privacy.png'"> 

       </div>

 
        <h2 class="text-center text-white pt-5 pb-5 fw-normal">Privacy Policy</h2>

       <div class="border-top"></div>

       <div class="d-flex justify-content-center">

           <div class="col-md-10 pt-5">
            <p>Welcome to <b>NexAEI Colab</b> (a product of Nexval Infotech Pvt. Ltd.) (hereinafter referred to as <b>“Service Provider”</b>).<br><br>
                Our Privacy Policy governs your use of the <b>NexAEI Colab application</b> and explains how we collect, safeguard, and disclose information that results from your use of our <b>Service.</b><br><br>
                NexAEI Colab Services will be provided through the NexAEI Colab software based on the user subscription agreement.<br><br>
                By using Services, you agree to the collection and use of information by the Service Provider in accordance with this policy. Unless otherwise defined in this Privacy Policy, the terms used in this Privacy Policy have the same meanings as in our Terms and Conditions.<br><br>
                Our Terms and Conditions <b>(“Terms”)</b> govern all use of our Service and together with the Privacy Policy constitute your agreement with us <b>(“Agreement”)</b>.<br><br>
                NexAEI is strongly committed to protecting your privacy and complying with your choices. Both personal and non-personal information collected is safeguarded according to the highest privacy and data protection standards adopted worldwide – General Data Protection Regulation <b>(GDPR)</b>. We have maintained a robust and effective data protection program in place which complies with existing law and abides by the data protection principles and is updated from time to time.<br><br>
                <b>Our Commitment -</b><br><br>
                </p>
                <ul>
                <li>Your information will not be shared, rented, or sold to any third-party service provider/s. </li><br>
                <li>We use state-of-the-art data security measures to protect your information from unauthorized users</li>

               </ul><br><br>
               <p>
               <b> Specifically, this Privacy Policy describes our practices regarding -</b><br><br>
                    <label>
                    1.	Data Collection<br>
                    2.	Data Usage<br>
                    3.	Data Sharing<br>
                    4.	Data Location and Retention<br>
                    5.	Cookies and Tracking Technologies<br>
                    6.	Communications<br>
                    7.	Data Security<br>
                    8.	Data Subjects Rights<br>
                    9.	Data Controller/Processor<br>
                    10.	Additional Notices<br>
                    </label><br><br>
                
                <b>1. Data Collection</b><br><br>
                We use your data to provide and improve Service. By using Service, you agree to the collection and use of information in accordance with this policy. Unless otherwise defined in this Privacy Policy, the terms used in this Privacy Policy have the same meanings as in our Terms and Conditions.<br><br>
                In course of providing NexAEI Colab Services, NexAEI collects the following information from your employees depending upon the service level subscription agreement – <br><br>
                    <span [nexaeiBackgroundImage]="'left-arrow.png'" [nexaeiBackgroundImage]="'left-arrow.png'" [nexaeiBackgroundImage]="'left-arrow.png'">Name, address, telephone number, email address, and business details when you/your employer creates an account. </span>
                    <span [nexaeiBackgroundImage]="'left-arrow.png'" [nexaeiBackgroundImage]="'left-arrow.png'">Interactions with third-party service providers. </span>
                    <span [nexaeiBackgroundImage]="'left-arrow.png'" [nexaeiBackgroundImage]="'left-arrow.png'">Location-based data (GPS coordinates) through our geolocation feature if the employer has requested users to log in with their GPS location. </span>
                    <span [nexaeiBackgroundImage]="'left-arrow.png'" [nexaeiBackgroundImage]="'left-arrow.png'">Biometric data through our facial recognition feature if the employer has requested logging through selfies.</span>
                    <span [nexaeiBackgroundImage]="'left-arrow.png'" [nexaeiBackgroundImage]="'left-arrow.png'">Collection of time entry and employee attendance/availability data when you use the App.</span>
                    <span [nexaeiBackgroundImage]="'left-arrow.png'" [nexaeiBackgroundImage]="'left-arrow.png'">Location information, biometric information.</span>
                    <span [nexaeiBackgroundImage]="'left-arrow.png'" [nexaeiBackgroundImage]="'left-arrow.png'">Your/employee Internet Protocol (IP) address (if using a browser), operating system, the browser type, the address of a referring site, and any device identifier including any unique id(if using a mobile device or tablet) and other devise diagnostic data.</span>
                    <span [nexaeiBackgroundImage]="'left-arrow.png'" [nexaeiBackgroundImage]="'left-arrow.png'">Employee collaboration data like, messages, files, folders, images.
                    The lawful basis for collecting your data is by way of –</span>
                    <span [nexaeiBackgroundImage]="'left-arrow.png'" [nexaeiBackgroundImage]="'left-arrow.png'">Consent and by accepting our Privacy Policy along with the Terms & Conditions by your employer and/or you. </span>
                    <span [nexaeiBackgroundImage]="'left-arrow.png'" [nexaeiBackgroundImage]="'left-arrow.png'">Processing your data in the way explained above for us to provide NexAEI Colab service to your employer and/or you. </span>
                    <span [nexaeiBackgroundImage]="'left-arrow.png'" [nexaeiBackgroundImage]="'left-arrow.png'">Some of the data is processed for the purposes of our legitimate interests.</span> <br><br>
                    <b>2. Data Usage</b><br><br>
                    This captured data is collected to improve and secure our Services; for analytics, marketing, and sales purposes e.g. <br><br>
                    <span [nexaeiBackgroundImage]="'left-arrow.png'" [nexaeiBackgroundImage]="'left-arrow.png'">Communication with the employee users for comments, suggestions, requests, feedbacks, ratings etc. for information about our products and services and/or any other correspondence or phone calls. </span>
                    <span [nexaeiBackgroundImage]="'left-arrow.png'" [nexaeiBackgroundImage]="'left-arrow.png'">Marketing partners and affiliate interactions. </span>
                    <span [nexaeiBackgroundImage]="'left-arrow.png'" [nexaeiBackgroundImage]="'left-arrow.png'">For marketing purposes, we take note of the marketing channels and campaigns that drew you to NexAEI Colab. </span>
                    <span [nexaeiBackgroundImage]="'left-arrow.png'" [nexaeiBackgroundImage]="'left-arrow.png'">For providing employee analytics as may be demanded by the employer for internal operational purpose uses.</span> 
                    <span [nexaeiBackgroundImage]="'left-arrow.png'" [nexaeiBackgroundImage]="'left-arrow.png'">For R & D and consequent improvement in our services including and not limited to the following – </span>

                </p>
                <ul>
                    <li>To gain better understanding of how Users evaluate, use and interact with our Services.</li><br>
                    <li>To improve user experience and continually improve our products, offerings and the overall performance of our services.</li><br>
                    <li>Developing new line of products/services based on user feedback and experience.</li><br>
                    <li>To facilitate, sponsor and offer certain events, contests and promotions.</li>
                </ul><br><br>
                <p>
                NexAEI as a Service Provider through the NexAEI Colab application acts by default as a data processor and uses employment data within the scope of its data collection under the Services hitherto provided, to provide analytics and reporting services to the employer as per operational business requirement, comply with applicable laws, and to support our legitimate interests. Personal information is the data captured in the process of use of the NexAEI Colab application entered by the Customer’s end-users/employee in the process of using the service of NexAEI. The customer is responsible for determining the purpose and means for the processing of personal information for its end-users. Therefore, the customer shall be regarded as the controller of personal data. NexAEI shall be regarded as the processor as per request received from the customer.<br><br>  
                Cross-referencing your information with what is received from our partners in order to provide a more enhanced and personalized App experience. <br><br>
                Using data information by the customer/employer in communication with the employee/end-user e.g. responding to your comments or inquiries, resolving disputes, contacting you regarding any other operational issues with the App or matter involving product or service transactions, to address problems on the App or our Service and to enforce our agreements with you. <br><br>
                As a data processor, we can provide a data processing agreement to team owners. If you are a team owner, you must comply with GDPR. Customer is responsible to collect any necessary consent, providing any notice where necessary to their respective end-users, and being compliant with applicable data protection laws. <br><br>
                Acceptance of our <b>Terms and Conditions</b> and this <b>Privacy Policy</b> will be deemed as customer’s  consent to the processing of data of the end-user/employee on behalf of the customer/organization for all purposes detailed in this document. If you wish to revoke such consent, please contact us by email at support@nexaei.com.<br><br>
                <b>3) Data Sharing</b><br><br>
                We share your data (& feedback) with your organization as per the Terms agreed upon.<br><br>
                <b>Legal Compliance:</b> In exceptional circumstances, we may disclose or allow government and law enforcement officials access to your Personal Data, in response to a subpoena, search warrant or court order (or similar requirement), or in compliance with applicable laws and regulations. Such disclosure or access may occur if we believe in good faith that: (a) we are legally compelled to do so; (b) disclosure is appropriate in connection with efforts to investigate, prevent, or take action regarding actual or suspected illegal activity, fraud, or other wrongdoing; or (c) such disclosure is required to protect the security or integrity of our products and services.<br><br>
                <b>Service Providers:</b> We may engage selected third-party companies and individuals to perform services complementary to our own. Such service providers include providers of Third Party Services (as defined in the Terms), hosting and server co-location services, e-mail distribution and monitoring services, data optimization, resellers, and providers of professional services related to our Service, and our legal, compliance and financial advisors (collectively, “Service Providers“).<br><br>
                These Service Providers may have access to a limited amount of your Personal Data, depending on each of their specific roles and purposes in facilitating and enhancing our Service, and may only use it for such limited purposes as determined in our agreements with them. When our Service Providers contact you in order to offer or promote our Service, they may additionally interest you in their own services. Should you decide to engage in such activities with NexAEI Colab’s Service Providers, please note that such engagement is beyond the scope of NexAEI Colab’s Terms and Privacy Policy and will therefore be covered by our Service Provider’s terms and privacy policy.<br><br>
                Our Service Providers shall be deemed as ‘Data Processors’ in circumstances where NexAEI assumes the role of ‘Data Controller’; and where NexAEI acts as the Data Processor for our Customer, the Service Provider shall be deemed our ‘Sub-Processor’.<br><br>
                While using our Service, we may ask you to provide us with certain personally identifiable information that can be used to contact or identify you (“Personal Data”). This information may include, but is not limited to:<br><br>
                </p>
                    <ul>
                    <li>Email address</li><br>
                    <li>First name and Last name</li><br>
                    <li>Phone number</li><br>
                    <li>Address, Country, State, Province, ZIP/Postal code, City</li><br>
                    <li>Cookies and Usage Data</li><br>
                    <li>User’s location Global Positioning System (GPS) data (as directed by your employer)</li><br>
                    <li>User Biometric Data (only if your employer opts for the feature)</li><br>
                    <li>Employee collaboration data like, messages, files, folders, images.</li><br>
                    <li>User official attendance/availability information.</li>
                    </ul><br><br>
                <p>    
                to facilitate the operation of the App and Service and to perform related services. For example, and without limitation, maintenance services, database management, web analytics, and improvement of the Service’s features or to assist us in analyzing how our App and Service are used. <br><br>
                
                We may share aggregated information that does not include personal information and we may otherwise disclose such information with third parties for industry analysis, demographic and analytical profiling, and other purposes. Any aggregated information shared in these contexts will not contain your personal information. <br><br>
                
                <b>4. Data Location & Retention	</b><br><br>
                <b>Data Location</b><br><br>
                The data we collect is hosted on Mumbai Zone at Amazon Web Services. This allows us to provide a reliable service and keeps your data available whenever you need it. We have also established a disaster recovery plan as additional support from our end. <br><br>
                <b>Data Retention</b><br><br>
                We generally retain 3 types of Information, account details, Personal information, and service transaction data to fulfil the purposes for which we collected it, including for the purposes of satisfying any legal, accounting, or reporting requirements, to establish or defend legal claims, or for fraud prevention purposes.<br><br />
				We will retain your data for as long as it is reasonably necessary as per the service agreement, terms of usage and subscription contract to provide our services and offerings and maintain and expand our relationship. to comply with our legal and contractual obligations; or to protect ourselves from any potential disputes, all in accordance with our data retention policy.<br /><br />
				If you have any question or request for specific discretion about our data retention policy, please contact us by e-mail at support@nexaei.com for the purposes of satisfying any legal, accounting, or reporting requirements, to establish or defend legal claims, or for fraud prevention. We determine that the appropriate retention period for transactional data including archived and deleted Information is relevant as per subscription agreement and account information till Six (6) years and PI information including archived user details as per service terms. Specific discretion could be handled for customize archival of document and data after consideration of factors such as the amount, nature, and sensitivity of the Information, the potential risk of harm from unauthorized use or disclosure of your information, the purposes for which we process your Information and the applicable legal requirements, or permanent deletion of data. <br /><br />
				When we no longer require the Information, we have collected, we will either delete or anonymize it. However, if necessary, we may retain your information for our compliance with a legal, accounting, reporting obligation or for archiving purposes in accordance with applicable law.
                <br><br>  
                <b>5. Cookies & Tracking Technologies</b><br><br>
                We use cookies and similar tracking technologies to track the activity on our Service and we hold certain information.<br><br>
                Cookies are files with a small amount of data which may include an anonymous unique identifier. Cookies are sent to your browser from a website and stored on your device.<br><br>
                You can instruct your browser to refuse all cookies or to indicate when a cookie is being sent. However, if you do not accept cookies, you may not be able to use some portions of our Service.<br><br>
                <b>Other Data</b><br><br>
                While using our Service, we may also collect the following information on <b>behalf of your employer</b>: sex, age, date of birth, place of birth, citizenship, registration at the place of residence and actual address, telephone number (work, mobile), details of documents on education, qualification, professional training, employment agreements, NDA agreements, information on bonuses and compensation, information on marital status, family members, office location and other data.<br><br>
                <b>6. Communications</b>
                We engage in service communications only through e-mail, phone, SMS, and notifications, only if opted for by you or as directed by your owner.<br><br>
                <b>Service Communications:</b><br><br>
                We may contact you with important information regarding our Service. For example, we may send you notifications (through any of the means available to us) of changes or updates to our Service, service changes, log-in attempts or password reset notices, etc. You can control your communications and notifications settings from your User Profile settings. However, please note that you will not be able to opt out of receiving certain service communications which are integral to your use (like password resets)<br><br>
                <b>Promotional Communications:</b>
                We may also notify you about new features, additional offerings, events, special opportunities, or any other information we think our Users will find valuable. We may provide such notices through any of the contact means available to us (e.g., phone, mobile, or e-mail), through the Service, or through our marketing campaigns on any other sites or platforms.<br><br>
                If you do not wish to receive such promotional communications, you may notify NexAEI Colab’s at any time by sending an email to support@nexaei.com, changing your communications preferences in your User Profile settings, or by following the “Unsubscribe”, “stop”, “opt-out” or “change e-mail preferences” instructions contained in the promotional communications you receive.<br><br>
                <b>7. Data Security</b><br><br>
                We take precautions to protect the security of your information. We secure your personal data using industry-standard physical, procedural, and technical measures. In addition, people can commit intentional crimes, make mistakes or fail to follow policies.<br><br>
                However, please be aware that in spite of us putting in reasonable efforts to protect your personal information and regardless of any security measures we use, we cannot and do not guarantee the absolute protection and security of any personal data stored with us.<br><br>
                <b>8. Data Subject Rights</b><br><br>
                We are committed to helping our customers meet the data subject rights requirements of the General Data Protection Regulation (GDPR).<br><br>
                <b>Article 5 of the GDPR requires that personal data shall be:</b><br><br>
                </p>
                <ul>
                <li>Processed lawfully, fairly, and in a transparent manner in relation to end users;</li><br>
                <li>Collected for specified, explicit, and legitimate purposes and not further processed in a manner that is incompatible with those purposes;</li><br>
                <li>Adequate, relevant, and limited to what is necessary for relation to the purposes for which they are processed.</li><br>
                <li>Accurate and, where necessary, kept up to date; every reasonable step must be taken to ensure that personal data that are inaccurate, having regard to the purposes for which they are processed, are erased or rectified without delay;</li><br>
                <li>Kept in a form that permits identification of data subjects for no longer than is necessary for the purposes for which the personal data are processed, personal data may be stored for longer periods insofar as the personal data will be processed solely for archiving purposes in the interest of your employer’s organization.</li><br>
                <li>Processed in a manner that ensures appropriate security of the personal data, including protection against unauthorized or unlawful processing and against accidental loss, destruction, or damage, using appropriate technical or organizational measures.</li>
                </ul><br><br>
                <p>
                Please note that once you contact us by e-mail, we may instruct you on how to fulfil your request independently through your User Profile settings; or may require additional information and documents, including certain Personal Data, in order to authenticate and validate your identity and to process your request.<br><br>
                <b>9. Data Controller/Processor</b><br><br>
                We are the Controller of our Visitors’ and Customers’ Personal Data, and certain types of User Data; we are the Processor of any Personal Data contained in Customer Data, and of User Data processed on behalf of our Customers.<br><br>
                NexAEI is the “Data Controller” of its Customers’ Personal Data, as well as User Data consisting of profile and contact details, as well as usage, preferences, engagement, and analytics data. With respect to such data, we assume the responsibilities of the Data Controller (solely to the extent applicable under law), as set forth in this Privacy Policy.<br><br>
                If any of our Users upload or submit Customer Data or other content to our Service which includes Personal Data (e.g., by submitting their own clients’ contact details to one of their Boards), such data will only be processed by NexAEI on behalf of your employer, who is the owner of the respective Account, along with all other User Data processed on your employer’s behalf.<br><br>
                In such instances, your employer shall be deemed the “Data Controller” of such data, and NexAEI will process such data on the Customer’s behalf, as its “Data Processor”.<br><br>
                <b>10. Additional Notices</b><br><br>
                <b>Updates and Amendments:</b> We may update and amend this Privacy Policy from time to time by posting an amended version on our Service. The amended version will be effective as of the date it is published. When we make material changes to this Privacy Policy, we’ll provide Users with notice as appropriate under the circumstances, e.g., by displaying a prominent notice within the Service or by sending an email. Your continued use of the Service after the changes have been implemented will constitute your acceptance of the changes.<br><br>
                <b>Questions, Concerns or Complaints:</b> If you have any comments or questions regarding our Privacy Policy, or if you have any concerns regarding your Personal Data held with us, please contact NexAEI support at support@nexaei.com. If you are a GDPR-protected individual, you also have the right to lodge a complaint with an EU supervisory authority.<br><br>
                
                </p>
           </div>

       </div>
        

    </div>

</section>
