<div  class="col-12 res-stu bg-color-dark pb-5" style="height:100%; overflow-y:scroll;">
    <div  class="form-container">
        
        <span  class="logo col-md-12 text-center">
            <img [nexaeiImageSrc]="'mobile_logo.png'" />

            <h1 >Welcome to Colab</h1>
            <p >Login to your account</p>
        </span>
        <form class="pt-5" 
>
            <fieldset  class="form_card ">
                
                    <div  class="col-md-12">
                        <div  class="input_field">
                            <span  class="icon">

                            <img [nexaeiImageSrc]="'input_field/envelope.svg'" />


                                </span>
                                <input type="email" id="" placeholder="Email Address">
                                </div>
                    </div>
                    <div  class="col-md-12">
                        <div  class="input_field">
                            <span  class="icon">
                                <img [nexaeiImageSrc]="'input_field/lock.svg'" />
                                </span>
                                <input type="password" id="" placeholder="Password"></div>
                    </div>
                    <div  class="col-md-12">
                        <button  type="submit" class="btn primary-xl">Login</button></div>
               
            </fieldset>
        </form><span  class="bottom-text">
            <label class="login_cndtn redirec_sigin mt-4">
                <h6 >
                    <a href="#">Forgot Password?</a></h6>
            </label></span>
            <span  class="bottom-text">
                <label class="login_cndtn redirec_sigin">
                <h6 > Don't have account? <a href="">Sign up</a></h6>
            </label>
        </span>
    </div>
</div>