import { Injectable } from '@angular/core';
import { Observable, Observer, Subscription } from 'rxjs';
import { switchMap, tap, map } from 'rxjs/operators';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { PrintScreenBlockComponent } from 'src/app/templates/authorized/enterprise/popup/print-screen-block/print-screen-block.component';

@Injectable({
  providedIn: 'root'
})
export class PrintScreenService {
  base64Image: any;
  modalDialog: any;
  showPopup: boolean = true;
  constructor(
	private dialog: MatDialog,
  ) { }
  
  printScreenAllowed() {
	this.showPopup = localStorage.getItem('IS-ALLOWED-SCREENSHOT-ORG') == 'N'?false:true;
	if(!this.showPopup) {
	  this.restrictDownload(this.showPopup); 
	  return false;
	}
  }
  
  restrictDownload(openPopup:boolean=false) {
	this.modalDialog = null;
	let downloadConfiguration;
    downloadConfiguration = this.openPopup(1);
	this.modalDialog = this.dialog.open(
      PrintScreenBlockComponent,
      downloadConfiguration
    );
    this.modalDialog.afterClosed().subscribe(() => {
      
    });
  }
  
  openPopup(is_active: number ) {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = true;
    dialogConfig.width = '50vw';
    dialogConfig.panelClass = 'borderless-dialog';
    dialogConfig.panelClass = 'bck-remove';
    dialogConfig.data = {
      isActive: is_active,
    };
    return dialogConfig;
  }
  
}
