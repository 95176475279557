import { Component, OnInit, Inject } from '@angular/core';
import { UserDataService } from 'src/app/service/user-data.service';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { AllowDownloadRestriction } from 'src/app/interfaces/user.interface';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-print-screen-block',
  templateUrl: './print-screen-block.component.html',
  styleUrls: ['./print-screen-block.component.scss']
})
export class PrintScreenBlockComponent implements OnInit {

  showPopup: boolean;
  constructor(
    private UserDataService: UserDataService,
    private router: Router,
    public dialogRef: MatDialog,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {}

  ngOnInit(): void {
  }
  
  hidePopup() {
    this.showPopup = true;
    this.dialogRef.closeAll();
  }
 
}
