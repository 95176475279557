import {
  AfterViewInit,
  Component,
  ElementRef,
  OnInit,
  ViewChild,
} from '@angular/core';
import { Router } from '@angular/router';
import { Validators, FormGroup, FormControl } from '@angular/forms';
import { AuthHttpService } from 'src/app/service/auth-http.service';
import { ValidateService } from 'src/app/service/validate.service';
import { StoreService } from 'src/app/service/store.service';
import { createTeam } from 'src/app/interfaces/signup.interface';
import { environment } from 'src/environments/environment';

import { Subscription } from 'rxjs/internal/Subscription';
import { Modal } from 'bootstrap';
import { EventEmitterService } from 'src/app/service/event-emitter.service';

@Component({
  selector: 'app-org-complete-popup',
  templateUrl: './org-complete-popup.component.html',
  styleUrls: ['./org-complete-popup.component.scss'],
})
export class OrgCompletePopupComponent implements OnInit {
  returnUrl: string = '/login';
  returnUrl1: string = './dashboard/';
  returnUrlBackward: string = 'signup/user-signup';
  orgSubscription: Subscription | null;
  orgCompleteInviteModal: Modal | null = null;
  user_fname: any;
  constructor(
    private eventEmitterService: EventEmitterService,
    private router: Router,
    private store: StoreService,
    private authHttp: AuthHttpService
  ) {}

  ngOnInit(): void {
    this.orgSubscription = this.eventEmitterService.orgSetupComplete.subscribe(
      (status) => {
        if (status) this.openOrgCompModal();
      }
    );
    this.user_fname = localStorage.getItem('current_fname');
  }
  ngAfterViewInit(): void {
    this.orgCompleteInviteModal = new Modal(
      this.orgCompleteModalEle?.nativeElement
    );
  }

  @ViewChild('org_complete', { static: false })
  orgCompleteModalEle: ElementRef | null = null;

  openOrgCompModal() {
    if (this.orgCompleteModalEle) this.orgCompleteInviteModal.show();
  }

  closeOrgCompModal() {
    if (this.orgCompleteModalEle) this.orgCompleteInviteModal.hide();
  }
  doLogin() {
    if (localStorage.getItem('current_fname') == null) {
      localStorage.clear();
      this.router.navigate([this.returnUrl]);
    } else {
      this.authHttp.loginDataRefreshOrgSetup().subscribe((res) => {
        this.store.setUserRole(res.data['role_name']);
        if (res.data['orgdetails'] != null) {
          localStorage.setItem('org_name', res.data['orgdetails']['orgname']);
        } else {
          localStorage.setItem('org_name', '-----');
        }
        localStorage.setItem('user_id', res.data.sendbird_user_details.user_id);
        localStorage.setItem('region_name', res.data['regionname']);
		localStorage.setItem('regions_id', res.data['regions_id']);
		if (res.data['role_name'] == 'SUPERADMIN') {
			localStorage.setItem('new_region_id', res.data['regions_id']);
		}
        this.router.navigate([this.returnUrl1]);
		
        (error) => {
          //this.isSubmitFormLoading = false;
        };
      });
    }
    this.closeOrgCompModal();
  }
}
