import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-invitation-url-landing',
  templateUrl: './invitation-url-landing.component.html',
  styleUrls: ['./invitation-url-landing.component.scss'],
})
export class InvitationUrlLandingComponent implements OnInit {
  constructor(private router: Router) {}

  ngOnInit(): void {
    localStorage.clear();
    localStorage.clear();
    this.router.navigate(['/login']);
  }
}
