<div [hidden]="showPopup" class="modal-dialog modal-dialog-centered modal-sm">
  <div class="modal-content">
    <div class="modal-body">
      <div class="text-center pt-4 pb-2">
        <img width="60px" class="pe-2" [nexaeiImageSrc]="'downloadreactive.png'" />
   
    	<p class="fs-14 pt-3">
		Print Screen is not permitted
		</p>
      </div>

      <div class="d-flex justify-content-center px-5 pb-4">
        <button
          type="button"
          class="btn cancelbtn mt-2 me-1"
          mat-dialog-close
          (click)="hidePopup()"
        >
          OK</button
        >
      </div>
    </div>
  </div>
</div>
