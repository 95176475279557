<app-sidebar></app-sidebar>
<app-header></app-header>

<div class="inner-sec_">
    <section class="pt-5">
        <div class="row">



     
            <!--tabular button-->
            <div class="col-lg-12">

            <h4>Registered Devices</h4>


                <div class="tabcontent width-full">
                    <ul>
                        <li>
                            <a class="active" href="#">All Devices</a>
                        </li>
                        <li>
                            <a href="#">New Requests</a>
                        </li>
                    </ul>
                 
                </div>
            </div>
            <!--table section-->

<div class="scrollbar-track container-scroll-act">
            <div class="tablesection table-empty col-lg-12 text-center">
                <table class="table">
                    <thead>
                        <tr>
                            <th scope="col">Name</th>
                            <th scope="col">Email Id</th>
                            <th scope="col">Device Name</th>
                            <th scope="col">OS</th>
                            <th scope="col">Device Id</th>
                            <th scope="col">Last Login</th>
                            <th scope="col">Date of Activation</th>
                            <th scope="col">Status</th>
                            <th scope="col">Action</th>
                        </tr>
                    </thead>

                    <tbody>
                        <tr>
                            <td>Amkit kumar</td>
                            <td>amit.kumar@gmail.com</td>
                            <td>
                                Samsung Galaxy S20
                            </td>
                            <td>
                                Android 12
                            </td>

                            <td>
                                86081506*********
                            </td>
                            <td>
                                16 Jan 2023 ; 15:58:23 (IST)
                            </td>

                            <td>
                                12-10-2022
                            </td>

                            <td>
                                Active
                            </td>

                            <td class="position-absolute z-index">
                                <a href="#" ><img width="15px" [nexaeiImageSrc]="'accept.png'" /></a>
                                
                                <a href="#">
                               <img class="ms-2" width="16px" [nexaeiImageSrc]="'reject.png'" />
                            </a>
                            </td>

                        </tr>

                        <tr>
                            <td>Amkit kumar</td>
                            <td>amit.kumar@gmail.com</td>
                            <td>
                                Samsung Galaxy S20
                            </td>
                            <td>
                                Android 12
                            </td>

                            <td>
                                86081506*********
                            </td>
                            <td>
                                16 Jan 2023 ; 15:58:23 (IST)
                            </td>

                            <td>
                                12-10-2022
                            </td>

                            <td>
                                Active
                            </td>

                            <td class="position-absolute z-index">
                                <a href="#" ><img width="15px" [nexaeiImageSrc]="'accept.png'" /></a>
                                
                                <a href="#">
                               <img class="ms-2" width="16px" [nexaeiImageSrc]="'reject.png'" />
                            </a>
                            </td>

                        </tr>

                        <tr>
                            <td>Amkit kumar</td>
                            <td>amit.kumar@gmail.com</td>
                            <td>
                                Samsung Galaxy S20
                            </td>
                            <td>
                                Android 12
                            </td>

                            <td>
                                86081506*********
                            </td>
                            <td>
                                16 Jan 2023 ; 15:58:23 (IST)
                            </td>

                            <td>
                                12-10-2022
                            </td>

                            <td>
                                Active
                            </td>

                            <td class="position-absolute z-index">
                                <a href="#" ><img width="15px" [nexaeiImageSrc]="'accept.png'" /></a>
                                
                                <a href="#">
                               <img class="ms-2" width="16px" [nexaeiImageSrc]="'reject.png'" />
                            </a>
                            </td>

                        </tr>


                        <tr>
                            <td>Amkit kumar</td>
                            <td>amit.kumar@gmail.com</td>
                            <td>
                                Samsung Galaxy S20
                            </td>
                            <td>
                                Android 12
                            </td>

                            <td>
                                86081506*********
                            </td>
                            <td>
                                16 Jan 2023 ; 15:58:23 (IST)
                            </td>

                            <td>
                                12-10-2022
                            </td>

                            <td>
                                Active
                            </td>

                            <td class="position-absolute z-index">
                                <a href="#" ><img width="15px" [nexaeiImageSrc]="'accept.png'" /></a>
                                
                                <a href="#">
                               <img class="ms-2" width="16px" [nexaeiImageSrc]="'reject.png'" />
                            </a>
                            </td>

                        </tr>

                        <tr>
                            <td>Amkit kumar</td>
                            <td>amit.kumar@gmail.com</td>
                            <td>
                                Samsung Galaxy S20
                            </td>
                            <td>
                                Android 12
                            </td>

                            <td>
                                86081506*********
                            </td>
                            <td>
                                16 Jan 2023 ; 15:58:23 (IST)
                            </td>

                            <td>
                                12-10-2022
                            </td>

                            <td>
                                Active
                            </td>

                            <td class="position-absolute z-index">
                                <a href="#" ><img width="15px" [nexaeiImageSrc]="'accept.png'" /></a>
                                
                                <a href="#">
                               <img class="ms-2" width="16px" [nexaeiImageSrc]="'reject.png'" />
                            </a>
                            </td>

                        </tr>

                        <tr>
                            <td>Amkit kumar</td>
                            <td>amit.kumar@gmail.com</td>
                            <td>
                                Samsung Galaxy S20
                            </td>
                            <td>
                                Android 12
                            </td>

                            <td>
                                86081506*********
                            </td>
                            <td>
                                16 Jan 2023 ; 15:58:23 (IST)
                            </td>

                            <td>
                                12-10-2022
                            </td>

                            <td>
                                Active
                            </td>

                            <td class="position-absolute z-index">
                                <a href="#" ><img width="15px" [nexaeiImageSrc]="'accept.png'" /></a>
                                
                                <a href="#">
                               <img class="ms-2" width="16px" [nexaeiImageSrc]="'reject.png'" />
                            </a>
                            </td>

                        </tr>

                        <tr>
                            <td>Amkit kumar</td>
                            <td>amit.kumar@gmail.com</td>
                            <td>
                                Samsung Galaxy S20
                            </td>
                            <td>
                                Android 12
                            </td>

                            <td>
                                86081506*********
                            </td>
                            <td>
                                16 Jan 2023 ; 15:58:23 (IST)
                            </td>

                            <td>
                                12-10-2022
                            </td>

                            <td>
                                Active
                            </td>

                            <td class="position-absolute z-index">
                                <a href="#" ><img width="15px" [nexaeiImageSrc]="'accept.png'" /></a>
                                
                                <a href="#">
                               <img class="ms-2" width="16px" [nexaeiImageSrc]="'reject.png'" />
                            </a>
                            </td>

                        </tr>

                        <tr>
                            <td>Amkit kumar</td>
                            <td>amit.kumar@gmail.com</td>
                            <td>
                                Samsung Galaxy S20
                            </td>
                            <td>
                                Android 12
                            </td>

                            <td>
                                86081506*********
                            </td>
                            <td>
                                16 Jan 2023 ; 15:58:23 (IST)
                            </td>

                            <td>
                                12-10-2022
                            </td>

                            <td>
                                Active
                            </td>

                            <td class="position-absolute z-index">
                                <a href="#" ><img width="15px" [nexaeiImageSrc]="'accept.png'" /></a>
                                
                                <a href="#">
                               <img class="ms-2" width="16px" [nexaeiImageSrc]="'reject.png'" />
                            </a>
                            </td>

                        </tr>

                        <tr>
                            <td>Amkit kumar</td>
                            <td>amit.kumar@gmail.com</td>
                            <td>
                                Samsung Galaxy S20
                            </td>
                            <td>
                                Android 12
                            </td>

                            <td>
                                86081506*********
                            </td>
                            <td>
                                16 Jan 2023 ; 15:58:23 (IST)
                            </td>

                            <td>
                                12-10-2022
                            </td>

                            <td>
                                Active
                            </td>

                            <td class="position-absolute z-index">
                                <a href="#" ><img width="15px" [nexaeiImageSrc]="'accept.png'" /></a>
                                
                                <a href="#">
                               <img class="ms-2" width="16px" [nexaeiImageSrc]="'reject.png'" />
                            </a>
                            </td>

                        </tr>

                        <tr>
                            <td>Amkit kumar</td>
                            <td>amit.kumar@gmail.com</td>
                            <td>
                                Samsung Galaxy S20
                            </td>
                            <td>
                                Android 12
                            </td>

                            <td>
                                86081506*********
                            </td>
                            <td>
                                16 Jan 2023 ; 15:58:23 (IST)
                            </td>

                            <td>
                                12-10-2022
                            </td>

                            <td>
                                Active
                            </td>

                            <td class="position-absolute z-index">
                                <a href="#" ><img width="15px" [nexaeiImageSrc]="'accept.png'" /></a>
                                
                                <a href="#">
                               <img class="ms-2" width="16px" [nexaeiImageSrc]="'reject.png'" />
                            </a>
                            </td>

                        </tr>

                        <tr>
                            <td>Amkit kumar</td>
                            <td>amit.kumar@gmail.com</td>
                            <td>
                                Samsung Galaxy S20
                            </td>
                            <td>
                                Android 12
                            </td>

                            <td>
                                86081506*********
                            </td>
                            <td>
                                16 Jan 2023 ; 15:58:23 (IST)
                            </td>

                            <td>
                                12-10-2022
                            </td>

                            <td>
                                Active
                            </td>

                            <td class="position-absolute z-index">
                                <a href="#" ><img width="15px" [nexaeiImageSrc]="'accept.png'" /></a>
                                
                                <a href="#">
                               <img class="ms-2" width="16px" [nexaeiImageSrc]="'reject.png'" />
                            </a>
                            </td>

                        </tr>

                        <tr>
                            <td>Amkit kumar</td>
                            <td>amit.kumar@gmail.com</td>
                            <td>
                                Samsung Galaxy S20
                            </td>
                            <td>
                                Android 12
                            </td>

                            <td>
                                86081506*********
                            </td>
                            <td>
                                16 Jan 2023 ; 15:58:23 (IST)
                            </td>

                            <td>
                                12-10-2022
                            </td>

                            <td>
                                Active
                            </td>

                            <td class="position-absolute z-index">
                                <a href="#" ><img width="15px" [nexaeiImageSrc]="'accept.png'" /></a>
                                
                                <a href="#">
                               <img class="ms-2" width="16px" [nexaeiImageSrc]="'reject.png'" />
                            </a>
                            </td>

                        </tr>

                        <tr>
                            <td>Amkit kumar</td>
                            <td>amit.kumar@gmail.com</td>
                            <td>
                                Samsung Galaxy S20
                            </td>
                            <td>
                                Android 12
                            </td>

                            <td>
                                86081506*********
                            </td>
                            <td>
                                16 Jan 2023 ; 15:58:23 (IST)
                            </td>

                            <td>
                                12-10-2022
                            </td>

                            <td>
                                Active
                            </td>

                            <td class="position-absolute z-index">
                                <a href="#" ><img width="15px" [nexaeiImageSrc]="'accept.png'" /></a>
                                
                                <a href="#">
                               <img class="ms-2" width="16px" [nexaeiImageSrc]="'reject.png'" />
                            </a>
                            </td>

                        </tr>

                        <tr>
                            <td>Amkit kumar</td>
                            <td>amit.kumar@gmail.com</td>
                            <td>
                                Samsung Galaxy S20
                            </td>
                            <td>
                                Android 12
                            </td>

                            <td>
                                86081506*********
                            </td>
                            <td>
                                16 Jan 2023 ; 15:58:23 (IST)
                            </td>

                            <td>
                                12-10-2022
                            </td>

                            <td>
                                Active
                            </td>

                            <td class="position-absolute z-index">
                                <a href="#" ><img width="15px" [nexaeiImageSrc]="'accept.png'" /></a>
                                
                                <a href="#">
                               <img class="ms-2" width="16px" [nexaeiImageSrc]="'reject.png'" />
                            </a>
                            </td>

                        </tr>

                        <tr>
                            <td>Amkit kumar</td>
                            <td>amit.kumar@gmail.com</td>
                            <td>
                                Samsung Galaxy S20
                            </td>
                            <td>
                                Android 12
                            </td>

                            <td>
                                86081506*********
                            </td>
                            <td>
                                16 Jan 2023 ; 15:58:23 (IST)
                            </td>

                            <td>
                                12-10-2022
                            </td>

                            <td>
                                Active
                            </td>

                            <td class="position-absolute z-index">
                                <a href="#" ><img width="15px" [nexaeiImageSrc]="'accept.png'" /></a>
                                
                                <a href="#">
                               <img class="ms-2" width="16px" [nexaeiImageSrc]="'reject.png'" />
                            </a>
                            </td>

                        </tr>

                        <tr>
                            <td>Amkit kumar</td>
                            <td>amit.kumar@gmail.com</td>
                            <td>
                                Samsung Galaxy S20
                            </td>
                            <td>
                                Android 12
                            </td>

                            <td>
                                86081506*********
                            </td>
                            <td>
                                16 Jan 2023 ; 15:58:23 (IST)
                            </td>

                            <td>
                                12-10-2022
                            </td>

                            <td>
                                Active
                            </td>

                            <td class="position-absolute z-index">
                                <a href="#" ><img width="15px" [nexaeiImageSrc]="'accept.png'" /></a>
                                
                                <a href="#">
                               <img class="ms-2" width="16px" [nexaeiImageSrc]="'reject.png'" />
                            </a>
                            </td>

                        </tr>

                        <tr>
                            <td>Amkit kumar</td>
                            <td>amit.kumar@gmail.com</td>
                            <td>
                                Samsung Galaxy S20
                            </td>
                            <td>
                                Android 12
                            </td>

                            <td>
                                86081506*********
                            </td>
                            <td>
                                16 Jan 2023 ; 15:58:23 (IST)
                            </td>

                            <td>
                                12-10-2022
                            </td>

                            <td>
                                Active
                            </td>

                            <td class="position-absolute z-index">
                                <a href="#" ><img width="15px" [nexaeiImageSrc]="'accept.png'" /></a>
                                
                                <a href="#">
                               <img class="ms-2" width="16px" [nexaeiImageSrc]="'reject.png'" />
                            </a>
                            </td>

                        </tr>

                        
                    </tbody>
                </table>
                <!--empty data-->
            
            </div>

        </div>


        </div>
    </section>
</div>

