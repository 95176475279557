<div class="forgetpass">


    <div class="container">

        <div class="row justify-content-between pt-70 ps-lg-5 ps-0">

            <div class="col-lg-3 pt-5 d-md-none d-lg-block">

                <img class="pt-lg-5" width="85%" [nexaeiImageSrc]="'forgerpassword/Frame.png'" />

            </div>


            <div class="col-md-6 col-lg-7 col-xl-6 p-0 res-stu bg-color-dark">
                <div class="form-container w-75">
                    <span class="logo col-md-12"> <img width="100%" [nexaeiImageSrc]="'logo.png'" />
                        <h1>Forgot Password?</h1>
                        <p>Enter your registered email address</p>
                    </span>
                    <form class="" [formGroup]="frmForgotPass" (ngSubmit)="onSubmit()">
                        <fieldset class="form_card g-3">
                            <div class="bg-color-gray row right-sec mt-5">
                                <div class="col-md-12">
                                    <div class="input_field"><span class="icon"><img
                                                src="./assets/images/input_field/envelope.svg">
                                        </span><input type="email" [formControlName]="'email'" placeholder="Email Address"  [ngClass]="{
                                            error_input:
                                            forgotFormControl.email?.dirty &&
                                              forgotFormControl.email?.invalid
                                          }"
                                          [ngClass]="{
                                            error_input: hasError
                                          }"></div>

                                </div>

                                <p class="error_cntnt-new" *ngIf="
                                forgotFormControl.email?.dirty &&
                                forgotFormControl.email?.errors?.required
                              "> Email field required <!--Email address not registered with us!--> </p>
                              <p class="error_cntnt-new" *ngIf="serverErrorMessage">
                                {{ serverErrorMessage }} !!
                              </p>
                              <p
                                class="error_cntnt-new"
                                *ngIf="
                                forgotFormControl.email?.dirty &&
                                forgotFormControl.email?.errors?.notvalidEmail &&
                                    !forgotFormControl.email?.errors?.required &&
                                    !forgotFormControl.email?.errors?.invalidDomain
                                "
                                >
                                Email address not valid
                                </p>
                                <!-- <div  class="col-md-12">
                                    <div  class="input_field"><span 
                                            class="icon"><img 
                                                src="./assets/images/input_field/lock.svg"></span><input
                                             type="password" id="" placeholder="Password" >
        
                                    </div>
                                </div> -->

                                <div class="col-md-12"><button type="submit" class="btn primary-xl">Next</button></div>
                            </div>
                        </fieldset>
                    </form>
                </div>
            </div>




        </div>


    </div>


</div>