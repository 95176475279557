import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-error-dialog',
  styles:[`
  .modal-content {
    background-color: #333333 !important;
    border-radius: 20px !important;
    border: 1.5px solid #686868;
  }

  .modal-lg {
    --bs-modal-width: 514px !important;
}

.w-50 {
  width: 40%!important;
}

button.btn.primary-xl-modal {
  font-size: 19px !important;
  padding: 23px 27px !important;
  border-radius: 40px;
  margin-top: 0;
}

button.btn.primary-xl:focus {
  box-shadow:none;
}
`],
  template: `
<div class="deactive-pop-up">
<div class="modal-dialog modal-dialog-centered modal-lg">
    <div class="modal-content">
      <div class="modal-body text-center pt-4 pb-4 mt-1">
        <img width="90px" [nexaeiImageSrc]="'no-data-img.png'" />

        <p class="fs-14 pt-4">
        {{ errorMessage }}
        </p>

        <div
          class="col-md-12 float-start d-flex justify-content-center pt-3 pb-0 mb-1"
        >
          <button [mat-dialog-close]="true" class="btn primary-xl primary-xl-modal w-50">
            Close
          </button>
        </div>
      </div>
    </div>
  </div>


</div>

  `,
})
export class ErrorDialogComponent {
  constructor(@Inject(MAT_DIALOG_DATA) public errorMessage: string) {
  }
}
