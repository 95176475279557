<div class="emv-invittion">
<div class="modal fade" #org_complete data-bs-backdrop="static" 
    
aria-hidden="true">
                    <div class="modal-dialog modal-lg modal-dialog-centered">
                        <div class="modal-content pt-2 pb-2">
                           
                            <div class="modal-body border-0 d-flex justify-content-center pt-4">
                                <div class="col-lg-10 col-sm-9 position-relative">
                                  <div class="d-flex justify-content-center">
                                    <div>
                                      <img width="80px" [nexaeiImageSrc]="'success1.png'" />
                                    </div>
                                  </div>
                          
                                  <p *ngIf="this.user_fname" class="text-center pt-3">
                                    You have successfully registered with colab please 
                                    click Ok to continue.
                                  </p>
								   <p *ngIf="!this.user_fname" class="text-center pt-3">
                                    You have successfully registered with colab please 
                                    click Ok and login with your account.
                                  </p>
                          
                                  <div class="d-flex justify-content-center">
                                  
                                    <button
                                      type="button"
                                      class="btn primary-xl ms-1"
                                      (click)="doLogin()"
                                    >
                                      Ok
                                    </button>
                                  </div>
                                </div>
                              </div> 


                        </div>
                    </div>
                </div>
            </div>