import { Injectable } from '@angular/core';
import jwt_decode from 'jwt-decode';

@Injectable({
  providedIn: 'root',
})
export class JwtService {
  private token: string | null;

  constructor() {
    this.token = null;
  }

  getToken(): string | null {
    return this.token;
  }

  getTokenFromLocalStorage(): string | null {
    let token: string | null = localStorage.getItem('authentication-token');
    return token;
  }

  setToken(token: string): void {
    localStorage.setItem('authentication-token', token);
    this.token = token;
  }

  isActive(): boolean {
    if (this.token === null) return false;
    return true;
  }

  isTokenInLocalStorage(): boolean {
    if (this.getToken() === null) return false;
    return true;
  }

  removeToken(): void {
    this.token = null;
    localStorage.clear();
  }

  decodeToken(token: string): any {
    try{
      return jwt_decode(token)
    } catch(Error){
      return null;
    }
  }

  getRefreshTokenFromLocalStorage(): string | null {
    let rToken: string | null = localStorage.getItem('refresh_token');
    return rToken;
  }
  setRefreshToken(RToken: string): void {
    localStorage.setItem('refresh_token', RToken);
  }

  getExpiryTime(){
    const token = this.getTokenFromLocalStorage();
    const decodedToken : any = this.decodeToken(token);
    const expiryTime = decodedToken.exp*1000;
    return expiryTime;
  }

}
