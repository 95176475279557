<div id="wrapper">
    <div id="fixscroll">
      <div id="sidebar-wrapper-main">
        <div class="sidebar-brand">
          <ul class="padding-adjust">
            <li class="list-unstyled user-status">
              <h4>S</h4>
              <span>
                <h5>Shelly Holt</h5>
                <label
                  >9:30 AM In
                  <img [nexaeiImageSrc]="'header/location.svg'" /></label
              ></span>
              
              <a data-bs-toggle="dropdown" data-bs-auto-close="true" id="#more"
                ><img [nexaeiImageSrc]="'sidebar/more.png'"
              /></a>
              <ul class="list-unstyled dropdown-menu setting-log" aria-labelledby="more">
                <li>
                  <h3>Settings</h3>
                  <p>NexAei Settings</p>
                  <label>
                    <a>
                      <img [nexaeiImageSrc]="'sidebar/settings/billing.png'" />Billing</a
                    ></label
                  >
                  <label>
                      <a>
                        <img [nexaeiImageSrc]="'sidebar/settings/user-manage.png'" />User Management</a
                      ></label
                    >
                    
                </li>
                <li>
                  <p>Modules Settings</p>
                  <label>
                    <a>
                      <img
                        [nexaeiImageSrc]="'sidebar/settings/avibilaty.png'"
                      />Availability</a
                    ></label
                  >
                  <label>
                    <a>
                      <img
                        [nexaeiImageSrc]="'sidebar/settings/support-request.png'"
                      />Support Requests</a
                    ></label
                  >
                  <label>
                    <a>
                      <img
                        [nexaeiImageSrc]="'sidebar/settings/shift.png'"
                      />Shift</a
                    ></label
                  >
                </li>
                <button>Logout</button>
              </ul>
            </li>
          </ul>
         
        </div>
      </div>
  
      <div
        class="tab-pane fade show active"
        id="nav-home"
        role="tabpanel"
        aria-labelledby="nav-home-tab"
      >
       
  
        <!--calendar tab start-->
        <div
          class="tab-pane fade show active"
          id="nav-home"
          role="tabpanel"
          aria-labelledby="nav-home-tab"
        >
          
        </div>
      </div>
    </div>

  </div>
  