<div  class="col-12 res-stu gray_bg pb-5" style="height:100%; overflow-y:scroll;">
    <div  class="form-container">
        
        <span  class="logo col-md-12 text-center">
            <img *ngIf="is_new_email =='1' || is_invitation_accepted" [nexaeiImageSrc]="'successicon.png'" />
            <img *ngIf="is_new_email == '0'" [nexaeiImageSrc]="'failed.png'" />

            <h5 >{{this.displayMessage}}</h5>
            
            <h5 class="pt-3">Visit <u>https://colab.nexaei.com</u> to access
                your account from a desktop or a laptop.
                Or you can download Colab mobile app for
                a better experience. </h5>

               

        </span>
        
        <div class="text-center">

            <img width="100%" class="pt-2" [nexaeiImageSrc]="'link.png'" />

        </div>
        <a style="text-decoration: none;" target="_blank" href="https://onelink.to/qwdu9z"> 
        <div  class="col-md-12 pt-5 d-flex justify-content-center">
            <button  type="button" class="btn primary-xl">Download App</button></div>
        </a>
 
        
    </div>
</div>