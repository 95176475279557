import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { HttpResponse } from '../interfaces/http-response.interface';
import {
  AddTeam,
  Team,
  createTeam,
  updateTeam,
  teamwiseMemberList,
  getTeam,
  attendancePercentage,
  updateTeamName,
  getTeamsAdmin,
  getUserAssociatedTeams,
  getSuperHierarchy,
  getRegion,
  addRegion,
  editRegion,
  deleteRegion,
} from '../interfaces/team';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class TeamService {
  private endpoint: string = environment.endpoint;
  constructor(private httpClient: HttpClient) {}

  getAllTeams(id: any) {
    let data: any = {
      orgId: id,
    };
    return this.httpClient
      .post<HttpResponse>(this.endpoint + 'api/getAllTeams', data)
      .pipe(
        map((response) => {
          let list: Team[] = response.data;
          response.data = list;
          return response;
        })
      );
  }

  createTeam(data: createTeam) {
    return this.httpClient.post<HttpResponse>(
      this.endpoint + 'api/Hierarchy/AddUpdateHierarchialNode',
      data
    );
  }
  getTeamList(data: getTeam) {
    return this.httpClient.post<HttpResponse>(
      this.endpoint + 'api/Hierarchy/GetUserAssociatedHierarchy',
      data
    );
  }

  updateTeam(data: updateTeam) {
    return this.httpClient.post<HttpResponse>(
      this.endpoint + 'api/addTeam',
      data
    );
  }

  deleteTeam(data: any) {
    // let datas: any = {
    //     id: data,
    //   };

    return this.httpClient.post<HttpResponse>(
      this.endpoint + 'api/Hierarchy/RemoveHierarchy',
      data
    );
  }
  teamwiseMemberList(data: teamwiseMemberList) {
    if (
      localStorage.getItem('org_name') != '-----' &&
      localStorage.getItem('org_name') != null &&
      localStorage.getItem('invite-string') == null
    ) {
      return this.httpClient.post<HttpResponse>(
        this.endpoint + 'api/User/GetAllUsers',
        data
      );
    }
    return null;
  }
  getAttendencePercentage(data: attendancePercentage) {
    return this.httpClient.post<HttpResponse>(
      this.endpoint + 'api/Hierarchy/GetAttendancePercentage ',
      data
    );
  }
  updateTeamName(data: updateTeamName) {
    return this.httpClient.post<HttpResponse>(
      this.endpoint + 'api/Hierarchy/AddUpdateHierarchialNode',
      data
    );
  }
  getTeamsAdmin(data: getTeamsAdmin) {
    return this.httpClient.post<HttpResponse>(
      this.endpoint + 'api/Hierarchy/GetHierarchyList',
      data
    );
  }
  getUserAssociatedTeams(data: getUserAssociatedTeams) {
    return this.httpClient.post<HttpResponse>(
      this.endpoint + 'api/Hierarchy/GetHierarchyListforUser',
      data
    );
  }
  setWatcherOfHierarchy(data: any) {
    return this.httpClient.post(
      this.endpoint + 'api/Hierarchy/SetWatcherOfHierarchy',
      data
    );
  }
  searchTeams(data: any) {
    return this.httpClient.post<HttpResponse>(
      this.endpoint + 'api/Hierarchy/SearchHierarchy',
      data
    );
  }

  getSuperHierarchy(data: getSuperHierarchy) {
    return this.httpClient.post<HttpResponse>(
      this.endpoint + 'api/Hierarchy/GetSuperHierarchyList',
      data
    );
  }

  getRegion(data: getRegion) {
    return this.httpClient.post<HttpResponse>(
      this.endpoint + 'api/Hierarchy/GetRegion',
      data
    );
  }
  addRegion(data: addRegion) {
    return this.httpClient.post<HttpResponse>(
      this.endpoint + 'api/Hierarchy/AddUpdateRegion',
      data
    );
  }
  updateRegion(data: editRegion) {
    return this.httpClient.post<HttpResponse>(
      this.endpoint + 'api/Hierarchy/AddUpdateRegion',
      data
    );
  }
  deleteRegion(data: deleteRegion) {
    return this.httpClient.post<HttpResponse>(
      this.endpoint + 'api/Hierarchy/DeleteRegion',
      data
    );
  }
  getCommonHierarchy(data: any) {
    return this.httpClient.post<HttpResponse>(
      this.endpoint + 'api/Hierarchy/GetCommonHierarchyListforUser',
      data
    );
  }
}
