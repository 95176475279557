<div class="col-12 res-stu bg-color-dark" style="height:100%; overflow-y:scroll;">
    <div class="form-container">

        <span class="logo col-md-12 text-center">
            <img [nexaeiImageSrc]="'mobile_logo.png'" />

            <h6>Update your name and password</h6>

        </span>
        <form class="pt-5" [formGroup]="formForce" (ngSubmit)="onSubmit()">
            <fieldset class="form_card ">

                <div class="col-md-12">
                    <div class="input_field" [ngClass]="{
                        error_input:
                          forcedFormControl.firstName?.dirty &&
                          forcedFormControl.firstName?.invalid
                      }"
                      [ngClass]="{
                        error_input: hasError
                      }">
                        <span class="icon">
                            <img [nexaeiImageSrc]="'input_field/user.svg'" />
                        </span>
                        <input
                        type="text"
                        [formControlName]="'firstName'"
                        aria-describedby=""
                        placeholder="First Name"
                        class="form-control bg-dark-sec border-0 text-white"
                      />
                    </div>
                    <P
                    class="error_cntnt_new pt-1 align"
                      *ngIf="forcedFormControl.firstName?.dirty &&
                        forcedFormControl.firstName?.errors?.required 
  
                      "
                      >First name cannot be blank</P
                    >
                   
                    <p
                    class="error_cntnt_new pt-1 align"
                    *ngIf="forcedFormControl.firstName?.dirty &&
                    forcedFormControl.firstName?.errors?.invalidNamesp  &&
                    !forcedFormControl.firstName?.errors?.required 
                    "
                  >
                  Invalid first name
                  </p>
                </div>

                <div class="col-md-12">
                    <div class="input_field" [ngClass]="{
                        error_input:
                          forcedFormControl.lastName?.dirty &&
                          forcedFormControl.lastName?.invalid
                      }"
                      [ngClass]="{
                        error_input: hasError
                      }">
                        <span class="icon">
                            <img [nexaeiImageSrc]="'input_field/user.svg'" />
                        </span>
                        <input
                      type="text"
                      [formControlName]="'lastName'"
                      aria-describedby=""
                      placeholder="Last Name"
                      class="form-control bg-dark-sec border-0 text-white"
                    />
                    </div>
                    <P
                    class="error_cntnt_new pt-1 align"
                      *ngIf="
                        forcedFormControl.lastName?.dirty &&
                        forcedFormControl.lastName?.errors?.required 
  
                      "
                      >Last name cannot be blank</P
                    >
  
                    <p
                    class="error_cntnt_new pt-1 align"
                    *ngIf="
                    forcedFormControl.lastName?.dirty &&
                    forcedFormControl.lastName?.errors?.invalidNamesp  &&
                    !forcedFormControl.lastName?.errors?.required 
                    "
                  >
                  Invalid last name
                  </p>
                </div>


                <div class="col-md-12">
                    <div class="input_field" [ngClass]="{
                        error_input:
                          forcedFormControl.email?.dirty &&
                          forcedFormControl.email?.invalid
                      }"
                      [ngClass]="{
                        error_input: hasError
                      }">
                        <span class="icon">
                            <img [nexaeiImageSrc]="'input_field/lock.svg'" />
                        </span>
                        <input
                      type="email"
                      [formControlName]="'email'"
                      aria-describedby=""
                      placeholder="Email"
                      class="form-control e-mail-text bg-dark-sec border-0 text-white"
                      [(ngModel)]="userEmail"
                      disabled="true"
                    />
                    </div>
                    <p
                    class="error_cntnt2 pt-1"
                    *ngIf="
                      forcedFormControl.email?.dirty &&
                      forcedFormControl.email?.errors?.required
                    "
                  >
                    Email required.
                  </p>
                  <p
                    class="error_cntnt2 pt-1"
                    *ngIf="
                      forcedFormControl.email?.dirty &&
                      forcedFormControl.email?.errors?.notvalidEmail &&
                      !forcedFormControl.email?.errors?.required &&
                      !forcedFormControl.email?.errors?.invalidDomain
                    "
                  >
                    Please use valid email, only official emails are accepted
                  </p>
                  <p
                    class="error_cntnt2 pt-1"
                    *ngIf="
                      forcedFormControl.email?.dirty &&
                      forcedFormControl.email?.errors?.invalidDomain &&
                      !forcedFormControl.email?.errors?.required
                    "
                  >
                    Invalid Domain
                  </p>
                </div>

                <div class="col-md-12">
                    <div class="input_field" [ngClass]="{
                        error_input:
                          forcedFormControl.password?.dirty &&
                          forcedFormControl.password?.invalid
                      }"
                      [ngClass]="{
                        error_input: hasError
                      }">
                        <span class="icon">
                            <img [nexaeiImageSrc]="'input_field/lock.svg'" />
                        </span>
                        <input
                      [type]="shPassword ? 'text' : 'password'"
                      [formControlName]="'password'"
                      aria-describedby=""
                      placeholder="New Password"
                      class="form-control bg-dark-sec border-0 text-white"
                    />
                    <div
                      class="password_view"
                      [ngClass]="{ active2: shPassword }"
                    >
                      <a (click)="showPassword()">
                        <img
                          class="me-2 pt-1"
                          width="20px"
                          [nexaeiImageSrc]="'input_field/eye.svg'"
                        />
                      </a>
                    </div>
                    </div>
                    <p
                    class="error_cntnt2 pt-1"
                    *ngIf="
                      forcedFormControl.password?.dirty &&
                      forcedFormControl.password?.errors?.required
                    "
                  >
                    Password required
                  </p>
                  <p
                  class="error_cntnt2 pt-1 minimum-show"
                  *ngIf="
                    forcedFormControl.password?.dirty &&
                    forcedFormControl.password?.errors?.invalidPassword &&
                    !forcedFormControl.password?.errors?.required
                  "
                >
                  Use minimum 8 characters with a mix of letters (uppercase,
                  lowercase) Numbers & Symbols (@$!%*?&)
                </p>
                  <p
                    class="error_cntnt2 pt-1 minimum-show"
                    *ngIf="
                      forcedFormControl.password?.dirty &&
                      forcedFormControl.password?.errors?.maxlength  &&
                      !forcedFormControl.password?.errors?.required
                    "
                  >
                    Maximum 28 characters allowed
                  </p>
                  
                </div>

                <div class="col-md-12">
                    <div class="input_field" [ngClass]="{
                        error_input:
                          forcedFormControl.confirmPassword?.dirty &&
                          forcedFormControl.confirmPassword?.invalid
                      }"
                      [ngClass]="{
                        error_input: hasError
                      }">
                        <span class="icon">
                            <img [nexaeiImageSrc]="'input_field/lock.svg'" />
                        </span>
                        <input
                      [type]="shPasswordC ? 'text' : 'password'"
                      [formControlName]="'confirmPassword'"
                      aria-describedby=""
                      placeholder="Confirm New Password"
                      class="form-control bg-dark-sec border-0 text-white"
                    />
                    <div
                      class="password_view"
                      [ngClass]="{ active2: shPasswordC }"
                    >
                      <a (click)="showPasswordConfirm()">
                        <img
                          class="me-2 pt-1"
                          width="20px"
                          [nexaeiImageSrc]="'input_field/eye.svg'"
                      /></a>
                    </div>
                    </div>
                    <p
                    class="error_cntnt2 pt-1"
                    *ngIf="
                      forcedFormControl.confirmPassword?.dirty &&
                      forcedFormControl.confirmPassword?.errors?.required
                    "
                  >
                    Password required
                  </p>
                  <p
                    class="error_cntnt2 minimum-show pt-1"
                    *ngIf="
                      forcedFormControl.confirmPassword?.dirty &&
                      forcedFormControl.confirmPassword?.errors?.mustMatch &&
                      !forcedFormControl.confirmPassword?.errors?.required
                    "
                  >
                    Password and confirm password not match
                  </p>
                </div>

                <!-- <p class="fs-12 text-muted">Use minimum 8 characters with a mix of letters (uppercase, lowercase)
                    Numbers & Symbols</p> -->


                <div class="col-md-12 pt-4">
                    <button type="submit" class="btn primary-xl">Continue</button>
                </div>

            </fieldset>

            <p class="fs-12 pt-4 w-100 text-center pb-5">By clicking on continue you agree to our
                <a href="#" class="text-green"> Privacy Policy </a> and <a href="#" class="text-green"> Terms and
                    Conditions </a>
            </p>

        </form>




    </div>
</div>