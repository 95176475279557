<app-sidebar></app-sidebar>
<app-header></app-header>

<div class="inner-sec_">
    <section>
        <div class="row">
            <div class="col-lg-12">
                <div class="subheader width-full">
                    <div class="col-md-3 pull-left">
                        <h5>Members</h5>
                    </div>
                    <div class="col-md-9 col-xs-12 pull-left button-section">
                        <button class="special special-bg">+ Add Member</button>
                        <button class="special special-border">
                            <span><img [nexaeiImageSrc]="'member/bulk.svg'" /></span>
                            Builk Import
                        </button>
                        <button>
                            <span><img [nexaeiImageSrc]="'member/integrate.svg'" /></span>
                        </button>
                        <button>
                            <span><img [nexaeiImageSrc]="'member/automate.svg'" /></span>
                        </button>
                    </div>
                </div>
            </div>
            <!--tabular button-->
            <div class="col-lg-12">
                <div class="tabcontent width-full">
                    <ul>
                        <li>
                            <a class="active" href="#">Active</a>
                        </li>
                        <li>
                            <a href="#">Invited</a>
                        </li>
                    </ul>
                    <div class="filter-box button-section">
                        <button class="special-bg">
                        <span>
                            <img [nexaeiImageSrc]="'member/xmlid.svg'" />
                        </span>
                        </button>
                        <button
                            data-bs-toggle="dropdown" data-bs-auto-close="true" id="#countrycodevisible">
                        <span>
                            <img [nexaeiImageSrc]="'member/add-package.svg'" />
                        </span>
                        </button>
                        <span class="dropdown-menu" aria-labelledby="countrycodevisible">
                            <h4>Select more fields</h4>
                            <label> <img [nexaeiImageSrc]="'member/input-field/join-date.svg'" />
                                <p>Joining Date</p>
                            </label>
                            <label> <img [nexaeiImageSrc]="'member/input-field/location.svg'" />
                                <p>Address</p>
                            </label>
                            <label> <img [nexaeiImageSrc]="'member/input-field/gender.svg'" />
                                <p>Gender</p>
                            </label>
                            <label class='active'> <img [nexaeiImageSrc]="'member/input-field/dob.svg'" />
                                <p>DOB</p>
                            </label>
                            <label> <img [nexaeiImageSrc]="'member/input-field/location.svg'" />
                                <p>Location</p>
                            </label>
                            <label> <img [nexaeiImageSrc]="'member/input-field/add-custom-field.svg'" />
                                <p>Add custom field</p>
                            </label>
                            <label> <img [nexaeiImageSrc]="'member/input-field/emp-id.svg'" />
                                <p>Emp ID</p>
                            </label>
                        </span>



                        <button class="button-squre"
                        data-bs-toggle="dropdown" data-bs-auto-close="true" id="#countrycodevisible-new">
                    <span>
                        <img [nexaeiImageSrc]="'squre.png'" />
                        Customize
                    </span>
                    </button>
                    <span class="dropdown-menu ad-dropdown-class" aria-labelledby="countrycodevisible-new">
                        
                        <p>Customize</p>

                        <div class="d-flex justify-content-between pt-2">

                             <p class="fs-14">  <img class="pe-2" width="22px" [nexaeiImageSrc]="'name.png'" />  Name </p>

                             <div class="check-box">
                                <input type="checkbox">
                              </div>
                             
                             
                        </div>


                        <div class="d-flex justify-content-between">

                            <p class="fs-14">  <img class="pe-2" width="24px" [nexaeiImageSrc]="'mail.png'" />  Email ID </p>

                            <div class="check-box">
                               <input type="checkbox">
                             </div>
                            
                            
                       </div>




                       <div class="d-flex justify-content-between">

                        <p class="fs-14">  
                            <img class="pe-2" width="24px" [nexaeiImageSrc]="'calender-icon.png'" />  Date of Activation  </p>

                        <div class="check-box">
                           <input type="checkbox">
                         </div>
                        
                        
                   </div>


                   <div class="d-flex justify-content-between">

                    <p class="fs-14">  
                        <img class="pe-2" width="24px" [nexaeiImageSrc]="'statusnew.png'" />  Status  </p>

                    <div class="check-box">
                       <input type="checkbox">
                     </div>
                    
                    
               </div>


               <div class="d-flex justify-content-between">

                <p class="fs-14">  
                    <img class="pe-2" width="24px" [nexaeiImageSrc]="'designation.png'" />  Designation  </p>

                <div class="check-box">
                   <input type="checkbox">
                 </div>
                
                
           </div>


           <div class="d-flex justify-content-between">

            <p class="fs-14">  
                <img class="pe-2" width="24px" [nexaeiImageSrc]="'emp-id.png'" />  Emp ID  </p>

            <div class="check-box">
               <input type="checkbox">
             </div>
            
            
       </div>


       <div class="d-flex justify-content-between">

        <p class="fs-14">  
            <img class="pe-2" width="24px" [nexaeiImageSrc]="'emp-type.png'" />  Employment Type  </p>

        <div class="check-box">
           <input type="checkbox">
         </div>
        
        
   </div>

                    </span>



                        
                    </div>
                </div>
            </div>
            <!--table section-->
            <div class="tablesection table-empty col-lg-12 text-center">
                <table class="table">
                    <thead>
                        <tr>
                            <th scope="col">Name</th>
                            <th scope="col">Email Id</th>
                            <th scope="col">Role</th>
                            <th scope="col">Team</th>
                        </tr>
                    </thead>

                    <tbody>
                        <tr>
                            <td><img [nexaeiImageSrc]="'member/user.png'" />Amkit kumar</td>
                            <td>amit.kumar@gmail.com</td>
                            <td>
                                <select [nexaeiBackgroundImage]="'header/polygon.svg'">
                                    <option>
                                        Member
                                    </option>
                                    <option>
                                        Member2
                                    </option>
                                </select>
                            </td>
                            <td>
                                <select [nexaeiBackgroundImage]="'header/polygon.svg'">
                                    <option>
                                        None
                                    </option>
                                    <option>
                                        NOne 2
                                    </option>
                                </select>
                            </td>
                        </tr>
                        <tr>
                            <td><img [nexaeiImageSrc]="'member/user.png'" />Amkit kumar</td>
                            <td>Aadf@gmail.com</td>
                            <td>Member</td>
                            <td>Nexval</td>
                        </tr>
                        <tr>
                            <td><img [nexaeiImageSrc]="'member/user.png'" />Amkit kumar</td>
                            <td>amit.kumar@gmail.com</td>
                            <td>
                                <select [nexaeiBackgroundImage]="'header/polygon.svg'">
                                    <option>
                                        Member
                                    </option>
                                    <option>
                                        Member2
                                    </option>
                                </select>
                            </td>
                            <td>
                                <select [nexaeiBackgroundImage]="'header/polygon.svg'">
                                    <option>
                                        None
                                    </option>
                                    <option>
                                        NOne 2
                                    </option>
                                </select>
                            </td>
                        </tr>
                        <tr>
                            <td><img [nexaeiImageSrc]="'member/user.png'" />Amkit kumar</td>
                            <td>amit.kumar@gmail.com</td>
                            <td>
                                <select [nexaeiBackgroundImage]="'header/polygon.svg'">
                                    <option>
                                        Member
                                    </option>
                                    <option>
                                        Member2
                                    </option>
                                </select>
                            </td>
                            <td>
                                <select [nexaeiBackgroundImage]="'header/polygon.svg'">
                                    <option>
                                        None
                                    </option>
                                    <option>
                                        NOne 2
                                    </option>
                                </select>
                            </td>
                        </tr>
                        <tr>
                            <td><img [nexaeiImageSrc]="'member/user.png'" />Amkit kumar</td>
                            <td>amit.kumar@gmail.com</td>
                            <td>
                                <select [nexaeiBackgroundImage]="'header/polygon.svg'">
                                    <option>
                                        Member
                                    </option>
                                    <option>
                                        Member2
                                    </option>
                                </select>
                            </td>
                            <td>
                                <select [nexaeiBackgroundImage]="'header/polygon.svg'">
                                    <option>
                                        None
                                    </option>
                                    <option>
                                        NOne 2
                                    </option>
                                </select>
                            </td>
                        </tr>
                        <tr>
                            <td><img [nexaeiImageSrc]="'member/user.png'" />Amkit kumar</td>
                            <td>amit.kumar@gmail.com</td>
                            <td>
                                <select [nexaeiBackgroundImage]="'header/polygon.svg'">
                                    <option>
                                        Member
                                    </option>
                                    <option>
                                        Member2
                                    </option>
                                </select>
                            </td>
                            <td>
                                <select [nexaeiBackgroundImage]="'header/polygon.svg'">
                                    <option>
                                        None
                                    </option>
                                    <option>
                                        NOne 2
                                    </option>
                                </select>
                            </td>
                        </tr>
                        <tr>
                            <td><img [nexaeiImageSrc]="'member/user.png'" />Amkit kumar</td>
                            <td>amit.kumar@gmail.com</td>
                            <td>
                                <select [nexaeiBackgroundImage]="'header/polygon.svg'">
                                    <option>
                                        Member
                                    </option>
                                    <option>
                                        Member2
                                    </option>
                                </select>
                            </td>
                            <td>
                                <select [nexaeiBackgroundImage]="'header/polygon.svg'">
                                    <option>
                                        None
                                    </option>
                                    <option>
                                        NOne 2
                                    </option>
                                </select>
                            </td>
                        </tr>
                        <tr>
                            <td><img [nexaeiImageSrc]="'member/user.png'" />Amkit kumar</td>
                            <td>amit.kumar@gmail.com</td>
                            <td>
                                <select [nexaeiBackgroundImage]="'header/polygon.svg'">
                                    <option>
                                        Member
                                    </option>
                                    <option>
                                        Member2
                                    </option>
                                </select>
                            </td>
                            <td>
                                <select [nexaeiBackgroundImage]="'header/polygon.svg'">
                                    <option>
                                        None
                                    </option>
                                    <option>
                                        NOne 2
                                    </option>
                                </select>
                            </td>
                        </tr>
                        <tr>
                            <td><img [nexaeiImageSrc]="'member/user.png'" />Amkit kumar</td>
                            <td>amit.kumar@gmail.com</td>
                            <td>
                                <select [nexaeiBackgroundImage]="'header/polygon.svg'">
                                    <option>
                                        Member
                                    </option>
                                    <option>
                                        Member2
                                    </option>
                                </select>
                            </td>
                            <td>
                                <select [nexaeiBackgroundImage]="'header/polygon.svg'">
                                    <option>
                                        None
                                    </option>
                                    <option>
                                        NOne 2
                                    </option>
                                </select>
                            </td>
                        </tr>
                        <tr>
                            <td><img [nexaeiImageSrc]="'member/user.png'" />Amkit kumar</td>
                            <td>amit.kumar@gmail.com</td>
                            <td>
                                <select [nexaeiBackgroundImage]="'header/polygon.svg'">
                                    <option>
                                        Member
                                    </option>
                                    <option>
                                        Member2
                                    </option>
                                </select>
                            </td>
                            <td>
                                <select [nexaeiBackgroundImage]="'header/polygon.svg'">
                                    <option>
                                        None
                                    </option>
                                    <option>
                                        NOne 2
                                    </option>
                                </select>
                            </td>
                        </tr>
                        <tr>
                            <td><img [nexaeiImageSrc]="'member/user.png'" />Amkit kumar</td>
                            <td>amit.kumar@gmail.com</td>
                            <td>
                                <select [nexaeiBackgroundImage]="'header/polygon.svg'">
                                    <option>
                                        Member
                                    </option>
                                    <option>
                                        Member2
                                    </option>
                                </select>
                            </td>
                            <td>
                                <select [nexaeiBackgroundImage]="'header/polygon.svg'">
                                    <option>
                                        None
                                    </option>
                                    <option>
                                        NOne 2
                                    </option>
                                </select>
                            </td>
                        </tr>
                        
                    </tbody>
                </table>
                <!--empty data-->
                <div class="table-empty-content empty-space">
                    <span>
                        <img [nexaeiImageSrc]="'member/empty-member.png'" />
                        <h6>Colleagues</h6>
                        <p>You don't have any members please start<br />adding members</p>
                        <button class="btn primary-xl mt-3"><a [routerLink]="['/dashboard/member']">+Add
                                new</a></button>
                    </span>
                </div>
            </div>
        </div>
    </section>
</div>

