<app-sidebar></app-sidebar>

<div class="mix-sec">
    <section class="col-12 emv-invittion collab pt-2">
        <div>
            <div class="d-flex p-4 justify-content-between">
                <div class="col-md-6 ps-4 d-flex">
                    <a href="#" class="me-3">
                        <img width="10px" [nexaeiImageSrc]="'emp-invite/arrow.png'" />
                    </a>

                    <small class="text-white pt-2">Mon, 16 Apr 21</small>

                    <div class="d-flex ms-3 pt-1">
                        <a href="" class="roted"><img width="15px" [nexaeiImageSrc]="'arow.png'" /></a>
                        <a href="" class="ms-2"><img width="15px" [nexaeiImageSrc]="'arow.png'" /></a>
                    </div>
                </div>
                <div class="col-md-6 d-flex justify-content-end pe-3">
                    <a href="#" class="me-2">
                        <img width="15px" [nexaeiImageSrc]="'calendar/download.png'" />
                    </a>
                </div>
            </div>

            <div class="d-flex justify-content-center mb-2">
                <div class="bg-black  col-md-11 f-control2 p-2 d-flex justify-content-between pe-3 ps-3 align-items-center">
                    <div class="d-flex col-4 invittion-new">
                      
          <h4 class="border-green null user-status">
            <img [nexaeiImageSrc]="'Ellipse16.png'" />
        </h4>


                        <div>
                            <p class="pb-0 mb-0 pt-2">Amit Kumar</p>
                        </div>
                    </div>

                    <div class="d-flex align-items-center justify-content-end col-4">
                        <div>
                            <p class="pb-0 mb-0 fs-14">10:30 AM In | 07:30 PM Out</p>
                        </div>
                        <div><img width="17px" class="ms-3" [nexaeiImageSrc]="'collab/map.png'" /></div>
                    </div>

                    <div class="d-flex align-items-start col-3">
                        <div>
                            <p class="pb-0 mb-0 fs-14">Clock Time: Undefine</p>
                        </div>
                    </div>
                </div>
            </div>

            <div class="d-flex justify-content-center mb-2">
                <div class="bg-black  col-md-11 f-control2 p-2 d-flex justify-content-between pe-3 ps-3 align-items-center">
                    <div class="d-flex col-4 invittion-new">
                        <h4 class="border-green null user-status">
                            Tr
                        </h4>
                        <div>
                            <p class="pb-0 mb-0 pt-2">Amit Kumar</p>
                        </div>
                    </div>

                    <div class="d-flex align-items-center justify-content-end col-4">
                        <div>
                            <p class="pb-0 mb-0 fs-14">10:30 AM In | 07:30 PM Out</p>
                        </div>
                        <div><img width="17px" class="ms-3" [nexaeiImageSrc]="'collab/map.png'" /></div>
                    </div>

                    <div class="d-flex align-items-start col-3">
                        <div>
                            <p class="pb-0 mb-0 fs-14">Clock Time: 9 hr</p>
                        </div>
                    </div>
                </div>
            </div>

            <div class="d-flex justify-content-center mb-2">
                <div class="bg-black  col-md-11 f-control2 p-2 d-flex justify-content-between pe-3 ps-3 align-items-center">
                    <div class="d-flex col-4">
                        <div class="position-relative">
                            <div class="online-status online"></div>
                            <img class="me-3 c-img border-green" [nexaeiImageSrc]="'Ellipse16.png'" />
                        </div>
                        <div>
                            <p class="pb-0 mb-0 pt-2">Poonam Sharma</p>
                        </div>
                    </div>

                    <div class="d-flex align-items-center justify-content-end col-4">
                        <div>
                            <p class="pb-0 mb-0 fs-14">10:30 AM In | --:-- PM Out</p>
                        </div>
                        <div><img width="17px" class="ms-3" [nexaeiImageSrc]="'collab/map.png'" /></div>
                    </div>

                    <div class="d-flex align-items-start col-3">
                        <div>
                            <p class="pb-0 mb-0 fs-14">Clock Time: 9 hr 15 min</p>
                        </div>
                    </div>
                </div>
            </div>

            <div class="d-flex justify-content-center mb-2">
                <div class="bg-black  col-md-11 f-control2 p-2 d-flex justify-content-between pe-3 ps-3 align-items-center">
                    <div class="d-flex col-4">
                        <div class="position-relative">
                            <div class="online-status online"></div>
                            <img class="me-3 c-img border-green" [nexaeiImageSrc]="'Ellipse16.png'" />
                        </div>
                        <div>
                            <p class="pb-0 mb-0 pt-2">Amit Kumar</p>
                        </div>
                    </div>

                    <div class="d-flex align-items-center justify-content-end col-4">
                        <div>
                            <p class="pb-0 mb-0 fs-14">10:30 AM In | 07:30 PM Out</p>
                        </div>
                        <div><img width="17px" class="ms-3" [nexaeiImageSrc]="'collab/map.png'" /></div>
                    </div>

                    <div class="d-flex align-items-start col-3">
                        <div>
                            <p class="pb-0 mb-0 fs-14">Clock Time: Undefine</p>
                        </div>
                    </div>
                </div>
            </div>

            <div class="d-flex justify-content-center mb-2">
                <div class="bg-black  col-md-11 f-control2 p-2 d-flex justify-content-between pe-3 ps-3 align-items-center">
                    <div class="d-flex col-4">
                        <div class="position-relative">
                            <div class="online-status online"></div>
                            <img class="me-3 c-img border-green" [nexaeiImageSrc]="'Ellipse16.png'" />
                        </div>
                        <div>
                            <p class="pb-0 mb-0 pt-2">Amit Kumar</p>
                        </div>
                    </div>

                    <div class="d-flex align-items-center justify-content-end col-4">
                        <div>
                            <p class="pb-0 mb-0 fs-14">10:30 AM In | 07:30 PM Out</p>
                        </div>
                        <div><img width="17px" class="ms-3" [nexaeiImageSrc]="'collab/map.png'" /></div>
                    </div>

                    <div class="d-flex align-items-start col-3">
                        <div>
                            <p class="pb-0 mb-0 fs-14">Clock Time: 9 hr</p>
                        </div>
                    </div>
                </div>
            </div>

            <div class="d-flex justify-content-center mb-2">
                <div class="bg-black  col-md-11 f-control2 p-2 d-flex justify-content-between pe-3 ps-3 align-items-center">
                    <div class="d-flex col-4">
                        <div class="position-relative">
                            <div class="online-status online"></div>
                            <img class="me-3 c-img border-green" [nexaeiImageSrc]="'Ellipse16.png'" />
                        </div>
                        <div>
                            <p class="pb-0 mb-0 pt-2">Poonam Sharma</p>
                        </div>
                    </div>

                    <div class="d-flex align-items-center justify-content-end col-4">
                        <div>
                            <p class="pb-0 mb-0 fs-14">10:30 AM In | --:-- PM Out</p>
                        </div>
                        <div><img width="17px" class="ms-3" [nexaeiImageSrc]="'collab/map.png'" /></div>
                    </div>

                    <div class="d-flex align-items-start col-3">
                        <div>
                            <p class="pb-0 mb-0 fs-14">Clock Time: 9 hr 15 min</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
</div>
