<div class="country-select-dropdown">
    <div class="dropdown">
        <div #country_select_dropdown data-bs-toggle="dropdown" aria-expanded="false" id="country_select">
            <div (click)="toggleCountrySelect()" class="pointer">
                <span class="flag-icon flag-icon-{{selectedCountry.isoCode|lowercase}} flag-icon-squared rounded-flg"
                    style="border-radius: 60%;"></span>
                <span class="country-code">&nbsp;+{{selectedCountry.dialCode}}&nbsp;&nbsp;</span>
                <span><img [nexaeiImageSrc]="'select-dropdown.svg'" class="img-icon-down" /></span>
            </div>
        </div>
        <div class="dropdown-menu pt-3 my-4" aria-labelledby="country_select">
            <span class="input-search">
                <img [nexaeiImageSrc]="'header/search-bar.svg'" />
                <input [formControl]="countrySearch" type="search" placeholder="search" />
            </span>
            <div class="dropdown-content-body">
                <div *ngIf="countries.length > 0">
                    <div class="dropdown-item" *ngFor=" let country of countries">
                        <div class="pointer d-flex align-items-center" (click)="selectCountry(country)">
                            <span
                                class="flag-icon flag-icon-{{country.isoCode|lowercase}} flag-icon-squared rounded-flg"></span>
                            <span class="country-code-span">{{country.dialCode}}</span>
                            <span [title]="country.name" class="span-overflow">{{country.name}}</span>
                        </div>
                    </div>
                </div>
            </div>
            <div *ngIf="!(countries.length > 0)">
                <div class="pt-1"></div>
                <p class="text-center">No Countries Found</p>
            </div>
        </div>
    </div>
</div>
