<app-sidebar-panel-blank></app-sidebar-panel-blank>
<app-header class="head_top"></app-header>

<div class="mid-sec bg-dark-sec p-5 pt-4 emv-invittion">
    <section class="d-flex justify-content-start pt-3">
        <div class="row col-md-8 pt-5">
       
            <div class="col-12 d-flex pt-4">
                <a _ngcontent-gyv-c153="" style="cursor: pointer;">
                    <img class="left-arow" [nexaeiImageSrc]="'emp-invite/arrow.png'" />
                   </a>
                <div class="me-3">
                    <div class="profilepic-new fw-bold">D</div>
                </div>
                <div class="position-relative">
                    <h6 class="text-white fw-normal pt-2">Shelly Holt</h6>
                    <a href="#" class="camera" data-bs-toggle="modal" data-bs-target="#staticBackdrop"><img width="20px" [nexaeiImageSrc]="'cameraicon.png'" /></a>
                </div>
                <a href="#" class="ms-4 pt-1"><img width="10px" [nexaeiImageSrc]="'collab/icon1.png'" /></a>
            </div>

            <!-- Modal -->
<div class="modal fade" id="staticBackdrop" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">
    <div class="modal-dialog modal-lg modal-dialog-centered">
      <div class="modal-content">
        <div class="modal-header">
          <div class="text-center col-11"><h6 class="modal-title">Profile Picture</h6>
            <small class="text-white">Please upload your profile picture, your colleague will recognize you better.
            </small>
         </div>
          <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
        </div>
        <div class="modal-body"> 
         
         <div class="d-flex justify-content-center">

             <div class="pro_pic d-flex justify-content-center align-items-center">

                <img width="200px" [nexaeiImageSrc]="'profile.png'" />

             </div>

         </div>

        </div>
        <div class="modal-footer border-0  d-flex justify-content-center pb-3">
       
           <div class="d-flex"> 

             <div class="d-flex text-white me-3 ps-3"><div><img class="me-2" width="26px" [nexaeiImageSrc]="'cameraicon1.png'" /></div> Take Photo</div>

             <div class="d-flex text-white ms-3"><div><img class="me-2" width="26px" [nexaeiImageSrc]="'imageicon.png'" /></div> Browse photos </div>

            </div>

             <div class="d-flex ms-4">
                
                <div class="text-white d-flex"> <div class="pt-1 pe-2"><img width="26px" [nexaeiImageSrc]="'deletcer.png'" /></div>
                    <h6 class="fw-normal pt-2"> Delete </h6> </div>
                <button type="submit" class="btn save">Save</button>

            </div> 

                

             

        </div>
      </div>
    </div>
  </div>

            <div class="col-12 pt-3">
                <input type="text" class="form-control form-sec border-0" id="" placeholder="Designation" />
            </div>

            <div class="col-12 pt-4 d-flex justify-content-between">
                <div>
                    <small>Email</small>
                    <p>shelly.holt@email.com</p>
                </div>

                <div>
                    <small>Emp ID</small>
                    <p>2323</p>
                </div>

                <div>
                    <small>Joining date</small>
                    <p>11 May, 2019</p>
                </div>
            </div>

            <hr />

            <div class="col-12 pt-2 d-flex justify-content-between">
                <div>
                    <small>Personal Details</small>
                </div>
            </div>

            <div class="col-12 pt-1 d-flex justify-content-between">
                <div>
                    <small>Gender</small>
                    <p>Male</p>
                </div>

                <div>
                    <a href="#" class="pt-2"><img width="10px" [nexaeiImageSrc]="'collab/icon1.png'" /></a>
                </div>
            </div>

            <div class="col-12 pt-1 d-flex justify-content-between">
                <div>
                    <small>DOB </small>
                    <p>12 Feb, 1995</p>
                </div>

                <div>
                    <a href="#" class="pt-2"><img width="10px" [nexaeiImageSrc]="'collab/icon1.png'" /></a>
                </div>
            </div>

            <div class="col-12 pt-1 d-flex justify-content-between">
                <div>
                    <small>Qualifications </small>
                    <p>None</p>
                </div>

                <div>
                    <a href="#" class="pt-2"><img width="10px" [nexaeiImageSrc]="'collab/icon1.png'" /></a>
                </div>
            </div>

            <div class="col-12 pt-1 d-flex justify-content-between">
                <div>
                    <small>Blood Group</small>
                    <p>None</p>
                </div>

                <div>
                    <a href="#" class="pt-2"><img width="10px" [nexaeiImageSrc]="'collab/icon1.png'" /></a>
                </div>
            </div>

            <div class="col-12 pt-1 d-flex justify-content-between">
                <div>
                    <small>Mobile Number</small>
                    <p>None</p>
                </div>

                <div>
                    <a href="#" class="pt-2"><img width="10px" [nexaeiImageSrc]="'collab/icon1.png'" /></a>
                </div>
            </div>

            <div class="col-12 pt-1 d-flex justify-content-between">
                <div>
                    <small>Address</small>
                    <p>None</p>
                </div>

                <div>
                    <a href="#" class="pt-2"><img width="10px" [nexaeiImageSrc]="'collab/icon1.png'" /></a>
                </div>
            </div>
        </div>
    </section>
</div>
